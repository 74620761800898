/* eslint-disable indent */
import Chat from '../../views/Chat.vue';
import UserBio from '../../views/user/UserBio.vue';
import MyMentors from '../../views/mentee/MyMentors.vue';
import LicensePackages from '../../views/mentee/LicensePackages.vue';
import MyTasks from '../../views/mentee/MyTasks.vue';
// import RequestMentor from '../../views/mentee/RequestMentor.vue';
import GoalMentorsSuggestionsList from '../../views/mentee/GoalMentorsSuggestionsList.vue';
import ProgramMentorsSuggestionsList from '../../views/mentee/ProgramMentorsSuggestionsList.vue';
import TaskReport from '../../views/mentee/TaskReport.vue';
import GroupTaskReport from '../../views/mentee/GroupTaskReport.vue';
import Resources from '../../views/mentee/Resources.vue';
import FAQ from '../../views/mentee/FAQ.vue';
import Expert from '../../views/mentee/Expert.vue';

const meta = {
  requiresLogin: true,
  requiresUserType: 4,
};

export default [
  {
    path: 'faq-mentee',
    name: 'FAQMenteeView',
    component: FAQ,
    meta,
  },
  {
    path: 'license-packages',
    name: 'license',
    component: LicensePackages,
    meta,
  },
  {
    path: 'my-tasks',
    name: 'TasksView',
    component: MyTasks,
    meta,
  },
  {
    path: 'my-mentors',
    name: 'menteeMentorsView',
    component: MyMentors,
    meta,
  },
  // {
  //   path: 'request-mentor',
  //   name: 'requestMentor',
  //   component: RequestMentor,
  //   meta,
  // },
  {
    path: 'request-mentor',
    redirect: 'request-mentor/mentors',
  },
  {
    path: 'task/:id/submit',
    name: 'TaskReport',
    component: TaskReport,
    props: true,
    meta,
  },
  {
    path: 'mentor/:id/bio',
    name: 'mentorBio',
    component: UserBio,
    props(route) {
      return {
        id: route.params.id,
        showBackButton: true,
      };
    },
    meta,
  },
  {
    path: 'request-mentor/mentors',
    name: 'MentorsSuggestionsList',
    component: GoalMentorsSuggestionsList,
    meta,
  },
  {
    path: 'program-mentors',
    name: 'ProgramMentorsSuggestionsList',
    component: ProgramMentorsSuggestionsList,
    meta,
  },
  // #region nested pages with BackButton
  {
    path: 'mentor/:id/chat',
    name: 'mentorChat',
    component: Chat,
    props() {
      return {
        // id: route.params.id,
        // prevRoute: {
        //   name: 'menteeMentorsView',
        // },
      };
    },
    meta,
  },
  {
    path: 'task/:id/submit/resources',
    name: 'addResourcesToTaskSubmit',
    component: Resources,
    props(route) {
      return {
        id: route.params.id,
        prevRoute: {
          name: 'TaskReport',
          params: {
            id: route.params.id,
          },
        },
      };
    },
    meta,
  },
  {
    path: 'expert/:id',
    name: 'ExpertView',
    component: Expert,
    props(route) {
      return {
        id: route.params.id,
        prevRoute: {
          name: 'menteeMentorsView',
        },
      };
    },
    meta,
  },
  // #endregion
  {
    path: 'group-task/:id/submit',
    name: 'GroupTaskReport',
    component: GroupTaskReport,
    props: true,
    meta,
  },
];
