import _compact from 'lodash/compact';

export async function asyncForEach(array, callback) {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

export function getRouteNames(routes) {
  const viewRoutes = [];
  // eslint-disable-next-line no-unused-vars
  Object.entries(routes).forEach(([index, {
    name = undefined,
    alias = undefined,
    children = [],
  }]) => {
    const routeName = name || ((alias !== 'default' && alias) ? alias : false);
    viewRoutes.push(routeName);

    if (children.length > 0) {
      viewRoutes.push(...getRouteNames(children));
    }
  });
  return _compact(viewRoutes);
}

export function customObservable(el, config, callback) {
  const observer = new MutationObserver(callback);
  observer.observe(el, config);
  return observer;
}

export const maxFileSize = 8388608;

// eslint-disable-next-line no-useless-escape
const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
// eslint-disable-next-line no-useless-escape
const urlNoProtocolRegex = /(https:\/\/)+(?=[a-zA-Z0-9@:%._\+~#=-]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/ig;

export function isValidUrl(url, noProtocol = false) {
  let isValid = false;

  if (noProtocol) {
    isValid = !(urlNoProtocolRegex.test(url));
  } else {
    isValid = urlRegex.test(url);
  }

  return !!isValid;
}

export function contrastColor(hex) {
  var threshold = 149;
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (hex.length == 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];
  // 6 digits
  } else if (hex.length == 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }
  return ((r*0.299 + g*0.587 + b*0.114) > threshold) ? '#000000' : '#ffffff';
}
