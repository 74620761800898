import { apiPost, generateFormData } from '../../../modules/apiHelper';
import { toBoolean, urlFriendly } from '../../../modules/dataHelper';
import shareResource from '../_helpers/shareResource';

const RESOURCES_PARAMS = {
  /**
   * THESE ARE GENERAL PROGRAM RESOURCES
   */
  PROGRAM_RESOURCES: {
    user: {
      link_endpoint: 'prog_share_resource_link',
      resource_endpoint: 'prog_share_resource',
      link_comp_stat: 1,
      resource_comp_stat: 1,
    },
  },
  /**
   * THESE ARE GOAL/MODULE SPECIFIC PROGRAM RESOURCES
   */
  GOAL_RESOURCES: {
    user: {
      link_endpoint: 'prog_share_goal_resource_link',
      resource_endpoint: 'prog_share_goal_resource',
      link_comp_stat: 1,
      resource_comp_stat: 1,
    },
  },
};

export default {
  async uploadSequentialResource(context, data) {
    const senderid = context.rootState.user.id;
    const resourceParams = data.moduleid ? RESOURCES_PARAMS.GOAL_RESOURCES : RESOURCES_PARAMS.PROGRAM_RESOURCES;
    const apiEndpoint = resourceParams.user.resource_endpoint;

    const formData = generateFormData({
      senderid,
      userid: data.userid,
      name: data.name,
      comp_stat: resourceParams.user.resource_comp_stat,
    });

    if (data.moduleid) {
      formData.set('goalid', data.moduleid);
    } else {
      formData.set('progid', data.programId);
    }

    const resourceExt = data.resource.name.split('.').pop();
    const resourceName = `${data.resource.name.slice(0, 80)}.${resourceExt}`;

    formData.append('file1', data.resource, urlFriendly(resourceName));

    if (data.userId) {
      formData.set('userid', data.userId);
    }

    return shareResource(apiEndpoint, formData, 3);
  },

  async uploadSequentialLinkResource(context, data) {
    const senderid = context.rootState.user.id;
    const resourceParams = data.moduleid ? RESOURCES_PARAMS.GOAL_RESOURCES : RESOURCES_PARAMS.PROGRAM_RESOURCES;
    const apiEndpoint = resourceParams.user.link_endpoint;

    const formData = generateFormData({
      senderid,
      userid: data.userid,
      name: data.name,
      link: data.link,
      comp_stat: resourceParams.user.link_comp_stat,
    });

    if (data.moduleid) {
      formData.set('goalid', data.moduleid);
    } else {
      formData.set('progid', data.programId);
    }

    const response = await apiPost(apiEndpoint, formData, 3)
      .catch((err) => {
        console.warn(`ERROR::uploadSequentialLinkResource::${apiEndpoint}`, err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) {
          return false;
        }

        return true;
      });
    //

    return response;
  },
};
