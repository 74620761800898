<template>
  <AppView
    padd-bottom
  >
    <TopNav
      slot="top-app-view"
      title="My Mentees"
      title-class="lg:ml-body lg-app:tw-font-bold"
      type="menu"
      @input="openSideNav()"
    />

    <template v-if="myMentees.length === 3">
      <div class=" tw-bg-app-primary-orange tw-py-4">
        <p class="tw-text-white px-body">
          You have reached a maximum number of mentees. You will not be able to accept any other mentee request until you finish the current mentorship sessions.
        </p>
      </div>
    </template>

    <transition name="new" mode="out-in" appear>
      <!-- badges -->
      <div class="tw-mx-auto px-body" v-if="!isSequentialProgram">
        <div class="tw-bg-gray-100 tw-rounded-10 tw-p-2 tw-mt-8 tw-shadow-lg">
          <p class="tw-text-sm opacity-54 tw-mb-2 tw-text-center">Your collected Badges</p>
          <UserGoalsBadges tiny />
        </div>
      </div>
    </transition>

    <!-- MY MEETINGS -->
    <transition
      mode="out-in"
      name="fade"
      appear
      key="my-meetings-list"
    >
      <div class="px-body tw-py-4 tw-w-full tw-border-b" v-if="meetings.length > 0">
        <div>
          <h5 class="opacity-54 tw-mb-2">Meetings with your mentees / mentorship groups</h5>
        </div>

        <div class="tw-relative">
          <swiper
            :options="meetingsSwiperOptions"
            ref="my-meetings-slider"
            :key="meetingsSlideKey"
          >
            <swiper-slide
              v-for="({ participants, ...meeting }, key) in meetings"
              :key="key"
              :class="[
                'tw-shrink-0 tw-w-screen tw-pb-4 tw-h-auto',
              ]"
              style="height: auto;"
            >
              <VideoMeetingCard
                :meeting="meeting"
                :participants="participants"
                class="tw-h-full"
              />
            </swiper-slide>

            <button
              slot="button-prev"
              class="tw-rounded-full tw-shadow-app tw-fill-current tw-p-2 tw-absolute tw-top-1/2 tw--mt-3 tw-cursor-pointer tw-ml-2 tw-left-0 tw-right-auto tw-z-20 tw-bg-app-light-blue"
              v-html="leftNav"
              ref="meetings-slider-button-prev"
              @click.stop="meetingPrevSlide()"
              v-if="meetings.length > 3"
            ></button>

            <button
              slot="button-next"
              class="tw-rounded-full tw-shadow-app tw-fill-current tw-p-2 tw-absolute tw-top-1/2 tw--mt-3 tw-cursor-pointer tw-mr-2 tw-right-0 tw-left-auto tw-z-20 tw-bg-app-light-blue"
              v-html="rightNav"
              ref="meetings-slider-button-next"
              @click.stop="meetingNextSlide()"
              v-if="meetings.length > 3"
            ></button>
          </swiper>
        </div>
      </div>
    </transition>

    <!-- NEW MENTEES -->
    <transition
      mode="out-in"
      name="fade"
      appear
      :key="slideKey"
    >
      <div
        v-if="newMentees.length > 0"
        class="px-body"
      >
        <h6
          :class="[
            'opacity-54 tw-text-xs tw-my-6',
            { 'tw-text-center': newMentees.length === 1 },
          ]"
        >
          New Mentee{{ newMentees.length > 1 ? 's!': '' }}
        </h6>
        <swiper
          :options="swiperOptions"
          ref="new-mentees-slider"
          :class="{'opacity-11': disableNewMenteesSwiper}"
          :key="slideKey"
        >
          <swiper-slide
            v-for="({ user, goal }, key) in newMentees"
            :key="key"
            :class="[
              'tw-shrink-0 tw-w-screen',
            ]"
          >
            <transition name="slide-list-right" mode="out-in" :key="key" appear>
              <div class="tw-relative tw-flex tw-flex-col tw-w-full tw-max-w-xs tw-mx-auto">
                <UserPhoto
                  :photo="user.photo"
                  :user-name="user"
                  class="tw-mx-auto tw-mb-2 tw-block"
                />
                <h4 class="tw-text-center tw-font-normal tw-text-xl leading-24">{{ user.name }} {{ user.last_name }}</h4>
                <p class="tw-text-base tw-text-center opacity-54 tw-text-black tw-mt-2 tw-leading-tight">
                  {{ user.name }} is looking to be mentored in
                  <b>{{ goal?.goal }}</b>.
                </p>
                <div class="tw-flex tw-justify-center sm:tw-justify-between tw-py-4 tw-items-center tw-flex-wrap">

                  <div class="tw-mb-2 tw-w-full sm:tw-w-auto tw-flex tw-justify-center sm:tw-justify-start">
                    <BaseButton
                      text="Accept"
                      :disabled="disableNewMenteesSwiper"
                      @click="acceptRequest(user.id)"
                    />
                    <BaseButton
                      style="margin-left: 10px;"
                      text="I am unavailable"
                      :disabled="disableNewMenteesSwiper"
                      @click="declineRequest(user.id)"
                    />
                  </div>
                  <div class="tw-mb-2">
                    <BaseButton
                      :to="{
                        name: 'menteeRequest',
                        params: {
                          id: user.id,
                          slug: 'bio'
                        },
                      }"
                      class="tw-border-none tw-py-2 tw-bg-gray-300"
                      text="Profile"
                      icon
                      icon-right
                      :disabled="disableNewMenteesSwiper"
                    >
                      <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M16,67.8c0-1.2,0.4-2,1.2-2.9l29-29l-29-29c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l31.9,31.9c1.6,1.6,1.6,4.1,0,5.7
                          L22.9,70.8c-1.6,1.6-4.1,1.6-5.7,0C16.4,69.9,16,69,16,67.8z"/>
                      </svg>
                    </BaseButton>
                  </div>
                </div>
              </div>
            </transition>
          </swiper-slide>
          <button
            slot="button-prev"
            class="tw-rounded-full tw-shadow-app tw-fill-current tw-p-2 tw-absolute tw-top-1/2 tw--mt-3 tw-cursor-pointer tw-ml-2 tw-left-0 tw-right-auto tw-z-20 tw-bg-app-light-blue"
            v-html="leftNav"
            ref="slider-button-prev"
            @click.stop="prevSlide()"
            v-if="newMentees.length > 1"
          ></button>

          <button
            slot="button-next"
            class="tw-rounded-full tw-shadow-app tw-fill-current tw-p-2 tw-absolute tw-top-1/2 tw--mt-3 tw-cursor-pointer tw-mr-2 tw-right-0 tw-left-auto tw-z-20 tw-bg-app-light-blue"
            v-html="rightNav"
            ref="slider-button-next"
            @click.stop="nextSlide()"
            v-if="newMentees.length > 1"
          ></button>
        </swiper>
      </div>
    </transition>

    <!-- GROUP MENTORSHIPS -->
    <transition
      mode="out-in"
      name="fade"
      appear
      key="group-mentorships-list"
    >
      <div class="px-body tw-py-4 tw-w-full" v-if="groupMentorships.length > 0">
        <template v-for="groupMentorship in groupMentorships">
          <MentorshipGroup
            :mentorship-group="groupMentorship"
            :key="groupMentorship.id"
          />
        </template>
      </div>
    </transition>

    <!-- MY MENTEES -->
    <transition mode="out-in" name="fade" appear>
      <ContentLoadingUserList v-if="loading" class="tw-mt-12" />

      <div v-else-if="hasMentees && !loading">
        <h6
          key="title"
          class="opacity-54 tw-text-xs tw-mt-6 mx-body"
          v-if="myMentees.length > 0 && newMentees.length > 0"
        >
          My Mentees
        </h6>
        <transition-group key="mentees" tag="div" name="new" mode="out-in" appear class="lg:mx-body lg:tw-mt-6">
          <template v-for="({ user, id: matchId }, key) in myMentees">
            <div :key="`${user.photo}-${key}`" class="tw-mt-6 mentee-card">
              <router-link
                tag="div"
                class="mx-body lg:tw-mx-0 tw-flex lg:tw-inline-flex tw-items-center tw-cursor-pointer"
                :to="{ name: 'mentorMenteeView', params: { id: user.id } }"
              >
                <UserPhoto
                  :photo="user.photo"
                  :user-name="user"
                  photo-size="tw-w-8 tw-h-8 lg:tw-w-16 lg:tw-h-16"
                />
                <h4 class="opacity-87 tw-text-black tw-tracking-wide tw-ml-2 lg:tw-ml-4 tw-text-xl font-title leading-24 lg:leading-tight">{{user.name}} {{user.last_name}}</h4>
              </router-link>
              <div class="lg:tw-flex lg:tw-flex-row lg:tw-items-start lg-grid">
                <div class="lg-grid__col-1/3">
                  <router-link
                    tag="div"
                    v-if="hasUserLastMessage(user.id)"
                    :class="[
                      'mr-body lg:tw-mr-0 pl-body lg:tw-pl-0 tw-flex sm:tw-inline-flex tw-items-center bg-black-11  tw-mt-2 tw-cursor-pointer',
                      'tw-py-4 lg:tw-py-2 transition-fast tw-pr-4 tw-rounded-r-full lg:tw-rounded-bl-full lg:tw--ml-4 lg:tw-pl-4 min-h-60 sm:tw-min-w-1/2'
                    ]"
                    :to="{ name: 'mentorMenteeChat', params: { id: user.id } }"
                  >
                    <div class="tw-flex-shrink-0 tw-relative">
                      <svg
                        :class="[
                          'tw-h-6 tw-w-6 tw-inline-block opacity-54 tw-fill-current',
                          { 'tw-text-app-blue opacity-87': !userLastMessageIsRead(user.id) && !userLastMessageIsSelf(user.id) },
                        ]"
                        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M46.1,5.3C35,5.3,25.6,12.4,22,22.2h-1c-0.1,0-0.3,0-0.4,0C9.2,22.2,0,31.5,0,42.8c0,5.8,2.4,11.1,6.5,15V59
                          c0,5.3,3.5,7.8,6.7,7.8c1.4,0,3.5-0.4,5.8-2.7l0.8-0.8c0.3,0,0.5,0,0.8,0c6.2,0,11.9-2.7,15.8-7.5l0.7-0.9c2.9,1.1,5.9,1.6,9.1,1.6
                          c0.5,0,1.1,0,1.7-0.1l2.9,2.9c2.2,2.2,4.3,2.7,5.8,2.7c3.2,0,6.7-2.5,6.7-7.8v-4.1c5.4-4.8,8.7-11.8,8.7-19.2
                          C71.8,16.8,60.3,5.3,46.1,5.3z M20.4,58.2c-0.9,0-1.8-0.1-2.7-0.2l-2.6,2.6c-2.1,2-3.6,1.3-3.6-1.5v-3.6C7.6,52.5,5,47.9,5,42.8
                          c0-8.5,6.9-15.4,15.4-15.4c0.1,0,0.2,0,0.3,0c-0.2,1.1-0.2,2.4-0.2,3.6c0,9,4.7,17,11.9,21.7C29.5,56,25.2,58.2,20.4,58.2z
                          M57.9,47.7v6.6c0,2.9-1.6,3.5-3.7,1.5l-4.6-4.6c-1.1,0.2-2.4,0.3-3.6,0.3c-11.3,0-20.5-9.2-20.5-20.5S34.7,10.4,46,10.4
                          S66.6,19.7,66.6,31C66.7,37.8,63.3,44,57.9,47.7z"/>
                      </svg>
                      <span
                        v-if="!userLastMessageIsRead(user.id) && !userLastMessageIsSelf(user.id)"
                        class="tw-p-1 tw-rounded-full tw-mt-1 tw-bg-blue-500 tw-absolute tw-top-0 tw-right-0"
                      ></span>
                    </div>
                    <p :class="[
                      'opacity-78 mentor-card__chat tw-text-xs tw-overflow-hidden tw-ml-4',
                      { 'tw-text-app-blue': !userLastMessageIsRead(user.id) && !userLastMessageIsSelf(user.id) },
                      ]">
                      <span v-if="userLastMessageIsSelf(user.id)" class="tw-italic">You: </span>
                      <span :class="{ 'tw-italic tw-font-bold': userLastMessageIsResource(user.id) }">{{ userLastMessageText(user.id) }}</span>
                    </p>
                  </router-link>
                </div>
                <div class="tw-mt-4 pl-body lg:tw-pl-16 lg-grid__col-2/3 tw-text-right">
                </div>
              </div>
              <div class="tw-mt-4 tw--ml-4">
                <BaseButton
                  :to="`/app/mentee/${user.id}/chat`"
                  icon
                  icon-right
                  text="CHAT"
                  class="tw-mt-4 tw-mx-4 tw-inline-block tw-border-0 tw-bg-gray-300"
                >
                  <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M7.2,72c-0.8,0-1.7-0.2-2.3-0.8c-1-0.8-1.5-2.1-1.3-3.4l2.1-13.2c-3.6-5.9-5.4-12.6-5.4-19.3c0-16.5,11.9-31,28.1-34.6l0,0
                      c10.9-2.3,22,0.4,30.6,7.5c8.4,7.1,13.2,17.6,12.8,28.7C71.1,52.9,59.3,67,43.6,70.3c-7.7,1.7-15.7,0.8-23-2.7L8.4,71.8
                      C8,72,7.6,72,7.2,72z M29.2,4.8C14.9,7.9,4.5,20.7,4.5,35.4c0,6.3,1.7,12.4,5.2,17.6c0.2,0.4,0.4,1,0.4,1.5L8,67.6l12.1-4.2
                      c0.6-0.2,1-0.2,1.7,0.2c6.5,3.4,13.8,4.2,20.9,2.7c13.6-3.1,24.1-15.5,24.5-29.5C67.7,27,63.5,17.8,56,11.5
                      C48.7,5.2,38.8,2.9,29.2,4.8L29.2,4.8z M28.8,2.9L28.8,2.9L28.8,2.9z"/>
                  </svg>
                </BaseButton>

                <BaseButton
                  :to="{ name: 'CreateMeeting', query: { 'add-users': user.id}}"
                  icon
                  icon-right
                  text="VIDEO CALL MEETING"
                  class="tw-mt-4 tw-mx-4 tw-inline-block tw-border tw-border-app-primary-blue tw-bg-gray-300"
                >
                  <svg class="my-btn__icon" slot="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M58.8,8.4C27.3-17-14.9,19.9,6,54.6L3.8,68c-0.4,2,1.4,4.1,3.4,4c0,0.4,13-4.3,13.4-4.4C58.6,84.9,90.2,35.9,58.8,8.4z
                      M42.8,66.7c-7.3,1.6-14.6,0.6-21.2-2.8c-0.5-0.2-1-0.3-1.5-0.1L7.6,68.1l2.2-13.6c0.1-0.5,0-0.9-0.3-1.3C6,47.9,4.2,41.7,4.2,35.3
                      C4.3,8.7,36.1-6,56.4,11.3C76.6,27.9,68.4,61.2,42.8,66.7L42.8,66.7z M41.9,30.2c0,1-0.8,1.9-1.9,1.9h-2.1c-1,0-1.9-0.8-1.9-1.9
                      c0-1,0.8-1.9,1.9-1.9h2.1C41.1,28.3,41.9,29.2,41.9,30.2z M57.5,28.1c-1-0.5-2.2-0.5-3.2,0.2l-4.5,3.1v-3.8c0-3.5-2.8-6.3-6.3-6.3
                      H22.1c-3.5,0-6.3,2.8-6.3,6.3v15.5c0,3.5,2.8,6.3,6.3,6.3h21.4c3.5,0,6.3-2.8,6.3-6.3v-2.8l4.3,3.5c2.1,1.7,5.2-0.1,5.1-2.7l0-10
                      C59.3,29.8,58.6,28.7,57.5,28.1L57.5,28.1z M46.1,43.1c0,1.4-1.2,2.6-2.6,2.6H22.1c-1.4,0-2.6-1.2-2.6-2.6V27.6
                      c0-1.4,1.2-2.6,2.6-2.6h21.4c1.4,0,2.6,1.2,2.6,2.6V43.1z M55.5,40.2l-5.5-4.4l5.5-3.8C55.5,32,55.5,40.2,55.5,40.2z"/>
                  </svg>
                </BaseButton>

                <BaseButton
                  :to="`/app/mentee/${user.id}/tasks`"
                  icon
                  icon-right
                  text="VIEW TASKS"
                  class="tw-mt-4 tw-mx-4 tw-inline-block tw-border-0 tw-bg-gray-300"
                >
                  <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <g>
                      <path d="M64.4,17.3c0-4.6-3.7-8.2-8.2-8.2h-4.2c-0.7-2.4-3-4.1-5.5-4.1h-3.3C41.5,1,37-1,33,0.6C31,1.4,29.3,3,28.6,5h-3.2
                        c-2.5,0-4.8,1.7-5.5,4.1h-4.2c-4.6,0-8.2,3.7-8.2,8.2v46.5c0,4.6,3.7,8.2,8.2,8.2h40.7c4.6,0,8.2-3.7,8.2-8.2V17.3H64.4z
                        M22.9,10.7c0-1.4,1.1-2.5,2.5-2.5h4.4c0.8,0,1.5-0.5,1.6-1.3c0.5-2.5,2.9-4.1,5.3-3.6c1.8,0.4,3.2,1.8,3.6,3.6
                        c0.2,0.8,0.9,1.3,1.6,1.3h4.5c1.4,0,2.5,1.1,2.5,2.5v1.2c0,1.4-1.1,2.5-2.5,2.5H25.2c-1.4,0-2.5-1.1-2.5-2.5L22.9,10.7z M61.1,63.8
                        c0,2.7-2.2,4.9-4.9,4.9H15.5c-2.7,0-4.9-2.2-4.9-4.9V17.3c0-2.7,2.2-4.9,4.9-4.9h4c0.2,3,2.7,5.4,5.8,5.4h21c3,0,5.6-2.3,5.8-5.4h4
                        c2.7,0,4.9,2.2,4.9,4.9V63.8z"/>
                      <path d="M26.7,28h-7.8c-0.9,0-1.6,0.7-1.6,1.6v7.8c0,0.9,0.7,1.6,1.6,1.6h7.8c0.9,0,1.6-0.7,1.6-1.6v-7.8
                        C28.4,28.7,27.6,28,26.7,28z M25.1,35.8h-4.5v-4.5h4.5V35.8z"/>
                      <path d="M51.9,31.8H36c-0.9,0-1.6,0.7-1.6,1.6S35.1,35,36,35h15.9c0.9,0,1.6-0.7,1.6-1.6S52.8,31.8,51.9,31.8z"/>
                      <path d="M26.7,46.9h-7.8c-0.9,0-1.6,0.7-1.6,1.6l0,0v7.8c0,0.9,0.7,1.6,1.6,1.6h7.8c0.9,0,1.6-0.7,1.6-1.6v-7.8
                        C28.4,47.8,27.6,46.9,26.7,46.9L26.7,46.9z M25.1,54.9h-4.5v-4.5h4.5V54.9z"/>
                      <path d="M51.9,50.9H36c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h15.9c0.9,0,1.6-0.7,1.6-1.6C53.5,51.6,52.8,50.9,51.9,50.9z"
                        />
                    </g>
                  </svg>
                </BaseButton>

                <BaseButton
                  :to="`/app/mentee/${user.id}/tasks/create-task`"
                  icon
                  icon-right
                  text="CREATE TASK"
                  class="tw-mt-4 tw-mx-4 tw-inline-block tw-border-0 tw-bg-gray-300"
                >
                  <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
                      c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
                  </svg>
                </BaseButton>

                <BaseButton
                  :to="`/app/resources?to=${user.id}`"
                  icon
                  icon-right
                  text="SHARE A RESOURCE"
                  class="tw-mt-4 tw-mx-4 tw-inline-block tw-border-0 tw-bg-gray-300"
                >
                  <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M71.1,25.6L56.6,10.8c-1-1-2.4-1-3.3,0c-1,1-1,2.4,0,3.3l11.2,11.5H25.3C11.5,25.6,0,36.8,0,50.9v8.6c0,1.4,1,2.4,2.4,2.4
                      s2.4-1,2.4-2.4v-8.6c0-11.2,9.1-20.5,20.5-20.5h39.1L53.2,41.8c-1,1-1,2.4,0,3.3c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7
                      l14.6-14.8C72.3,29.2,72.3,27.1,71.1,25.6z"/>
                  </svg>
                </BaseButton>

                <BaseButton
                  :to="`/app/mentee/${user.id}/profile`"
                  icon
                  icon-right
                  text="VIEW PROFILE"
                  class="tw-mt-4 tw-mx-4 tw-inline-block tw-border-0 tw-bg-gray-300"
                >
                  <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M46.9,39.5C53,35.6,57.1,29,57.1,21.3C57.1,9.5,47.7,0,35.9,0S14.6,9.5,14.6,21.3c0,7.7,4.1,14.3,10.2,18.2
                      C12.6,43.8,3.3,55.3,2.3,69.2c0,0.8,0.3,1.3,0.8,2C3.6,72,4.4,72,4.9,72h62.3c0.8,0,1.3-0.3,1.8-0.8s0.8-1.3,0.8-2
                      C68.7,55.3,59.4,43.8,46.9,39.5z M19.7,21.3c0-9,7.2-16.1,16.1-16.1S52,12.3,52,21.3s-7.2,16.1-16.1,16.1S19.7,30.2,19.7,21.3z
                      M7.7,66.6C10,53,21.8,42.5,35.9,42.5S62,52.8,64.1,66.6H7.7z"/>
                  </svg>
                </BaseButton>

              </div>
              <div class="divider tw-mt-6 lg:tw-mt-8 tw-border-t mx-body lg:tw-mx-0 opacity-87"></div>
            </div>
          </template>
        </transition-group>
      </div>

      <div v-else class="tw-py-24">
        <!-- SVG FOR WAITING TO BE MATCHED -->
        <h2 class="my-quote tw-px-8 tw-mb-6 tw-max-w-md tw-text-2xl tw-mx-auto font-nunito tw-text-app-primary-orange">
          Show me a successful individual and I’ll show you someone who had real positive influences in his or her life. I don’t care what you do for a living—if you do it well. I’m sure there was someone cheering you on or showing the way. A mentor.
          <br><span class="tw-text-xs tw-text-right tw-ml-auto tw-my-4 tw-block tw-text-black tw-italic">~ Denzel Washington</span>
        </h2>
        <p class="opacity-54 tw-text-center tw-text-gray-700 tw-max-w-md tw-mx-auto tw-pt-6 tw-px-6">
          <!-- Don't worry, mentees are going to flood here. You can contact us to auto match you. -->
          Don't worry, mentees are going to flood here
        </p>
      </div>
    </transition>
  </AppView>
</template>

<script>
import { mapGetters } from 'vuex';
import MatchedListMixin from '@/mixins/matchedlist';
import ContentLoadingUserList from '@/components/ContentLoadingUserList.vue';
import VideoMeetingCard from '@/components/VideoMeetingCard.vue';
import { now } from '@/modules/dataHelper';
import MentorshipGroup from '@/components/MentorshipGroup.vue';
import UserGoalsBadges from '@/components/UserGoalsBadges.vue';

export default {
  name: 'MyMentees',
  components: {
    ContentLoadingUserList,
    VideoMeetingCard,
    MentorshipGroup,
    UserGoalsBadges,
  },
  mixins: [MatchedListMixin],
  data() {
    return {
      query: '',
      loading: true,
      disableNewMenteesSwiper: false,
      updatedSlideCounts: 0,
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 10,
        observer: true,
        centeredSlides: false,
        breakpoints: {
          // screenWidth <=767px
          767: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
      meetingsSwiperOptions: {
        slidesPerView: 3.25,
        spaceBetween: 20,
        observer: true,
        centeredSlides: false,
        breakpoints: {
          // screenWidth <=767px
          767: {
            slidesPerView: 1.25,
            spaceBetween: 10,
          },
        },
      },
      meetings: [],
      groupMentorships: [],
    };
  },
  computed: {
    hasMentees() {
      return this.myMentees.length > 0;
    },
    ...mapGetters([
      'newMentees',
      'myMentees',
      'userLastMessage',
      'userId',
    ]),
    slideKey() {
      // eslint-disable-next-line no-unused-expressions
      this.updatedSlideCounts;
      return now();
    },
    meetingsSlideKey() {
      // eslint-disable-next-line no-unused-expressions
      this.updatedSlideCounts;
      return now();
    },
  },
  watch: {
    newMentees(val) {
      // refresh the slider
      this.swiperOptions.centeredSlides = val.length === 1;
      if (this.$refs['new-mentees-slider']) {
        this.$refs['new-mentees-slider'].swiper.destroy(false, false);
        this.$refs['new-mentees-slider'].swiper.init();
        this.$refs['new-mentees-slider'].swiper.updateSlides();
        this.$refs['new-mentees-slider'].swiper.updateSize();
        this.$refs['new-mentees-slider'].swiper.update();
      }

      this.updatedSlideCounts += 1;
    },
    meetings(val) {
      // refresh the slider
      this.meetingsSwiperOptions.centeredSlides = val.length === 1;
      if (this.$refs['my-meetings-slider']) {
        this.$refs['my-meetings-slider'].swiper.destroy(false, false);
        this.$refs['my-meetings-slider'].swiper.init();
        this.$refs['my-meetings-slider'].swiper.updateSlides();
        this.$refs['my-meetings-slider'].swiper.updateSize();
        this.$refs['my-meetings-slider'].swiper.update();
      }

      this.updatedSlideCounts += 1;
    },
  },
  methods: {
    prevSlide() {
      this.$refs['new-mentees-slider'].swiper.slidePrev();
    },
    nextSlide() {
      this.$refs['new-mentees-slider'].swiper.slideNext();
    },
    meetingPrevSlide() {
      this.$refs['my-meetings-slider'].swiper.slidePrev();
    },
    meetingNextSlide() {
      this.$refs['my-meetings-slider'].swiper.slideNext();
    },
    async acceptRequest(menteeId) {
      if (this.disableNewMenteesSwiper) {
        this.$toasted.info('action not allowed at the moment. Please wait...', {
          duration: 3000,
          position: 'top-right',
        });
        return false;
      }

      this.disableNewMenteesSwiper = true;
      const [result, message] = await this.$store.dispatch('acceptProgramMenteeRequest', menteeId);
      if (result) {
        this.$toasted.success(message, {
          duration: 3000,
          position: 'top-right',
        });

        // get prompt for user
        this.$store.dispatch('getCurrentUserJourneyAction');
      } else {
        this.$toasted.global.appError({ errorMessage: message });
      }
      this.updatedSlideCounts += 1;
      this.disableNewMenteesSwiper = false;
      return true;
    },
    async declineRequest(menteeId) {
      if (this.disableNewMenteesSwiper) {
        this.$toasted.info('action not allowed at the moment. Please wait...', {
          duration: 3000,
          position: 'top-right',
        });
        return false;
      }

      this.disableNewMenteesSwiper = true;
      const [result, message] = await this.$store.dispatch('declineProgramMenteeRequest', menteeId);

      if (result) {
        this.$toasted.success(message, {
          duration: 3000,
          position: 'top-right',
        });

        // get prompt for user
        this.$store.dispatch('getCurrentUserJourneyAction');
      } else {
        this.$toasted.global.appError({ errorMessage: message });
      }
      this.updatedSlideCounts += 1;
      this.disableNewMenteesSwiper = false;
      return true;
    },
    async getMyMeetings() {
      this.meetings = await this.$store.dispatch('getMyActiveMeetings');
    },
    async getGroupMentorships() {
      const groups = await this.$store.dispatch('getMyMentorshipGroups');

      if (Array.isArray(groups)) {
        this.groupMentorships = groups;
      }
    },
  },
  async mounted() {
    this.$store.commit('isFlatView', true);

    await Promise.all([
      this.$store.dispatch('getMyPendingProgramMenteesRequests'),
      this.getMyMeetings(),
      this.getGroupMentorships(),
    ]);

    await this.$nextTick();
    this.loading = false;

    await this.$nextTick();
    if (this.$refs['new-mentees-slider']) {
      await this.$refs['new-mentees-slider'].swiper.destroy(false, false);
      await this.$refs['new-mentees-slider'].swiper.init();
    }

    if (this.$refs['my-meetings-slider']) {
      await this.$refs['my-meetings-slider'].swiper.destroy(false, false);
      await this.$refs['my-meetings-slider'].swiper.init();
    }

    this.swiperOptions.centeredSlides = this.newMentees.length === 1;
    await this.$nextTick();
    this.updatedSlideCounts += 1;
  },
};
</script>

<style>

</style>
