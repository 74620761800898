import { apiStore, apiPost } from '../../../modules/apiHelper';
import { now } from '../../../modules/dataHelper';

export default {
  updateUserSession(context, [index, data]) {
    apiStore.setItem(index, data);
  },

  async clearUserSession(context) {
    await apiStore.clear().then(() => {
      // sessionStorage.clear();
      // console.log('Database is now empty.');
    }).catch((err) => {
      console.error('Can\'t clear database\n', err);
    }).finally(() => {
      context.commit('clearUserSession');
    });
  },

  async userLogIn(context, userData) {
    apiStore.setItem('userid', userData.userid);
    apiStore.setItem('userurl', userData.url);
    apiStore.setItem('usertype', Number(userData.type) || undefined);
    apiStore.setItem('companyid', userData.companyid);
    if (userData.programid) {
      apiStore.setItem('programid', userData.programid);
    }

    apiStore.getItem('session-expiry').then((expiry) => {
      if (!expiry) { // set only if it was not set
        apiStore.setItem('session-expiry', now());
      }
    });
    context.commit('userLogIn', userData);
    context.dispatch('postUserLastSeen');
  },

  async userLogOut(context, authError = undefined) {
    // clear session storage
    await context.dispatch('postUserLastSeen');

    const formData = new FormData();
    formData.set('userid', context.state.id);
    formData.set('user_url', context.state.url);
    await apiPost('logout', formData, 1)
      .then(async () => {
        // clear session API token (from localStorage)
        await Promise.all([
          context.dispatch('stopFetchingNotifications'),
          context.dispatch('stopFetchingUserMessages'),
          context.dispatch('stopFetchingGroupChatMessages'),
          context.dispatch('stopFetchingGroupChatMessages'),
          context.dispatch('stopFetchingMentorshipGroupChatMessages'),
        ]);

        await context.dispatch('clearUserSession');
        await context.commit('authError', authError);
      });

    //
    console.info('logged out');

    return true;
  },
};
