<template>
  <AppView>
    <TopNav
      :title="meeting?.id ? 'Edit Meeting' : 'New Meeting'"
      title-class="font-title lg-app:ml-body"
      center-title
      type="back"
      slot="top-app-view"
      :prev-route="{ name: isMentor ? 'mentorMenteesView' : 'menteeMentorsView' }"
      show-back-button
    />
    <div class="mx-body tw-mb-8">
      <div v-if="errors">
        <p class="tw-text-center tw-text-base opacity-78 tw-my-20">{{ errors }}</p>
      </div>
      <div v-else-if="loading">
        <p class="tw-text-center tw-text-xs opacity-54 tw-my-20">Loading...</p>
      </div>
      <div v-else>
        <!-- explainer section -->
        <div class="tw-mt-5 tw-mb-14 tw-p-5 tw-rounded-10 tw-bg-app-primary-blue tw-shadow-sm" v-if="true" ref="top-page-position">
          <div class="tw-flex">
            <div>
              <svg class="tw-w-12 tw-h-12 tw-text-white tw-fill-current" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M58.8,8.4C27.3-17-14.9,19.9,6,54.6L3.8,68c-0.4,2,1.4,4.1,3.4,4c0,0.4,13-4.3,13.4-4.4C58.6,84.9,90.2,35.9,58.8,8.4z
                  M42.8,66.7c-7.3,1.6-14.6,0.6-21.2-2.8c-0.5-0.2-1-0.3-1.5-0.1L7.6,68.1l2.2-13.6c0.1-0.5,0-0.9-0.3-1.3C6,47.9,4.2,41.7,4.2,35.3
                  C4.3,8.7,36.1-6,56.4,11.3C76.6,27.9,68.4,61.2,42.8,66.7L42.8,66.7z M41.9,30.2c0,1-0.8,1.9-1.9,1.9h-2.1c-1,0-1.9-0.8-1.9-1.9
                  c0-1,0.8-1.9,1.9-1.9h2.1C41.1,28.3,41.9,29.2,41.9,30.2z M57.5,28.1c-1-0.5-2.2-0.5-3.2,0.2l-4.5,3.1v-3.8c0-3.5-2.8-6.3-6.3-6.3
                  H22.1c-3.5,0-6.3,2.8-6.3,6.3v15.5c0,3.5,2.8,6.3,6.3,6.3h21.4c3.5,0,6.3-2.8,6.3-6.3v-2.8l4.3,3.5c2.1,1.7,5.2-0.1,5.1-2.7l0-10
                  C59.3,29.8,58.6,28.7,57.5,28.1L57.5,28.1z M46.1,43.1c0,1.4-1.2,2.6-2.6,2.6H22.1c-1.4,0-2.6-1.2-2.6-2.6V27.6
                  c0-1.4,1.2-2.6,2.6-2.6h21.4c1.4,0,2.6,1.2,2.6,2.6V43.1z M55.5,40.2l-5.5-4.4l5.5-3.8C55.5,32,55.5,40.2,55.5,40.2z"/>
              </svg>
            </div>
            <div class="tw-pl-5">
              <p class="tw-text-xs tw-text-white tw-font-bold tw-mb-2">
                NEW!
              </p>
              <p class="tw-text-white">
                You can now communicate with your {{isMentee ? 'mentor' : 'mentee'}} with Video Meetings!
              </p>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap">
          <div class="tw-w-full md:tw-w-3/5">
            <h4 class="font-title tw-mb-4">Meeting Details</h4>
            <CreateMeeting :meeting="meeting" @on-success="onSuccess" />
            <div v-if="meeting?.id" class="tw-mb-4 tw-border tw-border-red-500 tw-bg-red-50 tw-p-4 tw-rounded-10 tw-text-right md:tw-max-w-md">
              <BaseButton
                type="button"
                :disabled="deleteButtonDisabled"
                class="tw-bg-red-500 tw-border-0 tw-text-white tw-py-2 tw-px-3"
                :text="deleteButtonText"
                @click="deleteMeeting"
              />
            </div>
          </div>
          <div class="tw-w-full md:tw-w-2/5 md:tw-pr-6" ref="meeting-participants">
            <h4 class="font-title tw-mb-4">Participants</h4>
            <AddMeetingParticipants :meeting="meeting" />
          </div>
        </div>
      </div>
    </div>
  </AppView>
</template>

<script>
import CreateMeeting from '@/views/_partials/VideoMeetings/CreateMeeting.vue';
import AddMeetingParticipants from '@/views/_partials/VideoMeetings/AddMeetingParticipants.vue';

const defaultDeleteButtonText = 'Cancel Meeting';

export default {
  name: 'CreateMeetingView',
  components: {
    CreateMeeting,
    AddMeetingParticipants,
  },
  props: ['id'],
  data() {
    return {
      loading: true,
      meeting: {},
      errors: undefined,
      deleteButtonDisabled: false,
      deleteButtonText: defaultDeleteButtonText,
    };
  },
  watch: {
    id: {
      async handler() {
        await this.getMeeting();
  
        // redirect if meeting exists and user is not owner
        this.assertMeetingOwner().then(() => {
          this.loading = false;
        });
      },
      immediate: true, // will call handler on component mount / create as well, so no need to call fn on mounted again
    },
  },
  methods: {
    onSuccess(meetingId) {
      // scroll to top if desktop

      this.$router.push({
        name: 'EditMeeting',
        params: {id: meetingId},
        query: this.$route.query,
      });

      if (this.$refs['top-page-position'] && this.isDesktopView) {
        this.$refs['top-page-position'].scrollIntoView({
          behavior: 'smooth'
        });
      } else if (this.$refs['meeting-participants']) {
        this.$refs['meeting-participants'].scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    async getMeeting() {
      const meetingId = this.id;
    
      if (meetingId) {
        this.meeting = await this.$store.dispatch('getMeetingByLinkId', [meetingId, true]);

        if (!this.meeting?.id) {
          // meeting does not exist
          this.$toasted.global.appError({
            errorMessage: 'Meeting does not exist',
          });

          this.$router.push({
            name: this.isMentor ? 'mentorMenteesView' : 'menteeMentorsView'
          });
        }
      }
    },
    async deleteMeeting() {
      if (!this.meeting?.id) return;

      this.deleteButtonDisabled = true;
      this.deleteButtonText = 'Cancelling...';

      const result = await this.$store.dispatch('cancelMeeting', {
        meetingid: this.meeting.id,
        linkId: this.meeting.linkid,
        reason: '[not needed any more]'
      });

      if (result) {
        this.$toasted.success('Meeting Cancelled!', { duration: 4000 });

        this.$router.push({
          name: this.isMentor ? 'mentorMenteesView' : 'menteeMentorsView'
        })
      }

      this.deleteButtonDisabled = false;
      this.deleteButtonText = defaultDeleteButtonText;
    },
    async assertMeetingOwner() {
      return new Promise((resolve, reject) => {
        if (this.meeting.id && Number(this.meeting?.admin) !== Number(this.$store.state.user.id)) {
          // redirect user
          this.$toasted.global.appError({
            errorMessage: 'You are not the meeting creator.',
          });
  
          this.$router.push({ name: this.isMentor ? 'mentorMenteesView' : 'menteeMentorsView' });

          reject(false);

          return;
        }

        resolve(true);
      });
    }
  },
};
</script>

<style>

</style>
