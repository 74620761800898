<template>
  <ContentLoadingTwoGrid v-if="loading && isMobileView" style="margin-left: -36px; margin-right: -36px;"/>
  <ContentLoadingFourGrid v-else-if="loading" style="margin-left: -36px; margin-right: -36px;"/>

  <div v-else-if="error">
    <p class="tw-text-red-400 tw-py-10" v-html="error"></p>
  </div>

  <div v-else>
    <h4>
      Choose 1 or more of the goals below
    </h4>
    <template v-for="goalGroup in goalGroups">
      <div class="tw-flex tw-mt-8 tw-items-center tw-relative" :key="goalGroup.id">
          <h3 class="tw-text-base lg:tw-text-lg opacity-54 leading-19 lg:leading-24 mr-10 tw-w-3/4 font-title">{{ goalGroup.goal_group }}</h3>
          <h6 class="tw-text-xs tw-text-right tw-w-1/4" v-show="!goalIsEmpty(goalGroup.id) && totalSelectedGoals(goalGroup.id) > 0">
            {{ totalSelectedGoals(goalGroup.id) }} Selected
          </h6>
      </div>
      <div class="tw-mb-6 tw-mt-1 opacity-54" v-if="goalGroup.description"  :key="goalGroup.id">
        {{goalGroup.description}}
      </div>
      <ViewMore
        class="tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-three tw-flex-wrap tw-mt-4"
        v-if="!goalIsEmpty(goalGroup.id)"
        :key="`${goalGroup.id}-goals`"
        :limit="3"
        :list="goalsByGroup(goalGroup.id)"
        v-slot="{ item: goal }"
      >
        <div :class="[
            'info-card tw-shadow-app tw-relative tw-cursor-pointer',
            {
              'info-card--selected': goalIsSelected(goal.id)
            }
          ]"
          @click="selectGoal(goal.id)"
        >
          <h4 class="tw-leading-tight tw-font-medium opacity-87 tw-text-xl tw-px-2 tw-py-4 tw-break-words">
            {{ goal.goal }}
          </h4>
          <h6 v-if="(goal.description || '').length" v-html="goal.description" class="opacity-54 tw-text-sm leading-19 tw-px-2 tw-pb-4 tw-mt-2 tw-whitespace-pre-wrap tw-break-words">
          </h6>
          <!-- <p class="tw-text-sm tw-leading-none tw-font-bold tw-p-2 tw-mt-3 info-card__action-info info-card__action-info--left">{{ goal.tym }} days</p> -->
        </div>
      </ViewMore>
      <div v-else :key="`${goalGroup.id}-goals`" class="tw-py-8">
        <p class="opacity-31 tw-text-center">Currently there are no goals in {{ goalGroup.goal_group }}</p>
      </div>
    </template>

  </div>
</template>

<script>
import ContentLoadingTwoGrid from '@/components/ContentLoadingTwoGrid.vue';
import ContentLoadingFourGrid from '@/components/ContentLoadingFourGrid.vue';
import settingsMixin from '@/mixins/settings';

export default {
  name: 'GoalsSettingsMentor',
  components: {
    ContentLoadingTwoGrid,
    ContentLoadingFourGrid,
  },
  mixins: [settingsMixin],
  data() {
    return {
      loading: true,
      error: undefined,
      goalGroups: [],
      goals: [],
      settingsActionUpdate: 'updateMentorGoals',
      groupIdName: 'groupid',
      selectedDataName: 'goals',
      totalGoalsCanSelect: 5,
    };
  },
  async mounted() {
    // so as to not change the store data
    this.selectedGoals = new Array(...this.$store.state.user.mentor.goals);
    this.isUpdating = new Array(...this.$store.state.user.mentor.goals).length > 0;

    // console.log(this.selectedGoals, this.isUpdating);
    // GET goalsList
    // [TODO] add api endpoints according to user license
    const requests = [
      ['getPillarGroups', 'goalGroups'],
      ['getGoals', 'goals'],
    ];

    await Promise.all([
      this.$store.dispatch(requests[0][0], [null, true]),
      this.$store.dispatch(requests[1][0], [null, true]),
    ]).catch((err) => {
      console.warn('error getting goals \n', err);
      this.$toasted.global.appError({ errorMessage: 'Sorry we found an error while fetching the goals' });
      this.error = 'Sorry we found an error while fetching the goals.<br>Maybe try again later or talk to us';
      this.loading = false;

      return false;
    }).then((res) => {
      if (!res[0] || !res[1]) {
        const errorMessage = 'Sorry we found an error while fetching the goals';
        this.$toasted.global.appError({ errorMessage });
        this.error = errorMessage;

        return false;
      }

      [this[requests[0][1]], this[requests[1][1]]] = res;

      this.sortGoalGroups();
      this.loading = false;

      return true;
    });

    await this.$nextTick();
    this.$emit('view-mounted');
  },
};
</script>

<style>
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';
</style>
