<template>
  <AppView
    body-class="tw-flex tw-flex-col tw-mx-auto tw-bg-gray-800"
  >
    <TopNav
      type="back"
      :show-notification-icon="false"
      :show-user-photo="!isNow"
      :prev-route="{ name: isMentor ? 'mentorMenteesView' : 'menteeMentorsView' }"
      :show-back-button="!isNow"
      slot="top-app-view"
      title="Video Meeting"
      title-class="pl-body"
    />
    <div class="tw-flex-grow">
      <div v-if="error" class="tw-bg-red-500 tw-p-4 tw-rounded-10">
        <p class="tw-text-white" v-html="error"></p>
      </div>
      <div v-else-if="loading" class="tw-p-4 tw-rounded-10">
        <p class="tw-italic tw-text-sm tw-text-center tw-text-white">Loading...</p>
      </div>
      <div v-else-if="hasPast || (meetingIsToday && !isNow) || (!isNow && !meetingIsToday && !hasPast)">
        <div class="tw-max-w-md tw-mx-auto tw-p-8 tw-shadow-sm tw-bg-red-100 tw-rounded-b-32">
          <p v-if="hasPast" class="tw-text-center tw-font-sans tw-mb-4">This meeting has passed</p>
          <p v-if="!isNow && !meetingIsToday && !hasPast" class="tw-text-center tw-font-sans tw-mb-4">Meeting has not started yet</p>
          <div v-if="meetingIsToday">
            <p class="tw-text-center tw-font-sans tw-mb-4">
              Meeting start{{ hasPast ? 'ed' : 's' }} <span class="tw-font-bold">{{ countDownTime }}</span>
              <!-- <br /> -->
              <!-- <span class="tw-text-sm tw-italic">{{ countDownTime }}</span> -->
            </p>
          </div>

          <BaseButton
            text="Exit Meeting"
            class="tw-block tw-w-full tw-mb-4"
            v-if="!isNow && !userIsAdmin"
            :to="{ name: isMentor ? 'mentorMenteesView' : 'menteeMentorsView' }"
          />

          <BaseButton v-if="userIsAdmin" text="End Meeting" @click="endMeeting" class="tw-bg-black tw-block tw-w-full tw-text-white tw-py-3 tw-px-4" />
        </div>
      </div>
      <div v-else class="tw-p-5">
        <VideoSdk :meeting="meeting" />

        <div v-if="userIsAdmin" class="tw-w-full tw-max-w-md tw-mx-auto tw-p-8 tw-shadow-sm tw-bg-app-light-blue tw-rounded-t-32 tw-fixed tw-bottom-0 tw-left-1/2 -tw-translate-x-1/2">
          <BaseButton text="End Meeting" @click="endMeeting" class="tw-bg-black tw-text-white tw-py-3 tw-px-4" />
        </div>
      </div>
    </div>

  </AppView>
</template>

<script>
import VideoSdk from '@/components/VideoSdk.vue';
import { now, toJsDate } from '@/modules/dataHelper';

const windowPeriod = 10; // minutes

export default {
  name: 'VideoMeeting',
  components: {
    VideoSdk,
  },
  props: ['id'],
  data() {
    return {
      loading: true,
      error: undefined,
      meeting: {},
      countDownTime: '',
      isNow: false,
      hasPast: false,
    };
  },
  computed: {
    meetingDate() {
      const time = this.meeting.time.split(':');
      const d = new Date(this.meeting.start).setHours(time[0], time[1]);

      return this.$moment(toJsDate(d, true)).format('YYYY-MM-DD HH:mm:ss');
    },
    meetingStartTime() {
      return this.$moment(this.meetingDate).subtract(windowPeriod, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    },
    meetingIsToday() {
      return this.$moment(this.meetingDate).isSame(now(), 'day');
    },
    userIsAdmin() {
      return Number(this.meeting.admin) === Number(this.$store.state.user.id);
    }
  },
  methods: {
    async getMeeting() {
      this.meeting = await this.$store.dispatch('getMeetingByLinkId', [this.id, true]);
    },
    async assertMeetingParticipant() {
      const isParticipant = await this.$store.dispatch('assertMeetingParticipant', this.meeting.id);

      // allow or redirect user
      return new Promise((resolve, reject) => {
        if (!isParticipant) {
          this.$toasted.global.appError({
            errorMessage: 'You are not a participant of this meeting.',
          });

          this.$router.push({
            name: this.isMentor ? 'mentorMenteesView' : 'menteeMentorsView'
          });

          reject(false);

          return;
        }

        resolve(true);
      });
    },
    meetingIsNow() {
      const duration = Number(this.meeting.duration) + Number(windowPeriod);
      const meetingEndDate = this.$moment(this.meetingDate).add(duration, 'minutes').format('YYYY-MM-DD HH:mm:ss');

      // moment is failing to calculate
      const startTime = new Date(this.meetingStartTime).getTime();
      const endTime = new Date(meetingEndDate).getTime();
      const nowTime = now();

      // console.log(startTime - nowTime, endTime, nowTime);

      this.isNow = startTime - nowTime < 0 && endTime - nowTime > 0;
    },
    meetingHasPast() {
      const duration = Number(this.meeting.duration) + Number(windowPeriod);
      const meetingEndDate = this.$moment(this.meetingDate).add(duration, 'minutes').format('YYYY-MM-DD HH:mm:ss');

      const meetingEndTime = new Date(meetingEndDate).getTime();
      const nowTime = now();

      this.hasPast = meetingEndTime - nowTime < 0;
    },
    countdown() {
      if (this.meetingIsToday && !this.isNow && !this.hasPast) {
        setTimeout(() => {
          this.countDownTime = this.$moment(this.meetingStartTime).fromNow();
          this.meetingIsNow();
          this.meetingHasPast();
          this.countdown();
        }, 3000);
      }
    },
    async endMeeting() {
      this.$store.dispatch('endMeeting', this.meeting.id);

      this.$toasted.info('Meeting ended', { duration: 4000 });

      this.$router.push({
        name: this.isMentor ? 'mentorMenteesView' : 'menteeMentorsView',
      });
    }
  },
  async created() {
    await this.$store.commit('showMenuNav', false);
    await this.$store.commit('isFullAppView', true);

    // get and assert meeting
    await this.getMeeting();

    // todo: assert if meeting has ended
    
    this.assertMeetingParticipant().then(() => {
      if (this.meetingIsToday && !this.isNow && !this.hasPast) {
        this.countdown();
      }

      this.meetingHasPast();

      this.loading = false;
    });
  },
  async beforeDestroy() {
    await this.$store.commit('showMenuNav', true);
    await this.$store.commit('isFullAppView', false);
  }
};
</script>

<style>

</style>
