import getResources from './get-resources';
import uploadResource from './upload-resource';
import uploadSequentialResource from './upload-sequential-resource';
import shareResource from './share-resource';

export default {
  ...getResources,
  ...uploadResource,
  ...uploadSequentialResource,
  ...shareResource,
};
