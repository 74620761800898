<template>
  <div
    :class="[
      'tw-text-center tw-bg-gray-200 tw-rounded-full tw-overflow-hidden tw-relative tw-inline-flex tw-items-center tw-justify-center',
      photoSize
    ]"
  >
    <img
      v-show="uPhoto.length > 0 && !loadingPhoto"
      :title="self ? 'me': userFirstName"
      :alt="self ? 'me': userFirstName"
      :class="[
        'tw-bg-gray-500 tw-rounded-full tw-text-center tw-block tw-mx-auto tw-object-cover',
        photoSize,
      ]"
      :src="uPhoto"
      ref="photo"
      loading="lazy"
      decoding="async"
      @load="loadingPhoto = false"
      @error="loadingPhoto = true"
    >
    <span
      v-show="uPhoto.length === 0 || loadingPhoto"
      :class="[
        'tw-block tw-bg-gray-200 tw-text-black tw-rounded-full tw-text-center tw-uppercase tw-leading-relaxed opacity-31',
      ]"
      style="font-size: 70%;"
    >
      {{ userFirstName | firstLetter }}{{ userLastName | firstLetter }}
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UserPhoto',
  props: {
    photo: {
      type: String,
      default: '',
    },
    userName: {
      type: Object,
      default: () => ({
        name: '',
        middle: '',
      }),
    },
    self: {
      type: Boolean,
      default: false,
    },
    photoSize: {
      type: [String, Array],
      default: 'w-56 h-56',
    },
  },
  data() {
    return {
      photoSrc: '',
      loadingPhoto: false,
      uPhoto: this.userPhoto(this.photo),
      userFirstName: this.userName.name,
      userLastName: this.userName.middle,
    };
  },
  watch: {
    async refreshMyPhoto() {
      await this.$nextTick();
      await this.fetchMyPhoto();
      await this.$nextTick();
    },
    photo(newVal) {
      this.uPhoto = this.userPhoto(newVal);
    },
  },
  computed: {
    textPadding() {
      return 'tw-pt-2 tw-my-2';
    },
    ...mapState({
      refreshMyPhoto: (state) => state.ui.refreshMyPhoto,
    }),
  },
  methods: {
    async fetchMyPhoto() {
      if (this.self) {
        this.uPhoto = '';
        const user = await this.$store.dispatch('getUserData', this.$store.state.user.id) || {};
        this.uPhoto = this.userPhoto(user.photo);
        this.userFirstName = user.name;
        this.userLastName = user.last_name;
      }
    },
  },
  async mounted() {
    await this.$nextTick();
    await this.$nextTick();
    await this.$nextTick();
    this.uPhoto = this.userPhoto(this.photo);
    // generate blob
    // because it is usually cached
    // instead of getting from the server every time
    // DOES NOT WORK
    if (this.self) {
      await this.fetchMyPhoto();
    }

    if (this.photo.length === 0 || !this.$refs.photo) return false;
    this.loadingPhoto = true;

    this.$refs.photo.onloadend = () => {
      this.loadingPhoto = false;
    };

    this.loadingPhoto = false;
    return true;
  },
};
</script>

<style>

</style>
