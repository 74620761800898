<template>
  <AppView>
    <TopNav
      title="Revert Task"
      title-class="font-title lg-app:ml-body"
      center-title
      type="back"
      slot="top-app-view"
    />
    <div class="mx-body tw-mb-8 tw-h-full">
      <div v-if="error">
        <p class="tw-text-center tw-text-base opacity-78 tw-my-20" v-html="error"></p>
      </div>
      <div v-else-if="loading">
        <p class="tw-text-center tw-text-xs opacity-54 tw-my-20">Loading...</p>
      </div>
      <form
        method="POST"
        enctype="multipart/form-data"
        ref="revert-task-form"
        class="tw-py-16 tw-max-w-md tw-mx-auto tw-h-full"
        v-else
      >
        <p class="tw-text-xs leading-17 opacity-54 tw-mb-2">Task</p>
        <h4 class=" tw-text-xl leading-24 tw-mb-8 tw-capitalize">{{ task.task_name }}</h4>
        <div v-if="taskIsCompleted(task.status)" class="tw-text-center">
          <p class="tw-py-10 tw-text-green-500 tw-text-base">Task has been reviewed</p>
          <BaseButton text="View my mentees" :to="{ name: 'mentorMenteesView' }" />
        </div>
        <div v-else-if="taskIsOnReview(task.status)">
          <p class="tw-text-app-primary-orange tw-text-sm tw-mb-8">
            To revert this task, write your detailed comments below, or upload a resource, or both
          </p>

          <!-- report text -->
          <p class="tw-text-xs leading-17 opacity-54">Your comments</p>
          <QuillTextareaGroup
            label=""
            type="text"
            placeholder=""
            input-classes="focus:tw-bg-gray-100 tw-w-full tw-mb-4"
            v-model="reportText"
            :rows="4"
          />


          <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <p class="tw-text-xs leading-17 opacity-54">Submit Resource</p>
            <BaseButton
              text="Clear"
              v-if="hasAttachedResources"
              @click="taskResources = []"
            />
          </div>

          <div v-if="hasAttachedResources">
            <div class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-4">
              <div
                class="info-card tw-shadow-app tw-relative tw-cursor-pointer"
                v-for="resource in taskResources"
                :key="resource.id"
              >
                <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">
                  {{ resource.title || resource.name || resource.data || resource.resource }}
                </h4>
                <div class="tw-flex tw-items-end">
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                    {{ resourceType((resource.file_type || resource.type || resource.name), (!resource.file_type && resource.type.length === 0) ) }}
                  </p>
                </div>
              </div>
            </div>
            <BaseInput
              type="file"
              name="file1"
              :multiple="true"
              accepts="*"
              class="tw-py-3 tw-w-full tw-my-6"
              v-model="taskResources"
            />
          </div>

          <div v-else class="tw-py-10 tw-text-center tw-bg-gray-200">
            <p class="tw-text-center tw-text-base opacity-78">Upload a resource</p>
            <p class="tw-text-center tw-text-xs opacity-78 tw-pb-4">
              This will be used by your mentee to improve their task
            </p>

            <BaseInput
              type="file"
              name="file1"
              :multiple="true"
              accepts="*"
              class="tw-py-3 tw-w-full tw-my-6"
              v-model="taskResources"
            />
          </div>

          <BaseButton
            :text="saveButtonText"
            :disabled="!canSave || saveDisabled"
            style="margin-top: 80px;"
            class="tw-shadow-app tw-block tw-w-full tw-bg-black tw-border-transparent tw-text-white tw-py-3"
            @click="onSubmit"
          />
        </div>
        <div v-else class="tw-text-center">
          <p class="tw-py-10 tw-text-green-500 tw-text-base">Task is not submitted for review</p>
          <BaseButton text="View my mentees" :to="{ name: 'mentorMenteesView' }" />
          <!-- GOODIES -->
        </div>
      </form>
    </div>
  </AppView>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
// import { totalSumOfArray } from '@/modules/dataHelper';
import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';

export default {
  name: 'RevertTask',
  props: ['id'],
  components: {
    BaseInput,
    QuillTextareaGroup,
  },
  data() {
    return {
      loading: true,
      error: undefined,
      task: {},
      reportText: '',
      taskResources: [],
      mentee: {},
      saveButtonText: 'Submit',
      saveDisabled: false,
    };
  },
  computed: {
    hasAttachedResources() {
      return this.taskResources.length > 0;
    },
    canSave() {
      return this.taskResources.length > 0 || this.reportText.length > 0;
    },
  },
  methods: {
    async resetForm() {
      this.taskResources = [];
    },
    async onSubmit() {
      if (!this.canSave) {
        this.$toasted.info(
          'Error. Can\'t revert task',
          {
            duration: 4000,
            position: 'top-right',
          },
        );
        return;
      }

      this.saveButtonText = 'working... (0%)';
      this.saveDisabled = true;

      const revertTaskDispatchName = this.isSequentialProgram ? 'revertSequentialTask' : 'revertTask';
      const response = await this.$store.dispatch(revertTaskDispatchName, [this.id, this.reportText, this.taskResources]);

      // const $this = this;
      await this.$nextTick();

      // eslint-disable-next-line prefer-arrow-callback
      // setTimeout(function again() {
      //   const totalPercentage = Math.round((totalSumOfArray(progress) / progress.length) || 0);
      //   $this.saveButtonText = `working... (${totalPercentage}%)`;
      //   if (totalPercentage < 100) {
      //     setTimeout(again, 100);
      //   }
      // }, 500);

      if (!response) {
        this.$toasted.error('Error in reverting task', { duration: 4000, position: 'top-right' });
        this.saveButtonText = 'Submit';
        this.saveDisabled = false;

        return;
      }

      await this.$nextTick();
      this.saveButtonText = '100% please wait...';
      await this.$nextTick();

      this.$toasted.success('success!', { duration: 3500 });

      this.$router.push({
        name: 'mentorMenteeTasks',
        params: {
          id: this.task.menteeid,
        },
      });

      this.saveButtonText = 'Submit';
      this.saveDisabled = false;

      // get prompt for user
      this.$store.dispatch('getCurrentUserJourneyAction');
    },
  },
  async created() {
    this.error = undefined;
    this.loading = true;

    const isPermitted = await this.assertTask(this.id);
    if (!isPermitted || !this.isMentor) return;

    const dispatchName = this.isSequentialProgram ? 'getSequentialTask' : 'getTask';
    await this.$store.dispatch(dispatchName, [this.id, true])
      .catch(() => {
        this.error = 'Could not get the task';
        this.$toasted.global.appError({ errorMessage: this.error });
        return false;
      })
      .then(async (task) => {
        if (!task) {
          this.error = 'Could not get the task';
          return false;
        }

        this.task = { ...task };

        this.error = undefined;
        this.loading = false;

        return true;
      });
  },
};
</script>

<style>

</style>
