import resolve from '../../../modules/api/resolve';
import { apiPost, generateFormData } from '../../../modules/apiHelper';

export default {
  async getUserGoalsBadges(context, userId) {
    const formData = generateFormData({
      userid: userId || context.state.user.id,
    });

    const userGoalsBadges = await resolve(apiPost('goal_badge_user', formData, 58));

    if (userGoalsBadges) {
      return userGoalsBadges.goal_badge_user;
    }

    return [];
  },
};
