import store from '../../store';
import Mentor from './mentor';
import Mentee from './mentee';
import Chat from '../../views/Chat.vue';
import EllyEbby from '../../views/EllyEbby.vue';
import TaskDetails from '../../views/TaskDetails.vue';
import ResourceView from '../../views/ResourceView.vue';
import UserProfile from '../../views/user/UserProfile.vue';
import NewUser from '../../views/user/NewUser.vue';
import GroupChat from '../../views/GroupChat.vue';
import MatchEndedSurvey from '../../views/surveys/MatchEndedSurvey.vue';
import ProgramSurveys from '../../views/surveys/ProgramSurveys.vue';
import CreateMeeting from '../../views/CreateMeeting.vue';
import VideoMeeting from '../../views/VideoMeeting.vue';
import MentorshipGroup from '../../views/MentorshipGroup/MentorshipGroup.vue';
import MentorshipGroupTasks from '../../views/MentorshipGroup/MentorshipGroupTasks.vue';
import MentorshipGroupChat from '../../views/MentorshipGroup/MentorshipGroupChat.vue';
import MentorshipGroupProfile from '../../views/MentorshipGroup/MentorshipGroupProfile.vue';
import GroupTaskDetails from '../../views/GroupTaskDetails.vue';

const userData = store.dispatch('getLoggedInUserData');
const UserRoutes = async () => {
  await userData;
  let User = [];

  if (store.state.user.type) {
    User = store.state.user.type === 3 ? Mentor : Mentee;
  }

  return [{
    path: '/app',
    // name: 'app',
    async component() {
      await userData;
      return store.state.user.type === 3
        ? import('../../views/mentor/MentorApp.vue')
        : import('../../views/mentee/MenteeApp.vue');
    },
    meta: {
      requiresLogin: true,
      requiresUserType: true,
    },
    children: [
      {
        path: '',
        name: 'app',
        async component() {
          await userData;
          return store.state.user.type === 3
            ? import('../../views/mentor/Home.vue')
            : import('../../views/mentee/Home.vue');
        },
        alias: 'default',
      },
      {
        path: 'settings',
        name: 'settings',
        async component() {
          await userData;
          return import('../../views/Settings.vue');
        },
      },
      {
        path: 'modules',
        name: 'modules',
        component: () => import('../../views/Modules.vue'),
      },
      {
        path: 'module/:id',
        name: 'module',
        component: () => import('../../views/Module.vue'),
        props: true,
      },
      {
        path: 'profile',
        name: 'profile',
        component: UserProfile,
      },
      {
        path: 'resources',
        name: 'resources',
        async component() {
          await userData;
          return store.state.user.type === 3
            ? import('../../views/mentor/Resources.vue')
            : import('../../views/mentee/Resources.vue');
        },
      },
      {
        path: 'new-user',
        alias: 'profile-settings',
        name: 'NewUser',
        component: NewUser,
      },
      {
        path: 'elly',
        alias: 'ebby',
        name: 'ellyebby',
        component: EllyEbby,
        props: { lgGrid: true },
      },
      {
        path: 'chat/:id',
        name: 'chatView',
        component: Chat,
        props: true,
      },
      {
        path: 'task/:id',
        name: 'FullTaskDetails',
        component: TaskDetails,
        props: true,
      },
      {
        path: 'resource',
        name: 'ResourceView',
        component: ResourceView,
        props: true,
      },
      {
        path: 'group-chat',
        name: 'GroupChat',
        component: GroupChat,
        props: true,
      },
      {
        path: 'surveys/matchended-survey',
        name: 'MatchEndedSurvey',
        component: MatchEndedSurvey,
        props: true,
      },
      {
        path: 'surveys/program-surveys',
        name: 'ProgramSurveys',
        component: ProgramSurveys,
        props: true,
      },
      {
        path: 'session/create-video-meeting',
        name: 'CreateMeeting',
        component: CreateMeeting,
        props: true,
      },
      {
        path: 'session/edit-video-meeting/:id', // will use queries to pre-select users
        name: 'EditMeeting',
        component: CreateMeeting,
        props: true,
      },
      {
        path: 'session/video-meeting/:id',
        name: 'VideoMeeting',
        component: VideoMeeting,
        props: true,
      },
      {
        path: 'mentorship-group/:id',
        name: 'mentorshipGroup',
        component: MentorshipGroup,
        props: true,
        children: [
          {
            path: 'tasks',
            name: 'mentorshipGroupTasks',
            // alias: '',
            component: MentorshipGroupTasks,
          },
          {
            path: 'chat',
            name: 'mentorshipGroupChat',
            component: MentorshipGroupChat,
          },
          {
            path: 'profile',
            name: 'mentorshipGroupProfile',
            component: MentorshipGroupProfile,
          },
        ],
      },
      {
        path: 'group-task/:id',
        name: 'FullGroupTaskDetails',
        component: GroupTaskDetails,
        props: true,
      },
      ...User,
    ],
  }];
};

export default UserRoutes;
