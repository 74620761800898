import Login from '../views/Auths/Login.vue';
import ForgotPassword from '../views/Auths/ForgotPassword.vue';
import ResetPassword from '../views/Auths/ResetPassword.vue';
import EmailVerify from '../views/Auths/EmailVerify.vue';
import CompanyVerify from '../views/Auths/CompanyVerify.vue';

const mainAppSignupRoutes = [
  {
    path: '/mentor-signup',
    name: 'mentorSignup',
    component: () => import(/* webpackChunkName: "auth2" */ '../views/Auths/MentorSignUp.vue'),
  }, {
    path: '/mentee-signup',
    name: 'menteeSignup',
    component: () => import(/* webpackChunkName: "auth2" */ '../views/Auths/MenteeSignUp.vue'),
  }, {
    path: '/auth/signup-success',
    name: 'signup-success',
    component: () => import(/* webpackChunkName: "auth2" */ '../views/Auths/SignupSuccess.vue'),
  },
];

const authRoutes = async () => [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/verify-account',
    name: 'verify-account',
    component: EmailVerify,
  },
  {
    path: '/verify-comp',
    name: 'verify-company',
    component: CompanyVerify,
  },
  {
    path: '/verify',
    name: 'do-verify-account',
    component: EmailVerify,
  },
  //
  ...mainAppSignupRoutes,
];

export default authRoutes;
