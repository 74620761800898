import resolve from '../../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { isStaleData, now, toBoolean } from '../../../modules/dataHelper';

export default {
  async getGoals(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootGetters.userCompanyId;
    if (!companyid) return false;

    const goals = await apiStore.getItem(`goals/${companyid}`) || [0, []];

    if (!refresh && !isStaleData(goals[0]) && goals[1].length) return goals[1];

    const result = await resolve(apiPost('company-goals', generateFormData({
      companyid,
    }), 5)).then((data) => {
      if (!data) return false;
      // console.log(data);

      const companyGoals = data['company-goals'] || [];

      if (companyGoals.length > 0) {
        apiStore.setItem(`goals/${companyid}`, [now(), companyGoals]);
      }

      return companyGoals;
    });

    return result;
  },

  // get logged in user goals
  async getUserGoals(context) {
    const formData = generateFormData({
      userid: context.rootState.user.id,
    });

    const userType = context.rootState.user.type;
    const { userTypeName } = context.rootGetters;

    // ! needs to be one. ERROR!!!
    const apiCall = (userType === 3)
      ? apiPost('goals_check', formData)
      : apiPost('sub_goals_check', formData, 1);

    const goals = await apiCall
      .catch((err) => {
        console.warn('getUserGoals\nCannot get user goals\n', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const userGoals = res.data.goals_check || [];
        const addUserGoalMutationName = userType === 3
          ? 'addMentorGoal'
          : 'addMenteeGoal';
        //

        userGoals.forEach((goal) => {
          context.commit(addUserGoalMutationName, goal.ementid); // ementid is the selected goal's goal id
        });
        await apiStore.setItem(`${userTypeName}goals`, context.rootState[userTypeName].goals);

        return userGoals;
      });

    return goals;
  },

  async getMenteeFinishedGoals(context, menteeId) {
    const menteeid = menteeId || context.rootState.user.id;

    if (!menteeid) {
      return [];
    }

    const { companyid } = context.rootState.user;

    const menteeFinishedGoals = resolve(apiPost('end_goal_log', generateFormData({
      companyid,
    }), 38))
      .then((res) => {
        if (!res) return false;

        // filter only mentee's data
        // eslint-disable-next-line eqeqeq
        return res.end_goal_log.filter((goal) => goal.menteeid == menteeid);
      });
    //

    return menteeFinishedGoals;
  },
};
