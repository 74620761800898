import resolve from '../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../modules/apiHelper';
import { invalideDate, isStaleData, now } from '../../modules/dataHelper';
// import journeyActionsList from './journeyActionsList.json';

export default {
  state: {
    // journeyActionsList,
    currentActionPrompt: {},
    openPrompt: false,
  },
  mutations: {
    setCurrentActionPrompt(state, actionPrompt) {
      state.currentActionPrompt = actionPrompt;
    },
    showPrompt(state, show = true) {
      state.openPrompt = show;
    },
  },
  actions: {
    async getCurrentUserJourneyAction(context) {
      const { id, programid } = context.rootState.user;

      const action = await resolve(apiPost('prog_curr_usr_act', generateFormData({ userid: id, progid: programid }), 71))
        .then(async (res) => {
          if (!res) {
            console.warn('getCurrentUserJourneyAction::journeys/index.js Error in getting badges');
            return false;
          }

          const currentActionPrompt = res.prog_curr_usr_act[0];

          if (currentActionPrompt) {
            if (Number(currentActionPrompt.badgeid) && Number(currentActionPrompt.badge_type) !== 1) {
              // get badge
              currentActionPrompt.badge = await context.dispatch('getBadge', currentActionPrompt.badgeid);
            }

            context.commit('setCurrentActionPrompt', currentActionPrompt);

            // only show unrevealed prompts
            if (Number(currentActionPrompt.status) === 0) {
              context.commit('showPrompt', true);
            }
          } else {
            context.commit('setCurrentActionPrompt', {});
          }

          return currentActionPrompt;
        });
      //

      return action;
    },
    async markCurrentJourneyActionAsRevealed(context) {
      context.commit('showPrompt', false);

      const formData = {
        progid: context.rootState.user.programid,
        userid: context.rootState.user.id,
        actid: context.state.currentActionPrompt.id,
      };

      const result = await resolve(apiPost('prog_jan_log', JSON.stringify(formData), 3, {
        'Content-Type': 'application/json',
      }));

      if (result) {
        context.dispatch('getCurrentUserJourneyAction');
      }
    },
    async getBadge(context, badgeId) {
      const badges = await context.dispatch('getBadges');

      return badges.find((badge) => Number(badge.id) === Number(badgeId));
    },

    async getBadges(context, refresh = false) {
      const { companyid, programid } = context.rootState.user;

      const cacheKey = `programBadges/${programid}`;

      const programBadges = await apiStore.getItem(cacheKey) || [invalideDate, []];

      if (!refresh && !isStaleData(programBadges[0]) && programBadges[1].length) return programBadges[1];

      const badges = await resolve(apiPost('prog_badge_list', generateFormData({ companyid, progid: programid }), 71))
        .then((res) => {
          if (!res) {
            console.warn('getBadges::journeys/index.js Error in getting badges');
            return false;
          }

          apiStore.setItem(cacheKey, [now(), res.prog_badge_list]);

          return res.prog_badge_list;
        });
      //

      return badges;
    },
  },
};
