import resolve from '../../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { isStaleData, now } from '../../../modules/dataHelper';

export default {
  async getPillarGroups(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootGetters.userCompanyId;
    if (!companyid) return false;

    const pillars = await apiStore.getItem(`pillars/${companyid}`) || [0, []];

    if (!refresh && !isStaleData(pillars[0]) && pillars[1].length) return pillars[1];

    const result = await resolve(apiPost('company-pillars', generateFormData({
      companyid,
    }), 5)).then((data) => {
      if (!data) return false;
      // console.log(data);

      const companyPillars = data['company-pillars'] || [];

      if (companyPillars.length > 0) {
        apiStore.setItem(`pillars/${companyid}`, [now(), companyPillars]);
      }

      return companyPillars;
    });

    return result;
  },
};
