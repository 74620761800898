import resolve from '../../../../../modules/api/resolve';
import {
  apiPost,
  generateFormData,
} from '../../../../../modules/apiHelper';

export default {
  async getUserPrograms(context, userId) {
    // eslint-disable-next-line no-underscore-dangle
    const _userId = userId || context.getters.userId;
    const userPrograms = await resolve(apiPost('prog_user_list', generateFormData({
      userid: _userId,
    }), 63));

    if (!userPrograms) return false;

    // load the programs' details
    const programRequests = [];

    userPrograms.prog_user_list.forEach((userProgram) => {
      const request = context.dispatch('getProgram', [userProgram.progid, false]);
      programRequests.push(request);
    });

    const response = await Promise.all(programRequests);

    return response.filter((res) => !!res);
  },

  async userJoinProgram(context, data) {
    const formData = generateFormData(data);

    const response = await resolve(apiPost('ad_user', formData, 63)).then(async (res) => {
      if (!res) return false;

      return true;
    });

    return response;
  },

  async userDeclineProgram(context, data) {
    const formData = generateFormData(data);

    const response = await resolve(apiPost('decline_req', formData, 63)).then(async (res) => {
      if (!res) return false;

      return true;
    });

    return response;
  },

  async userHasPendingSurvey(context) {
    const response = await resolve(apiPost('prog_suv_checker', generateFormData({
      userid: context.rootGetters.userId,
    }), 72));

    if (!response) return false;

    // 0 means there is no pending survey
    if (Number(response.prog_suv_checker) !== 0) {
      return true;
    }

    return false;
  },
};
