<template>
  <AppView
    :show-bottom-bar="resourcesAreSelected"
    padd-bottom
  >
    <TopNav
      title="RESOURCES"
      title-class="lg:ml-body lg-app:tw-font-bold"
      center-title
      type="back"
      :prev-route="prevRoute"
      slot="top-app-view"
    >
    </TopNav>

    <UploadResourceModal />

    <div class="mx-body tw-mb-8">
      <!-- MY RESOURCES -->
      <div class="tw-flex tw-flex-wrap tw-mt-8 tw-items-center tw-relative">
        <h6 class="tw-text-base font-title tw-text-app-primary-orange">My Resources</h6>
        <div class="tw-ml-auto tw-flex tw-flex-nowrap tw-items-center">
          <BaseButton
            text="Upload"
            class="tw-bg-gray-100"
            @click="openUploadModal()"
            icon
          >
            <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
                c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
            </svg>
          </BaseButton>
        </div>
      </div>
      <div class="divider tw-border tw-w-full tw-mt-2 opacity-11"></div>
      <MyResources />

      <!-- REFERENCE CENTER -->
      <SharedResources />
    </div>

    <div
      slot="bottom-app-view"
      class="px-body tw-py-4"
    >
      <BaseButton
        class="tw-py-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl"
        :text="`SHARE ${ shareToWho || totalSelectedResources + ' resources' }`"
        @click.native.stop="shareResource(selectedResources, $event, selectedMentors, selectedLinkResources)"
      />
    </div>
  </AppView>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadResourceModal from '@/views/_partials/UploadResourceModal.vue';
import MyResources from '@/views/_partials/MyResources.vue';
import SharedResources from '@/views/_partials/SharedResources.vue';
import ResourceMixin from '@/mixins/resources';
import { apiUrl } from '@/modules/apiHelper';

export default {
  name: 'MenteeResources',
  components: {
    UploadResourceModal,
    MyResources,
    SharedResources,
  },
  mixins: [ResourceMixin],
  props: ['prevRoute'],
  data() {
    return {
      mentorsResources: {},
      selectedMentors: [],
      loading: {
        myResources: true,
        mentorsResources: true,
        otherResources: true,
      },
      error: {
        myResources: undefined,
        mentorsResources: {},
        otherResources: undefined,
      },
      apiUrl,
    };
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
  },
  created() {
    this.$store.commit('isFlatView', false);
  },
};
</script>

<style>

</style>
