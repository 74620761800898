<template>
  <AppView padd-bottom>
    <TopNav
      slot="top-app-view"
      title=""
      title-class="tw-mr-auto"
      type="menu"
      @input="openSideNav()"
      show-notification-icon
    />
    <div class="mx-body">
      <transition name="fade" mode="out-in" appear>
        <div v-if="error" class="tw-text-center">
          <p class="tw-text-xs opacity-54 tw-py-18 tw-text-center" v-html="error"></p>
        </div>
        <div v-else-if="loading">
          <p class="tw-text-xs opacity-54 tw-py-18 tw-text-center">Finding available mentors...</p>
        </div>
        <div v-else class="tw-overflow-auto">
          <div v-if="hasMentors">
            <div class="md:tw-w-1/2 tw-bg-app-primary-orange tw-p-4 tw-mx-auto tw-mb-4 tw-rounded-10 tw-mt-8">
              <p class="tw-text-app-primary-orange-contrast">
                You can only have a maximum of <strong>1</strong> mentor in this program.
              </p>
            </div>
          </div>

          <div v-else>
            <div class="md:tw-w-1/2 tw-bg-app-primary-orange tw-p-4 tw-mx-auto tw-mb-4 tw-rounded-10 tw-mt-4">
              <p class="tw-text-app-primary-orange-contrast">
                You can only have a maximum of <strong>1 mentor</strong> in this program.
              </p>
            </div>
          </div>

          <h2 class="tw-text-app-primary-orange font-title tw-text-xl tw-mt-6 tw-mb-1 tw-text-center">Mentors List</h2>

          <div class="tw-shadow-app tw-p-4 tw-rounded-10 tw-bg-white tw-mb-4 tw-mx-4 tw-flex tw-items-center">
            <p class="tw-text-sm tw-text-app-primary-orange tw-mr-4">
              Filter:
            </p>

            <SelectGroup
              :options="filterUsing"
              label="Filter By"
              name-key="label"
              :reduce="value => value.id"
              :clearable="true"
              v-model="selectedFilter"
              class="tw-flex-grow"
            />
            <SelectGroup
              v-if="selectedFilter"
              :options="filterOptions.values"
              :label="filterOptions.label"
              :name-key="filterOptions.labelKey"
              :reduce="value => value.id"
              clearable
              searchable
              v-model="selectedFilterValue"
              class="tw-flex-grow"
            />
          </div>

          <ViewMore
            class="tw-mt-4 tw-flex tw-flex-wrap tw-justify-center"
            key="available-mentors"
            more-btn-text="more mentors"
            :limit="6"
            :list="filteredMentorsList"
            v-slot="{ item: mentor }"
          >
            <div class="tw-relative tw-block tw-w-full tw-max-w-xs tw-text-center tw-border tw-p-3 tw-rounded-32 tw-m-2 transition-fast hover:tw-shadow-app-lg">
              <UserPhoto
                :photo="mentor.photo"
                class="tw-inline-block"
                :user-name="mentor"
              />
              <h4 class="tw-text-center tw-font-normal tw-text-xl leading-24">
                {{ mentor.name }} {{ mentor.middle }}
              </h4>
              <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 tw-mr-2 leading-19 tw-px-2 tw-inline-block">
                Profession: <b>{{ mentor.profession.profession || '...' }}</b>
              </p>
              <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
                Experience: <b>{{ mentor.experience.experience }} years</b>
              </p>
              <template v-if="mentor.department">
                <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
                  Department: <b>{{ mentor.department?.departments }}</b>
                </p>
              </template>

              <template v-if="mentor.regional_area">
                <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
                  Region: <b>{{ mentor.regional_area?.region }}</b>
                </p>
              </template>

              <template v-if="mentor.sub_area">
                <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
                  Sub-area: <b>{{ mentor.sub_area?.sub_area }}</b>
                </p>
              </template>

              <br>
              <p
                v-if="mentor.bio"
                class="tw-text-base tw-text-center opacity-78 tw-text-black tw-mt-2 leading-19 h-60 tw-overflow-hidden"
              >
                {{ mentor.bio }}
              </p>
              <div v-else>
                <p class="tw-text-sm tw-text-center opacity-54 tw-text-black leading-19 tw-pt-2">
                  availability
                </p>
                <p
                  class="tw-text-xs tw-mr-2 tw-text-center opacity-54 tw-text-white leading-19 tw-bg-gray-700 tw-rounded-full tw-px-2 tw-inline-block"
                  v-for="(availability, index) in mentor.availabilities"
                  :key="index"
                >
                  {{ availability.availability }}
                </p>

              </div>
              <div v-if="isMyMentor(mentor.id)" class="tw-mt-3">
                <p class="tw-rounded-10 tw-text-center tw-inline-block tw-bg-app-deep-blue tw-text-white tw-py-1 tw-px-3 tw-mb-1">
                  {{mentor.name}} is your mentor
                </p>
                <br />
              </div>
              <div v-if="isRequested(mentor.id)" class="tw-mt-3">
                <p class="tw-rounded-10 tw-text-center tw-inline-block tw-bg-app-deep-blue tw-text-white tw-py-1 tw-px-3 tw-mb-1">
                  Request already sent
                </p>
                <br />
              </div>
              <div class="tw-flex --tw-justify-between tw-justify-center tw-py-4">
                <!-- <BaseButton
                  @click="sendMentorRequest(mentor.id)"
                  text="Send Request"
                  class="tw-bg-gray-200 tw-hidden"
                /> -->
                <BaseButton
                  :to="`/app/mentor/${mentor.id}/bio`"
                  text="Full Profile"
                  icon
                  icon-right
                >
                  <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M16,67.8c0-1.2,0.4-2,1.2-2.9l29-29l-29-29c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l31.9,31.9c1.6,1.6,1.6,4.1,0,5.7
                      L22.9,70.8c-1.6,1.6-4.1,1.6-5.7,0C16.4,69.9,16,69,16,67.8z"/>
                  </svg>
                </BaseButton>
              </div>
              <!-- <div class="tw-border opacity-18 tw-w-full tw-mt-6"></div> -->
            </div>
          </ViewMore>
        </div>
      </transition>
    </div>
  </AppView>
</template>

<script>
import SelectGroup from '@/components/SelectGroup.vue';
// import _groupBy from 'lodash/groupBy';
// import _compact from 'lodash/compact';


export default {
  name: 'MentorsSuggestionsList',
  components: {
    SelectGroup,
  },
  data() {
    return {
      mentorsList: [],
      selectedGoal: undefined,
      goalsGroupedByPillars: [],
      // goals: [],
      myMentors: [],
      requestedMentors: [],
      findMentors: false,
      loading: true,
      error: undefined,
      lockKengenExperienceOnly: false,
      filterUsing: [], // [profession, experience, gender, etc]
      selectedFilter: undefined,
      selectedFilterValue: undefined,
      filterOptions: {},
      filters: [],
    };
  },
  watch: {
    selectedFilter(newVal) {
      if (newVal) {
        this.filterOptions = this.filters.find((filter) => filter.labelKey === newVal);
      } else {
        this.filterOptions = {};
      }
    },
  },
  computed: {
    hasMentors() {
      return this.myMentors.length > 0;
    },
    filteredMentorsList() {
      const selectedFilter = this.filterUsing.find((filter) => filter.id === this.selectedFilter);

      console.log(selectedFilter);

      return this.mentorsList.filter((mentor) => {
        if (this.selectedFilterValue) {
          return mentor[selectedFilter.id] === this.selectedFilterValue;
        }

        return true;
      });
    },
  },
  methods: {
    isMyMentor(mentorId) {
      // eslint-disable-next-line eqeqeq
      return !!(this.myMentors.find((m) => m.id == mentorId));
    },
    isRequested(mentorId) {
      // eslint-disable-next-line eqeqeq
      return !!(this.requestedMentors.find((m) => m.mentorid == mentorId));
    },
    async getMentorsSuggestionsList() {
      this.loading = true;

      const mentorsList = await this.$store.dispatch('getProgramMentorsList', [undefined, true]);

      if (Array.isArray(mentorsList)) {
        this.mentorsList = mentorsList;
      } else {
        this.error = 'Error finding availabe mentors';
      }

      this.loading = false;
    },
    async generateCompanyFilters() {
      this.filters = await this.$store.dispatch('getMatchListFilters');

      this.filterUsing = this.filters.map((filter) => ({
        id: filter.labelKey,
        label: filter.label,
      }));
    },
  },
  async created() {
    this.$store.commit('isFlatView', false);

    if (this.isGoalOrientedProgram) {
      this.$router.push({
        name: 'MentorsSuggestionsList',
      });

      return;
    }

    this.loading = true;

    [this.myMentors, this.requestedMentors] = await Promise.all([
      this.$store.dispatch('getMyMentors'),
      this.$store.dispatch('getMyPendingMentorsRequests'),
      this.generateCompanyFilters(),
    ]);

    await this.getMentorsSuggestionsList();

    this.loading = false;
  },
};
</script>

<style>

</style>
