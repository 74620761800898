var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'chat-bubble chat-bubble--resource tw-overflow-visible box-border lg:tw-rounded-55 tw-fill-current',
    { 'chat-bubble--right lg:tw-mr-2 tw-rounded-l-55 tw-rounded-r-none' : _vm.textIsSelf },
    { 'chat-bubble--left tw-rounded-r-55 tw-rounded-l-none tw-border tw-border-l-0 lg-app:tw-border-l tw-border-app-primary-blue' : !_vm.textIsSelf }
  ]},[_c('div',{class:[
    'tw-py-2',
    {'tw-pl-12 pr-body lg:tw-pr-10 lg:tw-mr-2': _vm.textIsSelf},
    {'tw-pr-12 pl-body lg:tw-pl-10 lg:tw-ml-2': !_vm.textIsSelf},
    ]},[_c('div',{staticClass:"tw-pr-4"},[_c('p',{staticClass:"tw-text-xs opacity-31 tw-uppercase"},[_vm._v("RESOURCE")]),(_vm.isLinkResourceRef)?_c('a',{staticClass:"tw-underline tw-font-bold tw-text-sm leading-17 tw-mb-2 tw-break-all tw-text-app-primary-blue",attrs:{"href":_vm.resource.data,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.resource.title || _vm.resource.name || _vm.resource.data)+" ")]):_c('h4',{staticClass:"tw-font-bold tw-text-sm leading-17 tw-mb-2 opacity-54 tw-break-all"},[_vm._v(" "+_vm._s(_vm.resource.name || _vm.resource.data)+" ")]),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center opacity-54"},[_c('div',{staticClass:"tw-inline-flex tw-items-center"},[(!_vm.isLinkResourceRef)?_c('button',{staticClass:"tw-p-1 opacity-54",on:{"click":function($event){$event.preventDefault();return _vm.downloadResource(_vm.resource.data, _vm.resource.name)}}},[_c('svg',{staticClass:"tw-w-4 tw-h-4 transform-scale-0",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z\n                M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0\n                c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6\n                C62.8,33.4,62.8,31.7,61.6,30.5z"}})])]):_vm._e(),(!_vm.isLinkResourceRef)?_c('router-link',{staticClass:"tw-ml-2",attrs:{"tag":"a","to":{
              name: 'ResourceView',
              query: {
                doc: `${_vm.apiUrl}/${_vm.resource.data}`,
                title: _vm.resource.name,
              },
            },"title":"view/read"}},[_c('svg',{staticClass:"tw-w-6 tw-h-6",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","id":"Layer_1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2\n                c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6\n                c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1\n                c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1\n                S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2\n                C42.1,39.4,39.3,42.2,35.9,42.2z"}})])]):_vm._e()],1),(_vm.isLinkResourceRef)?_c('div'):_vm._e(),(_vm.isLinkResourceRef)?_c('p',{staticClass:"opacity-31 tw-uppercase tw-text-xs leading-14"},[_vm._v(" LINK ")]):_c('p',{staticClass:"opacity-31 tw-uppercase tw-text-xs leading-14"},[_vm._v(" "+_vm._s(_vm.resourceType(_vm.resource.file_type))+" ")])]),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('span',{class:[
          'opacity-54 tw-mx-2 tw-my-1 tw-text-xs leading-14 tw-font-light tw-block tw-flex-grow',
          { 'tw-text-right' : _vm.textIsSelf },
          { 'tw-text-left' : !_vm.textIsSelf },
        ]},[_vm._v(_vm._s(_vm._f("moment")(_vm._f("toJsDate")(_vm.message.tym,true),'ddd Do hh:mm A')))]),(_vm.textIsSelf)?_c('span',{class:[
        'tw-fill-current tw-my-1 tw-flex-shrink-0',
        { 'tw-text-green-400': _vm.read},
        { 'tw-text-gray-500': !_vm.read},
        ]},[_c('svg',{staticClass:"tw-h-3 tw-w-3 transform-scale-0",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('g',[_c('path',{attrs:{"d":"M34.6,58.7c-0.8,0-1.6-0.4-2.2-1l-16.7-18c-1.2-1.3-1.1-3.4,0-4.7c1.2-1.3,3.1-1.2,4.3,0l14.5,15.6l32.1-36.4\n              c1.1-1.3,3.1-1.4,4.3-0.2c1.2,1.2,1.3,3.3,0.2,4.7L36.9,57.7C36.3,58.3,35.5,58.7,34.6,58.7L34.6,58.7z"}}),_c('path',{attrs:{"d":"M35.2,40.9l19.1-21.7c1-1.1,1.2-2.9,0.4-4.2c-1.1-1.8-3.4-1.9-4.7-0.4L30.5,36.7c-1.1,1.2-1.1,3.1,0.2,4.3l0,0\n              C31.9,42.3,34,42.2,35.2,40.9z"}}),_c('path',{attrs:{"d":"M22.1,52.8L5.8,35.1c-1-1.1-2.4-1.4-3.8-1c-2.1,0.8-2.6,3.3-1.2,4.8L17.4,57c1.2,1.4,3.4,1.4,4.6,0l0,0\n              C23.2,55.9,23.2,54,22.1,52.8z"}})])])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }