import resolve from '../../../modules/api/resolve';
import { apiPost, generateFormData } from '../../../modules/apiHelper';

function getResourceDetails(resource) {
  return {
    isLinkResource: resource?.file_type === 'link',
    isGoalLinkResource: resource?.file_type === 'link' && !!resource?.goal?.id,
    isResource: resource?.file_type !== 'link',
    isGoalResource: resource?.file_type !== 'link' && !!resource?.goal?.id,
  };
}

export default {
  async shareResource(context, [userId, resource]) {
    const {
      isLinkResource, isGoalLinkResource, isResource, isGoalResource,
    } = getResourceDetails(resource);
    const senderId = context.rootState.user.id;

    const formData = generateFormData({
      senderid: senderId,
      userid: userId,
      resourceid: resource.id,
    });
    let endpoint;
    const action = 69;

    if (isGoalLinkResource) {
      endpoint = 'prog_reshare_goal_resource_link';
    } else if (isLinkResource) {
      endpoint = 'prog_reshare_resource_link';
    } else if (isGoalResource) {
      endpoint = 'prog_reshare_goal_resource';
    } else if (isResource) {
      endpoint = 'prog_reshare_resource';
    } else {
      console.warn('No resource details found');
      return false;
    }

    const response = await resolve(apiPost(endpoint, formData, action));

    return !!response;
  },
};
