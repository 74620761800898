<template>
  <div class="tw-relative">
    <div class="tw-mt-4 tw-mx-6 tw-mb-8 tw-text-center">
      <h4 class="font-title tw-text-xl leading-24 tw-mb-8 tw-tracking-widest">{{ task.task_name }}</h4>
      <p class="tw-text-base leading-19 tw-mb-2">
        {{ task.end_tym | toJsDate | moment('diff', task.start_tym) | duration('humanize') }}
      </p>
      <div class="tw-inline-flex tw-items-center tw-border tw-border-app-deep-blue tw-rounded-lg tw-py-2 tw-px-4">
        <div>
          <p class="tw-uppercase tw-text-xs tw-text-left- opacity-54 tw-font-bold">From</p>
          <p class="tw-text-base leading-19">{{ task.start_tym | toJsDate | moment('Do MMM YY') }}</p>
        </div>
        <span class="tw-mx-6 tw-fill-current tw-text-app-deep-blue" v-html="rightNav"></span>
        <div>
          <p class="tw-uppercase tw-text-xs tw-text-left- opacity-54 tw-font-bold">To</p>
          <p class="tw-text-base leading-19">{{ task.end_tym | toJsDate | moment('Do MMM YY') }}</p>
        </div>
      </div>
      <div class="tw-block tw-w-full">
        <p
          v-if="taskIsCompleted(task.status)"
          class="tw-mx-auto tw-inline-block tw-rounded-b-32 tw-bg-green-500 tw-px-6 tw-py-2 tw-text-xs tw-text-white tw-mb-6"
        >
          Completed
        </p>
      </div>
    </div>
    <div class="tw-mx-6">
      <h6 class="tw-text-xs">Task Description</h6>
      <div class="divider tw-border-t tw-border-black opacity-11 tw-my-3 tw-w-full"></div>
    </div>
    <p class="tw-mx-6 opacity-78 tw-text-gray-800 tw-mb-2 tw-break-all" v-html="htmlizeLinksInString(task.description)">
    </p>
    <p v-if="task?.goal?.id" class="tw-text-sm tw-mx-6 tw-mb-6 opacity-54">
      Goal Name:
      <span class="tw-text-app-primary-blue tw-font-bold">
        {{task?.goal?.goal}}
      </span>
    </p>
    <template v-if="showActionButtons">
      <BaseButton
        :to="{ name: (isGroupTask ? 'FullGroupTaskDetails' : 'FullTaskDetails'), params: { id: task.id } }"
        :task-status="task.status"
        text="Full details"
        class="tw-text-center tw-mx-auto tw-block tw-mb-4"
      />
      <BaseButton
        text="Comment"
        class="tw-text-center tw-text-black bg-black-11 tw-block box-border tw-mx-auto tw-mb-4"
        icon
        @click="commentTask(task)"
        v-if="isMyMentor(task.mentorid) && !isGroupTask"
      >
        <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M46.1,5.3C35,5.3,25.6,12.4,22,22.2h-1c-0.1,0-0.3,0-0.4,0C9.2,22.2,0,31.5,0,42.8c0,5.8,2.4,11.1,6.5,15V59
            c0,5.3,3.5,7.8,6.7,7.8c1.4,0,3.5-0.4,5.8-2.7l0.8-0.8c0.3,0,0.5,0,0.8,0c6.2,0,11.9-2.7,15.8-7.5l0.7-0.9c2.9,1.1,5.9,1.6,9.1,1.6
            c0.5,0,1.1,0,1.7-0.1l2.9,2.9c2.2,2.2,4.3,2.7,5.8,2.7c3.2,0,6.7-2.5,6.7-7.8v-4.1c5.4-4.8,8.7-11.8,8.7-19.2
            C71.8,16.8,60.3,5.3,46.1,5.3z M20.4,58.2c-0.9,0-1.8-0.1-2.7-0.2l-2.6,2.6c-2.1,2-3.6,1.3-3.6-1.5v-3.6C7.6,52.5,5,47.9,5,42.8
            c0-8.5,6.9-15.4,15.4-15.4c0.1,0,0.2,0,0.3,0c-0.2,1.1-0.2,2.4-0.2,3.6c0,9,4.7,17,11.9,21.7C29.5,56,25.2,58.2,20.4,58.2z
            M57.9,47.7v6.6c0,2.9-1.6,3.5-3.7,1.5l-4.6-4.6c-1.1,0.2-2.4,0.3-3.6,0.3c-11.3,0-20.5-9.2-20.5-20.5S34.7,10.4,46,10.4
            S66.6,19.7,66.6,31C66.7,37.8,63.3,44,57.9,47.7z"/>
        </svg>
      </BaseButton>
      <TaskButton
        :task-id="task.id"
        :task-status="task.status"
        :is-group-task="isGroupTask"
        class="tw-text-center tw-block tw-px-6 tw-py-3 tw-mb-6 tw-mx-auto tw-border-none transition focus:tw-shadow-app-lg focus:tw-bg-gray-900"
      />
    </template>
  </div>
</template>

<script>
import TaskButton from '@/components/TaskButton.vue';
import { htmlizeLinksInString } from '@/modules/dataHelper';

export default {
  name: 'TaskDetails',
  components: {
    TaskButton,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isGroupTask() {
      return !!Number(this.task?.groupid);
    },
  },
  methods: {
    commentTask(task) {
      this.refTaskInChat(this.isMentee ? task.mentorid : task.menteeid, task);
      this.$emit('close');
    },
    htmlizeLinksInString(str) {
      return htmlizeLinksInString(str);
    },
    isMyMentor(mentorId) {
      if (this.isMentee) {
        // eslint-disable-next-line eqeqeq
        return !!this.$store.state.user.mentee.mentors.find((mentor) => mentor?.user.id == mentorId);
      }

      return true;
    },
  },
};
</script>

<style>
</style>
