import actions from './actions';

export default {
  state: {
    // ! this is wrong but I see no other way
    kengenExperienceId: 21,
    technicalPillarId: 22,
    businessEnablersId: 23,
    minimumCompletedKengenExperienceGoals: 2,
  },
  actions,
};
