<template>
  <textarea
    :placeholder="placeholder"
    :rows="rows"
    v-model="text"
    ref="text-input-el"
    class="tw-p-1 tw-w-full tw-rounded-sm tw-block tw-bg-gray-200 tw-outline-none transition hover:tw-bg-gray-200 focus:tw-bg-white"
    :style="{ height: textareaScrollHeight, maxHeight: maxHeight }"
  >
  </textarea>
</template>

<script>
export default {
  name: 'BaseTextarea',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: [String, Number],
      default: 1,
    },
    value: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      text: this.value,
      textareaScrollHeight: 'auto',
    };
  },
  watch: {
    async text(val) {
      this.$emit('input', val);
      this.textareaScrollHeight = 'auto';

      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      const currentTextareaHeight = `${this.$refs['text-input-el']?.scrollHeight || 0 + 8}px`;
      this.textareaScrollHeight = currentTextareaHeight;
    },
    value(val) {
      this.text = val;
    },
  },
  mounted() {
    this.text = this.value;
    const currentTextareaHeight = `${this.$refs['text-input-el']?.scrollHeight || 0 + 8}px`;
    this.textareaScrollHeight = currentTextareaHeight;
  },
};
</script>

<style>

</style>
