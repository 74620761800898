<template>
  <div>
    <div class="tw-flex tw-flex-wrap tw-mt-10 tw-items-center tw-relative">
      <h6 class="tw-text-base tw-capitalize font-title tw-text-app-primary-orange">Resources shared to you specifically</h6>
      <div class="tw-ml-auto tw-flex tw-flex-nowrap tw-items-center">
      </div>
    </div>
    <div class="divider tw-border tw-w-full tw-mt-2 opacity-11"></div>
    <transition mode="out-in" name="fade" appear>
      <ContentLoadingTwoGrid v-if="loadingResources && isMobileView" style="margin-left: -36px; margin-right: -36px;"/>
      <ContentLoadingThreeGrid v-else-if="loadingResources" style="margin-left: -36px; margin-right: -36px;"/>

      <div v-else-if="errorResources">
        <p class="tw-text-red-400 tw-py-24" v-html="errorResources"></p>
      </div>

      <div v-else>
        <ResourcesList
          :resources="resourcesSharedToMeSpecifically"
          :link-resources="linkResourcesSharedToMeSpecifically"
          show-sender
        />
      </div>
    </transition>

    <div class="tw-flex tw-flex-wrap tw-mt-10 tw-items-center tw-relative">
      <h6 class="tw-text-base tw-capitalize font-title tw-text-app-primary-orange">Program Resources</h6>
      <div class="tw-ml-auto tw-flex tw-flex-nowrap tw-items-center">
      </div>
    </div>
    <div class="divider tw-border tw-w-full tw-mt-2 opacity-11"></div>
    <h6 class="tw-text-xs leading-14 opacity-54 tw-mt-2 tw-text-center">
      These are documents and media we feel might help you in achieving your goals.
    </h6>

    <transition mode="out-in" name="fade" appear>
      <ContentLoadingTwoGrid v-if="loadingResources && isMobileView" style="margin-left: -36px; margin-right: -36px;"/>
      <ContentLoadingThreeGrid v-else-if="loadingResources" style="margin-left: -36px; margin-right: -36px;"/>

      <div v-else-if="errorResources">
        <p class="tw-text-red-400 tw-py-24" v-html="errorResources"></p>
      </div>

      <div v-else>
        <ResourcesList :resources="resources" :link-resources="linkResources" />
      </div>
    </transition>
  </div>
</template>

<script>
import _groupBy from 'lodash/groupBy';
// import _orderBy from 'lodash/orderBy';

import ContentLoadingTwoGrid from '@/components/ContentLoadingTwoGrid.vue';
import ContentLoadingThreeGrid from '@/components/ContentLoadingThreeGrid.vue';
import ResourcesList from '@/views/_partials/ResourcesList.vue';

import { apiUrl } from '@/modules/apiHelper';

export default {
  name: 'SharedResources',
  components: {
    ContentLoadingTwoGrid,
    ContentLoadingThreeGrid,
    ResourcesList,
  },
  data() {
    return {
      selectedCategory: null,
      selectedLinkCategory: null,
      resources: {},
      linkResources: {},
      resourcesSharedToMeSpecifically: {},
      linkResourcesSharedToMeSpecifically: {},
      modules: [],
      pillars: [],
      loadingResources: false,
      errorResources: null,
      searchLinkTextQuery: '',
      searchTextQuery: '',
      apiUrl,
    };
  },
  methods: {
    async getAdminProgramResources() {
      this.loadingResources = true;

      // get admin program resources
      const [
        adminProgramResources,
        adminProgramLinkResources,
      ] = await Promise.all([
        this.$store.dispatch('getAdminProgramResources'),
        this.$store.dispatch('getAdminProgramLinkResources'),
      ]);

      // load modules to goal resources
      adminProgramResources.goalResources.forEach((resource) => {
        // eslint-disable-next-line eqeqeq
        const module = this.modules.find((m) => m.id == resource.goalid);
        if (module) {
          resource.goal = module;
        }
      });

      adminProgramLinkResources.goalLinkResources.forEach((resource) => {
        // eslint-disable-next-line eqeqeq
        const module = this.modules.find((m) => m.id == resource.goalid);
        if (module) {
          resource.goal = module;
        }
      });

      const groupKey = this.isSequentialProgram ? 'goalid' : 'pillarid';

      const groupedGoalResources = _groupBy(adminProgramResources.goalResources.filter((resource) => resource.compStat !== 1), groupKey);
      const groupedGoalLinkResources = _groupBy(adminProgramLinkResources.goalLinkResources.filter((resource) => resource.compStat !== 1), groupKey);

      this.resources = { ...groupedGoalResources, other: adminProgramResources.resources.filter((resource) => resource.compStat !== 1) };
      this.linkResources = { ...groupedGoalLinkResources, other: adminProgramLinkResources.linkResources.filter((resource) => resource.compStat !== 1) };

      const goalResourcesSharedToMeSpecifically = _groupBy(adminProgramResources.goalResources.filter((resource) => resource.compStat === 1), groupKey);
      const goalLinkResourcesSharedToMeSpecifically = _groupBy(adminProgramLinkResources.goalLinkResources.filter((resource) => resource.compStat === 1), groupKey);
      const resourcesSharedToMeSpecifically = adminProgramResources.resources.filter((resource) => resource.compStat === 1);
      const linkResourcesSharedToMeSpecifically = adminProgramLinkResources.linkResources.filter((resource) => resource.compStat === 1);

      this.resourcesSharedToMeSpecifically = { ...goalResourcesSharedToMeSpecifically, other: resourcesSharedToMeSpecifically };
      this.linkResourcesSharedToMeSpecifically = { ...goalLinkResourcesSharedToMeSpecifically, other: linkResourcesSharedToMeSpecifically };

      // loop through resources and get the userID that shared the resource
      const users = [];
      const sharedResources = [...Object.values(this.resourcesSharedToMeSpecifically), ...Object.values(this.linkResourcesSharedToMeSpecifically)].flat();
      sharedResources.forEach((resource) => {
        if (users.includes(resource.sharedBy)) return;
        users.push(resource.sharedBy);
      });

      const foundUsers = await Promise.all(users.map((user) => this.$store.dispatch('getUserData', user)));

      // somehow this updates the resourcesSharedToMeSpecifically and linkResourcesSharedToMeSpecifically! Yay!
      sharedResources.forEach((resource) => {
        // eslint-disable-next-line eqeqeq
        resource.sender = foundUsers.find((user) => user.id == resource.sharedBy);
      });

      await this.$nextTick();

      this.loadingResources = false;
    },
    async getModules() {
      const modules = ((await this.$store.dispatch('getProgramModules', [])) || []).flat();

      if (Array.isArray(modules)) {
        this.modules = [...modules];
      }
    },
    async getPillars() {
      const pillars = await this.$store.dispatch('getPillarGroups', []);

      if (Array.isArray(pillars)) {
        this.pillars = [...pillars];
      }
    },
  },
  async mounted() {
    await this.$nextTick();

    if (this.isSequentialProgram) {
      await this.getModules();
    } else {
      await this.getPillars();
    }

    this.getAdminProgramResources();
  },
};
</script>

<style>

</style>
