<template>
  <div ref="video-sdk-container" id="video-sdk-container" class="tw-min-h-[75vh]"></div>
</template>

<script>
// import { VideoSDK } from "@videosdk.live/js-sdk";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";

export default {
  name: 'VideoSdk',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      videoSDKMeeting: undefined,
    };
  },
  computed: {
    userIsAdmin() {
      return Number(this.meeting.admin) === Number(this.$store.state.user.id);
    }
  },
  async mounted() {
    this.videoSDKMeeting = new VideoSDKMeeting();

    this.videoSDKMeeting.init({
      apiKey: process.env.VUE_APP_VIDEO_SDK_API_KEY,
      meetingId: this.meeting.linkid,
      name: this.user.name || this.$store.state.user.name,
      containerId: 'video-sdk-container',
      redirectOnLeave: `${window.location.origin}/app`,
      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true,
      chatEnabled: true,
      screenShareEnabled: true,
      joinScreen: {
        visible: false,
        title: this.meeting.title,
        meetingUrl: window.location.href,
      },
      permissions: {
        endMeeting: this.userIsAdmin, // meeting owner only feature
        toggleRecording: this.userIsAdmin,
        canCreatePoll: this.userIsAdmin,
      },
      recording: {
        enabled: true,
        autoStart: false,
      },
    });

    await this.$nextTick();

    if (this.userIsAdmin) {
      // * fire start meeting here?
    }
  },
  beforeDestroy() {
    this.videoSDKMeeting = undefined; // ? cleanup?
  }
};
</script>

<style>

#video-sdk-container iframe {
  height: 75vh;
}

</style>
