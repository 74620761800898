import resolve from '../../modules/api/resolve';
import {
  apiGet, apiPost, apiStore, generateFormData,
} from '../../modules/apiHelper';
import { isStaleData, now, toBoolean } from '../../modules/dataHelper';
import mentorshipGroupChats from './chats';
import mentorshipGroupTasks from './tasks';

export default {
  namespaced: false,
  actions: {
    async getMentorshipGroup(context, [groupId, refresh = false]) {
      const groups = await apiStore.getItem(`mentorshipGroup/${groupId}`) || [0, []];

      if (!refresh && !isStaleData(groups[0]) && groups[1].length) return groups[1];

      const result = await resolve(apiPost('groups_links', generateFormData({ groupid: groupId }), 52))
        .catch((err) => {
          console.warn('getMentorshipGroup::group_links err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.error)) return false;

          const mentorshipGroup = (res.group_links || [])[0] || {};

          if (mentorshipGroup.goalid) {
            const goals = await context.dispatch('getGoals', [mentorshipGroup.companyid]);
            mentorshipGroup.goal = goals.find((goal) => Number(goal.id) === Number(mentorshipGroup.goalid));
          }

          if (mentorshipGroup?.id) {
            apiStore.setItem(`mentorshipGroup/${groupId}`, [now(), mentorshipGroup]);
          }

          return mentorshipGroup;
        });
      //

      return result;
    },
    async getMyMentorshipGroups(context, userId) {
      const userid = userId || context.rootState.user.id;

      const results = await apiPost('prt_groups', generateFormData({ userid }), 51)
        .catch((err) => {
          console.warn('getMyMentorshipGroups::prt_groups err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          const mentorshipGroups = res.data.prt_groups;
          const requests = [];

          mentorshipGroups.forEach((group) => {
            const req = context.dispatch('getMentorshipGroup', [group.groupid]);
            requests.push(req);
          });

          const groups = await Promise.all(requests);
          // return active mentorship groups only
          return groups.filter((group) => Number(group.status) === 0);
        });
      //

      return results;
    },
    async getMentorshipGroupParticipants(context, groupId) {
      const { companyid } = context.rootState.user;
      const formData = generateFormData({
        companyid,
        groupid: groupId,
      });

      const request = await apiPost('company_participants', formData, 53)
        .catch((err) => {
          console.warn('getMentorshipGroupParticipants::company_participants err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          /** @type {Array} */
          const participants = res.data.company_participants || [];

          // get users' data
          const promises = [];

          // cache data first
          await Promise.all([
            apiGet('gender'), // 1
            context.dispatch('companyProfessions', companyid), // 4
            apiGet('experience'), // 5
            apiGet('availability'), // 6
            context.dispatch('companyRegions', companyid), // 8
            context.dispatch('companyDivisions', companyid), // 9
            context.dispatch('companyDepartments', companyid), // 10
            context.dispatch('companySubAreas', companyid), // 11
            context.dispatch('companyDeptLevels', companyid), // 12
          ]);

          participants.forEach((participant) => {
            const prom = context.dispatch('getUserData', participant.userid);

            promises.push(prom);
          });

          const users = await Promise.all(promises);
          // console.log('participants::', users);

          return users;
        });
      //

      return request;
    },
    async assertParticipant(context, groupId) {
      const { companyid } = context.rootState.user;
      const formData = generateFormData({
        companyid,
        groupid: groupId,
      });

      const request = await apiPost('company_participants', formData, 53)
        .catch((err) => {
          console.warn('assertParticipant::company_participants err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          /** @type {Array} */
          const participants = res.data.company_participants || [];

          // eslint-disable-next-line eqeqeq
          return !!participants.find((p) => p.userid == context.rootState.user.id);
        });
      //

      return request;
    },
  },
  modules: {
    mentorshipGroupChats,
    mentorshipGroupTasks,
  },
};
