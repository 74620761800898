<template>
  <div class="tw-h-full tw-w-full tw-flex tw-flex-col tw-flex-grow lg-grid">
    <div class="lg-grid__col-2/3"></div>
    <div
      :class="[
        'tw-flex-grow tw-overflow-auto tw-w-full tw-h-full tw-pt-8 tw-flex tw-justify-end tw-flex-col transition-fast',
        { 'lg-grid__col-2/3': useLgGridOnThread }
      ]"
      ref="chat-body"
      v-observe-scrollheight="scrollDown"
    >
      <div v-if="loading">
        <p class="tw-text-xs tw-text-center tw-py-10 opacity-54">Getting your messages. Please wait..</p>
      </div>
      <transition
        name="new"
        mode="out-in"
        tag="span"
        v-else-if="!hasMessages"
      >
        <div class="transition-slow">
          <!-- GOODIES :: new chat! [Say hi!] -->
          <h4 class="tw-text-right px-body opacity-31 tw-text-base tw-font-bold font-title tw-italic tw-tracking-wide">Say hi!</h4>
        </div>
      </transition>
      <template
        v-for="(message, index) in messages"
      >
        <div
          :key="`spacer-${index}`"
          v-if="(messages[index - 1] || {}).senderid !== messages[index].senderid"
          class="tw-h-4"
        >
        </div>
        <ChatBubbleText
          :key="message.id"
          :message="message"
          :senderId="message.senderid"
          :status="message.status"
          :attachment="message.taskid || message.resourceid"
          @text-read="textRead()"
          @update="scrollDown()"
        />
      </template>
    </div>

    <portal to="bottom-app-view" slim>
      <div class="lg-grid">
        <div
          :class="[
            'tw-flex-grow-0 tw-flex-shrink-0 tw-w-full',
            { 'lg-grid__col-2/3': useLgGridOnInput }
          ]"
          ref="chat-bottom-bar"
        >
          <!-- // * chat action bar -->
          <transition name="new" mode="out-in" appear>
            <span class="tw-block tw-w-full tw-py-1 tw-text-xs tw-text-center tw-text-red-500" v-if="fetchingError">Error in fetching messages. Please reload page</span>
          </transition>

          <div
            :class="[
              'tw-mb-6 tw-flex tw-flex-nowrap tw-items-end transition-fast',
              { 'mx-body lg:tw-mr-0': !focusMode },
              { 'tw-mx-2 lg:tw-ml-8 lg:tw-mr-0': focusMode },
            ]"
          >
            <!-- // * menu -->
            <transition mode="out-in" name="fade">
              <div
                v-show="openAttachmentMenu"
                class="tw-absolute tw-pb-4 tw-z-40 tw-bg-white tw-bg-opacity-70 tw-rounded-t-10"
                style="bottom: 100%;"
              >
                <!-- // * menu items -->
                <div class="tw-mb-2">
                  <div
                    class="tw-flex tw-items-center tw-cursor-pointer"
                    @click="goToCreateMeeting()"
                  >
                    <span class="tw-p-3 tw-bg-white tw-shadow-app tw-rounded-full">
                      <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M58.8,8.4C27.3-17-14.9,19.9,6,54.6L3.8,68c-0.4,2,1.4,4.1,3.4,4c0,0.4,13-4.3,13.4-4.4C58.6,84.9,90.2,35.9,58.8,8.4z
                          M42.8,66.7c-7.3,1.6-14.6,0.6-21.2-2.8c-0.5-0.2-1-0.3-1.5-0.1L7.6,68.1l2.2-13.6c0.1-0.5,0-0.9-0.3-1.3C6,47.9,4.2,41.7,4.2,35.3
                          C4.3,8.7,36.1-6,56.4,11.3C76.6,27.9,68.4,61.2,42.8,66.7L42.8,66.7z M41.9,30.2c0,1-0.8,1.9-1.9,1.9h-2.1c-1,0-1.9-0.8-1.9-1.9
                          c0-1,0.8-1.9,1.9-1.9h2.1C41.1,28.3,41.9,29.2,41.9,30.2z M57.5,28.1c-1-0.5-2.2-0.5-3.2,0.2l-4.5,3.1v-3.8c0-3.5-2.8-6.3-6.3-6.3
                          H22.1c-3.5,0-6.3,2.8-6.3,6.3v15.5c0,3.5,2.8,6.3,6.3,6.3h21.4c3.5,0,6.3-2.8,6.3-6.3v-2.8l4.3,3.5c2.1,1.7,5.2-0.1,5.1-2.7l0-10
                          C59.3,29.8,58.6,28.7,57.5,28.1L57.5,28.1z M46.1,43.1c0,1.4-1.2,2.6-2.6,2.6H22.1c-1.4,0-2.6-1.2-2.6-2.6V27.6
                          c0-1.4,1.2-2.6,2.6-2.6h21.4c1.4,0,2.6,1.2,2.6,2.6V43.1z M55.5,40.2l-5.5-4.4l5.5-3.8C55.5,32,55.5,40.2,55.5,40.2z"/>
                      </svg>
                    </span>
                    <span class="tw-uppercase tw-mx-2">Video Meeting</span>
                  </div>
                </div>
                <div class="tw-mb-2">
                  <div
                    class="tw-flex tw-items-center tw-cursor-pointer"
                    @click="attachResource()"
                  >
                    <span class="tw-p-3 tw-bg-white tw-shadow-app tw-rounded-full">
                      <svg  class="tw-w-6 tw-h-6"
                        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                        y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M62.1,14h-8.8c-0.8,0-1.6-0.6-1.6-1.6V2.6C52,1.2,50.8,0,49.4,0H12.1c-2.8,0-5,2.2-5,5v62c0,2.8,2.2,5,5,5h47.9
                          c2.8,0,5-2.2,5-5V16.6C64.9,15.2,63.7,14,62.1,14z M60.9,66.8c0,0.6-0.4,1-1,1H12.1c-0.6,0-1-0.4-1-1V5c0-0.6,0.6-1,1-1H48v8.4
                          c0,3,2.4,5.6,5.6,5.6h7.4V66.8z M51,29.9c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2s0.8-2,2-2H49C50.2,27.9,51,28.7,51,29.9z M51,41.9
                          c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2c0-1.2,0.8-2,2-2H49C50.2,39.9,51,40.7,51,41.9z M51,53.9c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2
                          s0.8-2,2-2H49C50.2,51.9,51,52.7,51,53.9z"/>
                      </svg>
                    </span>
                    <span class="tw-uppercase tw-mx-2">RESOURCES</span>
                  </div>
                </div>
              </div>
            </transition>
            <div
              class="tw-flex tw-flex-wrap tw-shadow-app tw-p-1 tw-bg-white tw-flex-1 tw-items-end tw-mr-4 tw-relative tw-rounded-[24px]"
            >
              <!-- // * attach view! -->
              <transition name="fade" mode="out-in">
                <div
                  class="tw-w-full tw-m-1 tw-py-4 tw-px-6 tw-bg-gray-300"
                  style="border-radius: 24px;"
                  v-if="hasAttached && form.attachment"
                >
                  <div class="tw-flex tw-items-center tw-justify-between" v-if="isTaskRef">
                    <div>
                      <p class="tw-text-xs opacity-31 tw-uppercase">Task</p>
                      <h4 class="tw-font-bold tw-text-sm leading-17 opacity-54">
                        {{ form.attachment.task_name }}
                      </h4>
                    </div>
                    <button
                      class="tw-flex-shrink-0 tw-p-1 opacity-54"
                      @click="clearAttachment()"
                    >
                      <svg version="1.1" class="tw-w-5 tw-h-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
                          L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
                          s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
                      </svg>
                    </button>
                  </div>
                  <!-- <p class="tw-text-xs opacity-31 tw-uppercase" v-else-if="isResourceRef">Resource</p>
                  <h4 class="tw-font-bold tw-text-sm leading-17 opacity-54" v-else-if="isResourceRef">
                    {{ form.attachment.name || form.attachment.data }}
                  </h4> -->
                </div>
              </transition>

              <button
                ref="attachment-menu-button"
                :class="[
                  'tw-inline-block tw-relative tw-flex-shrink-0 tw-flex-grow-0 tw-p-2 tw-rounded-full tw-outline-none focus:tw-bg-gray-300 active:tw-bg-gray-300 transition-fast',
                  { 'tw-bg-gray-300': openAttachmentMenu }
                ]"
                @click.prevent.stop="toggleAttachmentMenu()"
                @blur.prevent.stop="openAttachmentMenu = false"
              >
                <svg version="1.1" class="tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
                    c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
                </svg>
              </button>
              <!-- // * textarea -->
              <textarea
                ref="text-input-el"
                rows="1"
                class="leading-24 tw-p-2 tw-text-base tw-flex-1 tw-bg-transparent tw-w-full tw-resize-none transition-fast"
                :style="'height: ' + textareaScrollHeight + '; max-height: 240px;'"
                placeholder="Type a message"
                v-model="form.text"
                @focus.prevent.stop="focusMode = true"
                @blur.prevent.stop="focusMode = false"
              ></textarea>
            </div>
            <!-- // * send button -->
            <template>
              <BaseButton
                text=""
                icon
                style="line-height: 0; font-size: 0;"
                class="tw-shadow-app tw-bg-app-primary-orange tw-rounded-full tw-text-app-primary-orange-contrast tw-py-3 tw-border-0 focus:tw-opacity-80 transition-fast"
                :disabled="disabled || sending"
                @click="sendMessage()"
              >
                <svg slot="icon" class="tw-w-6 tw-h-6 tw-mx-3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M70.9,0.9c-0.8-0.8-2.2-1-3.3-0.6l-65.8,25c-1.2,0.6-2,1.8-1.8,3c0,1.2,0.8,2.2,2,2.6l28.8,10l10.4,29.1
                    c0.4,1.2,1.6,2,2.8,2l0,0c1.4,0,2.6-1,3-2.2L71.9,4.1C72.2,2.9,71.7,1.7,70.9,0.9z M44.1,66.1l-9.8-27c-0.4-0.8-1-1.6-1.8-1.8
                    L5.7,28.1L67.1,4.9L44.1,66.1z M39.6,32.3c-0.8-0.8-0.8-2,0-2.8l10-10c0.8-0.8,2-0.8,2.8,0s0.8,2,0,2.8l-10,10
                    c-0.4,0.4-1,0.6-1.4,0.6C40.7,33,40.1,32.5,39.6,32.3z"/>
                </svg>
              </BaseButton>
            </template>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _debounce from 'lodash/debounce';
import _uniqBy from 'lodash/uniqBy';
import chatMixin from '@/mixins/chat';
import { customObservable } from '@/modules/misc';
import ChatBubbleText from '@/components/ChatBubbleText/ChatBubbleText.vue';

let observer;

export default {
  name: 'ChatPartial',
  components: {
    ChatBubbleText,
  },
  mixins: [chatMixin],
  props: ['id', 'lgGridOnInput', 'lgGridOnThread'],
  directives: {
    observeScrollheight: {
      inserted(el, binding) {
        const config = {
          childList: true,
        };
        observer = customObservable(
          el,
          config,
          _debounce((mutationsList) => {
            // make uniq mutation.type
            const mutations = _uniqBy(Object.entries(mutationsList), ['type']);
            mutations.forEach(() => {
              if (typeof binding.value === 'function') binding.value();
            });
          }, 180),
        );
      },
    },
  },
  watch: {
    userViewsData(newVal) {
      if (newVal[`refTask/${this.userId}`]) {
        this.attachTask(newVal[`refTask/${this.userId}`]);
      }
    },
  },
  computed: {
    ...mapGetters([
      'userMessages',
      'fetchingError',
    ]),
    ...mapState({
      userViewsData: (state) => state.ui.userViewsData,
    }),
    messages() {
      return this.userMessages(this.userId);
    },
    hasMessages() {
      return this.messages.length > 0;
    },
    useLgGridOnInput() {
      return !!this.lgGridOnInput;
    },
    useLgGridOnThread() {
      return !!this.lgGridOnThread;
    },
    hasAttached() {
      return !!this.form.attachmentId;
    },
  },
  methods: {
    isResourceRef() {
      return !!this.form.attachment.data;
    },
    isTaskRef() {
      return !!this.form.attachment.task_name;
    },
    async sendMessage() {
      // disable the textarea
      this.sending = true;

      // send message
      const response = await this.$store.dispatch('sendMessage', { userId: this.userId, ...this.form });

      await this.$nextTick();
      this.sending = false;
      this.$refs['text-input-el'].focus();

      // validate response
      if (!response) {
        this.$toasted.global.appError({
          errorMessage: 'Could not send message. Try again later',
        });
      } else {
        this.form.text = ' ';
        this.form.attachmentId = undefined;
        this.form.attachmentType = undefined;
        this.form.attachment = undefined;
        this.$store.dispatch('clearReferencedItem', `refTask/${this.userId}`);
        this.$store.dispatch('clearReferencedItem', `refResource/chat/${this.userId}`);

        // get prompt for user
        this.$store.dispatch('getCurrentUserJourneyAction');
      }
    },
    toggleAttachmentMenu() {
      this.openAttachmentMenu = !this.openAttachmentMenu;

      if (!this.openAttachmentMenu && this.$refs['attachment-menu-button']) {
        this.$refs['attachment-menu-button'].blur();
      }
    },
    async clearAttachment() {
      await this.$store.dispatch('clearReferencedItem', `refTask/${this.userId}`);
      await this.$store.dispatch('clearReferencedItem', `refResource/${this.userId}`);

      this.form.attachment = { };
      this.form.attachmentId = undefined;
      this.form.attachmentType = undefined;
      setTimeout(() => {
        this.$emit('view-mounted');
      }, 1000);
    },
    async scrollDown() {
      if (this.$refs['chat-body']) {
        await this.$nextTick();
        window.scrollTo(0, this.$refs['chat-body'].scrollHeight);
      }
    },
    // eslint-disable-next-line func-names
    textRead: _debounce(function () {
      this.$store.dispatch('markMessagesAsRead', this.messages[0].linkid);
    }, 1000, { leading: true }),
    async attachTask(task) {
      this.form.attachment = {
        id: task.id,
        userid: task.userid,
        description: task.description,
        task_name: task.task_name,
        start_tym: task.start_tym,
        end_tym: task.end_tym,
        subgoalid: task.subgoalid,
      };
      this.form.attachmentId = this.form.attachment.id;
      this.form.attachmentType = 'taskid';
      await this.$nextTick();
      setTimeout(() => {
        this.$emit('view-mounted');
        this.scrollDown();
      }, 1000);
    },
    async attachResource() {
      await this.clearAttachment();
      await this.$nextTick();
      this.$router.push({ name: 'resources', query: { to: this.userId } });
    },
    goToCreateMeeting() {
      this.$router.push({
        name: 'CreateMeeting',
        query: {
          'add-users': this.id,
        },
      });
    },
  },
  async mounted() {
    await this.$nextTick();
    this.$store.commit('isOnChatWith', this.userId);
    await this.$nextTick();
    this.$emit('view-mounted');
    const $this = this;

    setTimeout(async function hasMessages() {
      if (typeof $this.messages !== 'object') {
        setTimeout(hasMessages, 1000);
      } else {
        $this.loading = false;
        await $this.$nextTick();
        $this.scrollDown();
        $this.$emit('view-mounted');
      }
    }, 1000);

    const referencedTask = await this.$store.dispatch('getChatReferencedItem', `refTask/${this.userId}`);

    if (referencedTask) {
      // update UI
      this.attachTask(referencedTask);
    } else {
      const referencedResource = await this.$store.dispatch('getChatReferencedItem', `refResource/chat/${this.userId}`);

      if (referencedResource) {
        this.form.attachment = referencedResource;
        this.form.attachmentId = this.form.attachment.id;
        this.form.attachmentType = 'resourceid';
        this.form.resourceType = this.form.attachment.file_type;
        await this.sendMessage();
      }
    }

    await this.$nextTick();
    await this.$nextTick();
    this.scrollDown();
  },
  beforeDestroy() {
    this.$store.commit('resetFetchSpeeds');
    if (typeof observer === 'object') {
      if (Object.prototype.hasOwnProperty.call(observer, 'disconnect')) observer.disconnect();
    }
  },
};
</script>

<style>

</style>
