import resolve from '../../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { toBoolean, urlFriendly } from '../../../modules/dataHelper';

export default {
  async getMySequentialTasks(context, strict = false) {
    const menteeStoreData = context.state.tasks;
    // console.log(menteeStoreData);
    if (menteeStoreData.length > 0 && !strict) {
      return menteeStoreData;
    }

    const goals = await context.dispatch('getProgramModules', []);
    const allGoals = goals.flat();

    const data = new FormData();
    data.set('menteeid', context.rootState.user.id);
    data.set('progid', context.rootState.user.programid);

    return apiPost('prog_task_list_mentee', data, 70)
      .catch((err) => {
        console.warn('Cannot get your tasks \n', err);
      })
      .then((res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) {
          console.warn('Error in getting your tasks \n', res.data.message);
          return false;
        }

        // remove group task
        const tasks = (res.data.prog_task_list_mentee || []).filter((task) => (!!Number(task.groupid)) === false);

        const hasGroupTasks = (res.data.prog_task_list_mentee || []).find((task) => !!Number(task.groupid));
        context.commit('updateHasGroupTasks', hasGroupTasks);

        const mappedTasks = tasks.map((task) => {
          // eslint-disable-next-line eqeqeq
          const taskGoal = allGoals.find((goal) => goal.id == task.goalid);

          return {
            ...task,
            goal: taskGoal || {},
          };
        });

        mappedTasks.forEach((task) => {
          context.commit('addTask', task);
        });

        return true;
      });
  },
  async markSequentialTaskComplete(context, [taskId, reportText = undefined, reportResources]) {
    if (!taskId) return false;
    const task = await context.dispatch('getSequentialTask', [taskId]);
    const { userId } = context.getters;

    // eslint-disable-next-line eqeqeq
    if (userId != task.mentorid && userId != task.menteeid) return false;

    const formData = new FormData();
    formData.set('taskid', taskId);
    let endpoint = 'prog_submit_task_report';

    if (reportText) {
      formData.set('review', reportText);

      if (reportResources.length) {
        formData.set('text', 2);
        formData.set('name', reportResources[0].name);
        formData.append('file1', reportResources[0], urlFriendly(reportResources[0].name));
        endpoint = 'prog_submit_task_report';
      } else {
        formData.set('text', 1);
        endpoint = 'prog_submit_task_report_text';
      }
    } else if (reportResources.length) {
      formData.set('text', 3);
      formData.set('name', reportResources[0].name);
      formData.append('file1', reportResources[0], urlFriendly(reportResources[0].name));
      endpoint = 'prog_submit_task_report';
    } else {
      console.warn('No report text or resources provided');
      return false;
    }

    const response = await apiPost(endpoint, formData, 70)
      .catch((err) => {
        console.warn('markSequentialTaskComplete ERROR', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.error)) return false;

        return true;
      });
    //

    // upload more resources
    const requests = [];
    if (reportResources.length > 1) {
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index < reportResources.length; index++) {
        const resource = reportResources[index];

        const data = generateFormData({
          taskid: taskId,
          name: resource.name,
          text: 3,
        });

        data.append('file1', resource, urlFriendly(resource.name));

        requests.push(resolve(apiPost(endpoint, data, 70)));
      }
    }

    const responses = await Promise.all(requests);

    if (response === false || responses.some((res) => res === false)) {
      console.warn('Error in uploading resources');
      return false;
    }

    // get the updated task Details
    await apiStore.removeItem(`task/${taskId}`);
    context.dispatch('getSequentialTask', [taskId, true])
      .then((newTask) => {
        if (newTask.id) {
          context.commit('updateTask', {
            id: newTask.id,
            prop: 'status',
            value: newTask.status,
          });
        }
      });

    return response;
  },
};
