<template>
  <div class="tw-relative">
    <transition name="fade" mode="out-in">
      <template v-if="loading">
        <div class="tw-flex tw-h-screen tw-w-full tw-justify-center tw-items-center tw-bg-white">
          <p class="opacity-54 tw-italic">
            Please wait...
          </p>
        </div>
      </template>

      <template v-else>
        <div class="tw-h-screen tw-w-full tw-flex tw-flex-col tw-relative">
          <div class="tw-flex tw-flex-wrap tw-full tw-py-3 tw-items-center mx-body sm:tw-border-none tw-border-b tw-border-gray-800 tw-relative tw-z-20">
            <div class="tw-flex-shrink-0 tw-flex tw-items-center tw-mb-4 tw-p-3 tw-rounded-32">
              <img
                src="/img/ema-logo.png"
                key="ema-logo"
                alt="eMentoring Africa"
                width="auto"
                class="sm:tw-h-[80px] tw-h-10 tw-w-auto tw-max-w-3xl tw-object-contain tw-block tw-flex-shrink-0 transform-scale-0 tw-mr-5"
              >
              <div>
                <img
                  :src="appLogoUrl"
                  :key="appLogoUrl"
                  alt="eMentored"
                  width="auto"
                  class="sm:tw-h-[120px] tw-h-12 tw-w-auto tw-max-w-3xl tw-object-contain tw-block tw-flex-shrink-0 transform-scale-0"
                >
              </div>
            </div>

            <div class="tw-flex-grow"></div>

            <BaseButton text="Become a Mentor" to="/mentor-signup" class="tw-border-none tw-py-3 tw-px-5 sm:tw-mr-3 tw-mb-2 sm:tw-mb-0 tw-ml-auto sm:tw-ml-0 tw-bg-app-primary-blue tw-text-white" />
            <BaseButton text="Find a Mentor" to="/mentee-signup" class="tw-border-none tw-py-3 tw-px-5 tw-bg-app-primary-blue tw-text-white tw-ml-auto sm:tw-ml-0" />
          </div>
          <div class="tw-flex tw-w-full tw-h-full tw-relative tw-z-20">
            <!-- <div class="tw-hidden md:tw-block md:tw-w-1/6 tw-mt-3 tw-bg-black my-bg-pattern tw-rounded-tr-55 tw-overflow-hidden">
              <img src="/img/KenGen--cropped.jpg" class="tw-w-full tw-h-full tw-object-cover" alt="KenGen Plant" />
            </div> -->
            <!-- <img src="/img/Logo Log-In Page.jpeg" class="tw-w-full tw-h-full tw-object-contain sm:tw-top-0 tw-top-10 tw-absolute tw-left-0 tw-z-0" alt="KenGen Plant" /> -->

            <div class="tw-w-full px-body md:tw-px-0 tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-z-10">
              <BaseCard class="tw-p-4 md:tw-px-8 md:tw-py-16 tw-shadow-none md:tw-shadow-app tw-bg-white tw-w-full tw-max-w-md tw-flex-shrink-0 tw-mb-20 auth-card">
                <div>
                  <h1 class="tw-text-4xl tw-text-center">Login</h1>
                  <div class="tw-h-4 tw-block"></div>
                  <form
                    ref="login-form"
                    id="login-form"
                    method="post"
                    action="/login"
                    @submit.prevent="onSubmit"
                  >
                    <div class="tw-block tw-my-4 tw-shadow-md tw-bg-red-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-sm" v-if="errors.auth">
                      {{ errors.auth }}
                    </div>
                    <div class="">
                      <InputGroup
                        label="email address"
                        name="email"
                        type="email"
                        placeholder="email"
                        v-model="email"
                        :error="errors.email"
                      />
                    </div>
                    <div class="tw-mb-8">
                      <InputGroup
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="password"
                        v-model="password"
                        :error="errors.password"
                      />
                    </div>
                    <div class="flex items-center justify-between">
                      <BaseButton
                        type="submit"
                        :text="btnText"
                        class="tw-py-3"
                        :disabled="!canSubmit || btnDisabled"
                      />
                      <router-link
                        class="tw-inline-block tw-ml-2 tw-py-2 tw-align-baseline tw-font-bold tw-text-sm tw-text-blue-500
                        my-btn hover:tw-text-blue-800"
                        to="forgot-password"
                      >Forgot Password?</router-link>
                    </div>
                  </form>
                </div>
              </BaseCard>
            </div>
          </div>

          <img src="/img/login KG COLOR@compressed@resized.png" class="tw-w-full tw-h-full tw-object-cover tw-absolute tw-left-0 tw-top-0 tw-z-0 tw-opacity-35" alt="" />
        </div>
      </template>
    </transition>

    <!-- <UserSupport /> -->
    <JivoChat :destroy="destroyJivo" />

    <BaseModal>
      <div slot="body">
        <UserProgramsLoginOptions :user-programs="userPrograms" @program-selected="userSelectProgram" />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import authMixin from '@/mixins/auth';
import loginMixin from '@/mixins/login';
import visitorsMixin from '@/mixins/visitors';
// import UserSupport from '@/components/UserSupport.vue';
import JivoChat from '@/components/JivoChat.vue';
import BaseModal from '@/components/BaseModal.vue';
import UserProgramsLoginOptions from '@/components/UserProgramsLoginOptions.vue';

export default {
  name: 'KengenLogin',
  components: {
    InputGroup,
    BaseModal,
    UserProgramsLoginOptions,
    // UserSupport,
    JivoChat,
  },

  mixins: [authMixin, loginMixin, visitorsMixin],
  async created() {
    await this.unregisterAuthStoreModule();
    // if user is logged in, redirect to app
    const to = sessionStorage.getItem('after-login-redirector') || '/app';
    if (this.$store.getters.isLoggedIn) {
      this.$router.push(to);
      sessionStorage.removeItem('after-login-redirector');
    } else {
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      this.loading = false;
    }
  },

};
</script>

<style>

</style>
