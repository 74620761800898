<template>
  <AppView padd-bottom>
    <TopNav
      slot="top-app-view"
      title="Survey"
      title-class="lg:ml-body lg-app:tw-font-bold"
      center-title
      type="menu"
      @input="openSideNav()"
      show-notification-icon
    />
    <div class="mx-body tw-mb-8 tw-h-full">
      <div v-if="error">
        <p class="tw-text-center tw-text-base opacity-78 tw-my-20" v-html="error"></p>
      </div>
      <div v-else-if="loading">
        <p class="tw-text-center tw-text-xs opacity-54 tw-my-20">Loading...</p>
      </div>
      <div v-else>
        <div class="tw-max-w-3xl tw-mx-auto tw-mt-10">
          <div>
            <div class="tw-h-[1px] tw-bg-app-primary-orange tw-my-6 tw-w-1/3 tw-mx-auto"></div>

            <p class="tw-text-lg tw-text-center tw-my-2 tw-text-app-primary-orange font-title tw-mb-4">
              {{surveyQuestions[0]?.surveyType?.description}}
            </p>

            <div class="tw-h-[1px] tw-bg-app-primary-orange tw-my-6 tw-w-1/3 tw-mx-auto"></div>
          </div>

          <template v-for="(question, index) in surveyQuestions">
            <div class="tw-pb-4 tw-mb-4 tw-border-b" :key="index">
              <div class="tw-my-2">
                <p class="tw-text-lg">
                  {{question.question}}
                </p>
              </div>
              <div class="tw-p-2 tw-bg-gray-100 tw-rounded-10" v-if="question.ratings.length">
                <p v-if="question.type.q_type === 'Choices'" class="tw-text-sm tw-text-center tw-my-1 tw-italic opacity-78">
                  Please select all that apply
                </p>
                <div class="tw-flex sm:tw-flex-nowrap tw-flex-wrap tw-items-end tw-justify-center">
                  <template v-for="(rating, ratingindex) in question.ratings">
                    <div :key="`choice-${ratingindex}`" class="tw-p-5 tw-text-center" v-if="question.type.q_type === 'Choices'">
                      <p class="tw-text-center tw-text-sm" style="max-width: 150px">
                        {{rating.ratings}}
                      </p>
                      <input type="checkbox" :value="rating.id" v-model="form[question.id].rating[rating.id]" />
                    </div>
                    <div :key="`rating-${ratingindex}`" class="tw-p-5 tw-text-center" v-else>
                      <p class="tw-text-center tw-text-sm" style="max-width: 150px">
                        {{rating.ratings}}
                      </p>
                      <input type="radio" :value="rating.id" v-model="form[question.id].rating" />
                    </div>
                  </template>
                </div>
              </div>
              <div v-else>
                <!-- just a textarea -->
                <BaseTextarea
                  rows="4"
                  class="bg-black-11 placeholder:tw-text-gray-900"
                  v-model="form[question.id].rating"
                  placeholder="Type your answer here"
                  max-height="45vh"
                />
              </div>
            </div>
          </template>

          <BaseButton
            :text="btnText"
            class="tw-block tw-w-full tw-py-3"
            :disabled="!canSendSurvey || disableBtn"
            @click="sendSurveyResponses"
          />
        </div>
      </div>


    </div>
  </AppView>
</template>

<script>
export default {
  name: 'ProgramSurveys',
  data() {
    return {
      loading: true,
      error: undefined,
      surveyQuestions: [],
      form: {},
      btnText: 'Send',
      disableBtn: false,
      surveyResponse: {},
    };
  },
  computed: {
    canSendSurvey() {
      let canSend = true;
      Object.entries(this.form).forEach(([id, formItem]) => {
        const isChoices = formItem.questionType === 'Choices';

        if (isChoices && !Object.values(formItem.rating).some(Boolean)) {
          canSend = false;
        }

        if (!formItem.rating) {
          canSend = false;
        }
      });

      return canSend;
    },
  },
  methods: {
    async getSurveyQuestions() {
      const surveyQuestions = await this.$store.dispatch('getSurveyQuestions');

      if (!Array.isArray(surveyQuestions)) {
        this.error = 'Could not get the survey questions. Please try again later.';
        return false;
      }

      // console.log(surveyQuestions);

      // map to form
      surveyQuestions.forEach((question) => {
        const choicesSelection = {};

        if (question.type.q_type === 'Choices') {
          question.ratings.forEach((rating) => {
            choicesSelection[rating.id] = false;
          });
        }

        const questionForm = {
          questionId: question.id,
          rating: question.type.q_type === 'Choices' ? choicesSelection : undefined,
          questionType: question.type.q_type,
        };

        this.form = {
          ...this.form,
          [question.id]: questionForm,
        };
      });

      this.surveyQuestions = surveyQuestions;

      return true;
    },
    // async getSurveyUserResponse() {
    //   const response = await this.$store.dispatch('getMatchEndedSurveyUserResponse');

    //   if (!response) {
    //     this.error = 'Error while fetching your response';
    //     return false;
    //   }

    //   this.surveyResponse = response;

    //   return true;
    // },
    async sendSurveyResponses() {
      if (!this.canSendSurvey) return;

      this.disableBtn = true;
      this.btnText = 'sending...';
      this.$toasted.info('sending...', { duration: 3000 });

      // map to be an array
      const mappedForm = Object.entries(this.form).map(([index, formItem]) => formItem);

      await this.$nextTick();

      const response = await this.$store.dispatch('saveUserSurveyResponse', mappedForm);

      await this.$nextTick();

      if (response) {
        this.$toasted.success('success!', { duration: 4000 });

        // check if a survey is due
        // if so, redirect to the survey
        const surveyDue = await this.$store.dispatch('userHasPendingSurvey');

        if (surveyDue) {
          window.location.reload();

          return;
        }

        this.$router.push('/app');

        // get prompt for user
        this.$store.dispatch('getCurrentUserJourneyAction');
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Error in saving your survey response.',
        });
      }

      this.btnText = 'Send';
      this.disableBtn = false;
    },
  },
  async created() {
    this.loading = true;
    this.error = undefined;

    const userHasPendingSurvey = await this.$store.dispatch('userHasPendingSurvey');

    if (!userHasPendingSurvey) {
      this.$router.push('/app');

      await this.$nextTick();

      this.$toasted.info('You have no pending survey', { duration: 3000 });
      this.$store.dispatch('getCurrentUserJourneyAction');

      return;
    }

    await Promise.all([
      this.getSurveyQuestions(),
      // this.getSurveyUserResponse(),
    ]);

    await this.$nextTick();

    this.loading = false;
  },
};
</script>

<style>

</style>
