import { mapGetters } from 'vuex';
import _compact from 'lodash/compact';
import { toBoolean } from '@/modules/dataHelper';

export default {
  name: 'ResourceMixin',
  props: ['id'],
  data() {
    return {
      shareTo: _compact([this.$route.query.to || this.id]),
      user: {},
      selectedResources: [],
      selectedLinkResources: [],
      myResources: [],
      myLinkResources: [],
      myDocumentsResources: [],
      myImagesResources: [],
      myAudioResources: [],
      myVideoResources: [],
      menteeResourcesCategories: [
        {
          title: 'Links',
          data: this.myLinkResources,
        },
        {
          title: 'Documents',
          data: this.myDocumentsResources,
        },
        {
          title: 'Images',
          data: this.myImagesResources,
        },
        {
          title: 'Audio',
          data: this.myAudioResources,
        },
        {
          title: 'Videos',
          data: this.myVideoResources,
        },
      ],
      otherResources: {},
      otherLinkResources: {},
      myPillarResources: {},
      myLinksPillarResources: {},
      myDocumentsPillarResources: {},
      myImagesPillarResources: {},
      myAudioPillarResources: {},
      myVideoPillarResources: {},
      mentorResourcesCategories: [
        {
          title: 'Links',
          data: this.myLinksPillarResources,
        },
        {
          title: 'Documents',
          data: this.myDocumentsPillarResources,
        }, {
          title: 'Images',
          data: this.myImagesPillarResources,
        }, {
          title: 'Audio',
          data: this.myAudioPillarResources,
        }, {
          title: 'Videos',
          data: this.myVideoPillarResources,
        },
      ],
      modules: [],
      pillars: [],
      myUploadedPillars: [],
      filterValue: '',
    };
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
    shareToWho() {
      if (!this.shareTo[0]) {
        return undefined;
      }
      if (this.user.user) {
        return `to ${this.user.user.name}`;
      }
      return undefined;
    },
    totalSelectedResources() {
      return this.selectedResources.length + this.selectedLinkResources.length;
    },
    resourcesAreSelected() {
      const totalSelected = this.selectedResources.length + this.selectedLinkResources.length;
      return totalSelected > 1;
    },
    myTotalResources() {
      return this.myResources.length;
    },
    filteredOtherResources() {
      const searchValue = this.filterValue.toLowerCase();

      return this.otherResources.filter((dataItem) => {
        if ((dataItem?.name || '').includes(searchValue) || (dataItem?.data || '').includes(searchValue)) {
          return dataItem;
        }

        return null;
      });
    },
    filteredOtherLinkResources() {
      const searchValue = this.filterValue.toLowerCase();

      return this.otherLinkResources.filter((dataItem) => {
        if ((dataItem?.title || '').includes(searchValue) || (dataItem?.link || '').includes(searchValue)) {
          return dataItem;
        }

        return null;
      });
    },
  },
  methods: {
    hasResources(dataName, childDataName = undefined) {
      return childDataName
        ? (this[dataName][childDataName] || []).length > 0
        : this[dataName].length > 0;
    },
    async openUploadModal() {
      this.$store.commit('openModal', [true]);
    },
  },
  async mounted() {
    // should be false if query is not found
    if (toBoolean(this.$route.query['prompt-upload'])) {
      await this.openUploadModal();
    }
  },
};
