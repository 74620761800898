<template>
  <AppView padd-bottom>
    <TopNav
      slot="top-app-view"
      title=""
      title-class="tw-mr-auto"
      type="menu"
      @input="openSideNav()"
      show-notification-icon
    />
    <div class="mx-body">
      <transition name="fade" mode="out-in" appear>
        <div v-if="error" class="tw-text-center">
          <p class="tw-text-xs opacity-54 tw-py-18 tw-text-center" v-html="error"></p>
        </div>
        <div v-else-if="loading">
          <p class="tw-text-xs opacity-54 tw-py-18 tw-text-center">Finding available mentors...</p>
        </div>
        <div v-else-if="myMentors.length >= 3">
          <div class="tw-w-1/2 tw-bg-app-primary-orange tw-p-4 tw-mx-auto tw-mb-4 tw-rounded-10 tw-mt-8">
            <p >
              You can only have a maximum of <strong>3</strong> mentors.
            </p>
          </div>
        </div>
        <div v-else-if="!findMentors">
          <div v-if="lockKengenExperienceOnly" class="sm:tw-w-1/2 tw-bg-app-primary-orange tw-p-4 tw-mt-18 tw-mx-auto tw-mb-4 tw-rounded-10">
            <p>
              Complete at least <strong>{{minimumCompletedKengenExperienceGoals}} goals</strong> from <strong class="">Kengen Experience</strong>
              pillar for you to get matches in other pillars
            </p>
          </div>

          <p class="tw-text-app-deep-blue font-title tw-text-xl tw-pb-2 tw-text-center tw-mt-4">Your goals</p>
          <p class="tw-text-sm tw-pb-6 tw-text-center">Select one to find a mentor</p>
          
          <div class="tw-max-w-sm sm:tw-max-w-4xl tw-mx-auto">
            <template v-for="({ pillarGoals, ...pillar }, index) in goalsGroupedByPillars">
              <div :key="`pillar-${index}`" class="tw-mb-4">
                <h3 class="tw-text-base lg:tw-text-lg opacity-54 leading-19 lg:leading-24 mr-10 font-title">{{ pillar.goal_group }}</h3>
                <div
                  class="tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-three tw-flex-wrap tw-mt-4 tw-text-center"
                >
                  <template v-for="({ goal, request, mentor, goalCompleted }, index) in pillarGoals">
                    <div
                      :key="index"
                      :class="[
                        'info-card tw-shadow-app tw-relative ',
                        {
                          'tw-cursor-pointer': !!request == false && !!mentor == false && goalCompleted == false
                        },
                        {
                          'opacity-54': !!request || !!mentor || !!goalCompleted
                        },
                        {
                          'info-card--selected': !!request == false && !!mentor == false && selectedGoal == goal.id && goalCompleted == false
                        },
                      ]"
                      @click="!!request == false && !!mentor == false && goalCompleted == false ? selectedGoal = goal.id : undefined"
                    >
                      <h4 class="tw-leading-tight tw-font-medium opacity-87 tw-text-xl tw-px-2 tw-py-4 tw-break-words">
                        {{ goal.goal }}
                      </h4>

                      <div v-if="mentor || request" class="divider tw-mx-auto tw-w-1/5 opacity-11 tw-border-black tw-border-t"></div>

                      <div class="tw-rounded-10 tw-my-3">
                        <p v-if="mentor" class="tw-text-xs tw-uppercase opacity-54">
                          Current mentor
                        </p>
                        <p v-else-if="request" class="tw-text-xs opacity-54">
                          You have a pending match request on this goal.
                        </p>

                        <div v-if="goalCompleted">
                          <CompletedGoal />
                        </div>
                        
                        <!-- // list the mentor for this goal if mentee has the mentor -->
                        <div
                          v-if="mentor || request"
                          :class="[
                            'tw-inline-flex tw-mx-auto tw-p-1',
                            { 'opacity-54': request }
                            ]"
                        >
                          <UserPhoto
                            :photo="(mentor || {}).photo || ((request || {}).mentor || {}).photo"
                            photo-size="tw-h-4 tw-w-4"
                            class="tw-inline-block"
                          />
                          <h5 class="tw-text-xs font-title leading-17 tw-ml-2 tw-inline-block">
                            {{(mentor || {}).name || ((request || {}).mentor || {}).name}}
                            {{(mentor || {}).middle || ((request || {}).mentor || {}).middle}}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>

            <div class="tw-text-center" v-if="goalsGroupedByPillars.length">
              <BaseButton
                @click="gotToMentorsSuggestions"
                text="Find a mentor"
                :disabled="!!selectedGoal == false"
                class="tw-bg-black tw-text-white tw-py-2 tw-mt-4"
              />
            </div>
            <div v-else class="tw-text-center">
              <BaseButton
                text="Select different goals"
                :to="{name: 'settings'}"
              />

            </div>
          </div>
        </div>
        <div v-else class="tw-overflow-auto">
          <h2 class="tw-text-app-deep-blue font-title tw-text-xl tw-mt-6 tw-mb-1 tw-text-center">Available Mentors</h2>

          <ViewMore
            class="tw-mt-4"
            key="available-mentors"
            more-btn-text="more mentors"
            :limit="6"
            :list="mentorsList"
            v-slot="{ item: mentor }"
          >
            <div class="tw-relative tw-block tw-w-full tw-max-w-xs tw-mx-auto tw-text-center">
              <UserPhoto
                :photo="mentor.photo"
                class="tw-inline-block"
                :user-name="mentor"
              />
              <h4 class="tw-text-center tw-font-normal tw-text-xl leading-24">
                {{ mentor.name }} {{ mentor.middle }}
              </h4>
              <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 tw-mr-2 leading-19 tw-px-2 tw-inline-block">
                Profession: <b>{{ mentor.profession.profession || '...' }}</b>
              </p>
              <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
                Experience: <b>{{ mentor.experience.experience }} years</b>
              </p>
              <template v-if="mentor.department">
                <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
                  Department: <b>{{ mentor.department?.departments }}</b>
                </p>
              </template>

              <template v-if="mentor.regional_area">
                <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
                  Region: <b>{{ mentor.regional_area?.region }}</b>
                </p>
              </template>

              <template v-if="mentor.sub_area">
                <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
                  Sub-area: <b>{{ mentor.sub_area?.sub_area }}</b>
                </p>
              </template>

              <br>
              <p
                v-if="mentor.bio"
                class="tw-text-base tw-text-center opacity-78 tw-text-black tw-mt-2 leading-19 h-60 tw-overflow-hidden"
              >
                {{ mentor.bio }}
              </p>
              <div v-else>
                <p class="tw-text-sm tw-text-center opacity-54 tw-text-black leading-19 tw-pt-2">
                  availability
                </p>
                <p
                  class="tw-text-xs tw-mr-2 tw-text-center opacity-54 tw-text-white leading-19 tw-bg-gray-700 tw-rounded-full tw-px-2 tw-inline-block"
                  v-for="(availability, index) in mentor.availabilities"
                  :key="index"
                >
                  {{ availability.availability }}
                </p>

              </div>
              <div class="tw-flex --tw-justify-between tw-justify-end tw-py-4">
                <!-- <BaseButton
                  @click="sendMentorRequest(mentor.id)"
                  text="Send Request"
                  class="tw-bg-gray-200 tw-hidden"
                /> -->
                <BaseButton
                  :to="`/app/mentor/${mentor.id}/bio?goalid=${selectedGoal}`"
                  text="Full Profile"
                  icon
                  icon-right
                >
                  <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M16,67.8c0-1.2,0.4-2,1.2-2.9l29-29l-29-29c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l31.9,31.9c1.6,1.6,1.6,4.1,0,5.7
                      L22.9,70.8c-1.6,1.6-4.1,1.6-5.7,0C16.4,69.9,16,69,16,67.8z"/>
                  </svg>
                </BaseButton>
              </div>
              <div class="tw-border opacity-18 tw-w-full tw-mt-6"></div>
            </div>
          </ViewMore>
        </div>
      </transition>
    </div>
  </AppView>
</template>

<script>
// import SelectGroup from '@/components/SelectGroup.vue';
import CompletedGoal from '@/components/CompletedGoal.vue';
import _groupBy from 'lodash/groupBy';
import _compact from 'lodash/compact';


export default {
  name: 'MentorsSuggestionsList',
  components: {
    CompletedGoal,
  },
  data() {
    return {
      mentorsList: [],
      selectedGoal: undefined,
      goalsGroupedByPillars: [],
      // goals: [],
      myMentors: [],
      findMentors: false,
      loading: true,
      error: undefined,
      lockKengenExperienceOnly: false,
    };
  },
  computed: {
    hasMentors() {
      return this.mentorsList.length > 0;
    },
    minimumCompletedKengenExperienceGoals() {
      return this.$store.state.goals.minimumCompletedKengenExperienceGoals;
    },
  },
  methods: {
    // sendMentorRequest(mentorId) {
    //   // apiPost
    //   console.log(mentorId);
    // },
    getMentorsSuggestionsList() {
      this.loading = true;

      if (!this.selectedGoal) {
        console.warn('No goal was selected');

        this.loading = false;
        return;
      }

      this.$store.dispatch('mentorsSuggestionsList', this.selectedGoal)
        .catch((err) => {
          const errorMessage = 'Error in getting information of your mentors';
          console.error(errorMessage, err);
          this.$toasted.global.appError({ errorMessage });
          this.error = errorMessage;
        })
        .then((mentors) => {
          if (!mentors) return false;

          this.mentorsList = mentors.filter((mentor) => {
            const isMyMentor = this.myMentors.find((m) => m.user.id == mentor.id);

            return isMyMentor ? null : mentor;
          });

          if (this.mentorsList.length > 0) {
            this.error = undefined;
          } else {
            this.error = `
            Currently there are no mentors available.
            <br>
            Try selecting a different goal
            <br>
            <br>
            <br>
            <a href="/app/request-mentor/mentors" class="tw-px-4 tw-py-2 tw-mt-4 tw-border tw-border-gray-900 tw-rounded-sm tw-uppercase bg-black-11">View Goals</a>
            `;
          }

          this.loading = false;

          return mentors;
        });
      //
    },
    async getMenteeSelectedGoals() {
      const [allMenteeGoals, pillars, goals, myMentors, myPendingMentorsRequests, getMenteeFinishedGoals] = await Promise.all([
        this.$store.dispatch('getMenteeGoalsData'),
        this.$store.dispatch('getPillarGroups', []),
        this.$store.dispatch('getGoals', []),
        this.$store.dispatch('getMyMentors'),
        this.$store.dispatch('getMyPendingMentorsRequests'),
        this.$store.dispatch('getMenteeFinishedGoals'),
      ]);

      // console.log(goals);

      // attach mentee's mentors requests data to goals data
      // attached goal matches to goals data
      const menteeGoalsWithMentorsData = allMenteeGoals.map((menteeGoal) => {
        const mentorRequest = myPendingMentorsRequests.find((req) => req.goalid == menteeGoal.goal.id);
        const goalMentor = myMentors.find((m) => m.goalid == menteeGoal.goal.id);

        const goalCompleted = !!(getMenteeFinishedGoals || []).find((g) => g.goalid == menteeGoal.goal.id);

        // console.log(goalMentor);

        return {
          ...menteeGoal,
          request: mentorRequest || undefined,
          goalCompleted,
          mentor: goalMentor ? goalMentor.user : undefined,
        };
      });

      // group mentee goals by pillar ids
      const goalsGroupedByPillarIds = _groupBy(menteeGoalsWithMentorsData, (g) => g.pillar.id);

      // change from object of pillars to array of pillars
      const goalsGroupedByPillars = Object.entries(goalsGroupedByPillarIds).map(([pillarId, pillarGoals]) => {
        // * will only be one pillar if mentee has no mentor in kengen experience
        const pillar = pillars.find((p) => p.id == pillarId);

        if (pillar) {
          return {
            ...pillar,
            pillarGoals,
          };
        }
      });

      // * check if mentee has finished 2 kengen experience goals
      // * if not, remove other pillars from pillars list
      let countFinishedKengenExperiencedGoals = 0;
      const kengenExperienceId = this.$store.state.goals.kengenExperienceId;

      getMenteeFinishedGoals.forEach((g) => {
        const finishedGoal = goals.find((_g) => _g.id == g.goalid)

        // console.log(finishedGoal);

        if (finishedGoal && finishedGoal.groupid == kengenExperienceId) {
          countFinishedKengenExperiencedGoals += 1
        }
      });

      // if (countFinishedKengenExperiencedGoals < this.minimumCompletedKengenExperienceGoals) {
      //   // lock 
      //   this.lockKengenExperienceOnly = true;

      //   this.goalsGroupedByPillars = _compact([goalsGroupedByPillars.find((pillar) => pillar.id == kengenExperienceId)]);

      //   return;
      // }

      this.goalsGroupedByPillars = goalsGroupedByPillars;
      // this.goals = menteeGoalsWithMentorsData;
    },
    async gotToMentorsSuggestions() {
      if (this.selectedGoal) {
        this.getMentorsSuggestionsList();

        await this.$nextTick();

        this.findMentors = true;
      } else {
        this.$toasted.info('Select a goal first', {
          duration: 5000,
          position: 'top-right',
        });
      }
    },
    goalIsSelected(id) {
      return !!this.selectedGoals.find((t) => t === id);
    },
  },
  async created() {
    this.$store.commit('isFlatView', false);

    if (this.isSequentialProgram) {
      this.$router.push({
        name: 'ProgramMentorsSuggestionsList',
      });

      return;
    }

    this.loading = true;
    // this.lockKengenExperienceOnly = true;

    this.myMentors = await this.$store.dispatch('getMyMentors');

    await this.getMenteeSelectedGoals();

    this.loading = false;
  },
};
</script>

<style>

</style>
