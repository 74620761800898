<template>
  <AppView>
    <TopNav
      title="My Profile"
      title-class="lg:ml-body lg-app:tw-font-bold"
      center-title
      type="menu"
      @input="openSideNav()"
      :show-notification-icon="false"
      padd-bottom
      slot="top-app-view"
    />
    <div class="mx-body tw-pb-10">
      <div class="tw-max-w-lg tw-mx-auto">
        <div v-if="error" v-html="error"></div>
        <div v-else-if="loading">
          <p class="opacity-54 tw-text-xs tw-text-center">Loading...</p>
        </div>
        <div v-else-if="user">
          <transition name="new" mode="out-in" appear>
            <div class="tw-inline-flex tw-flex-nowrap tw-flex-none tw-mt-3 lg:tw-mx-auto">
              <div
                :class="[
                  'tw-inline-block tw-w-20 tw-h-20 tw-rounded-full tw-relative tw-cursor-pointer tw-flex-shrink-0',
                  {'tw-bg-gray-500' : isValidPhoto},
                  {'tw-bg-red-200' : !isValidPhoto},
                  {'opacity-11' : loadingPhoto},
                  {'tw-overflow-hidden': isValidPhoto && !loadingPhoto}
                ]"
                @click="promptUpdatePhoto"
              >
                <!-- PUT PROFILE PIC HERE -->
                <img v-if="isValidPhoto && !loadingPhoto" :src="form.photo || userPhoto(user.photo)" class="tw-w-full tw-h-full tw-object-cover tw-rounded-full" />
                <transition name="new" appear mode="out-in">
                  <span
                    style="transform: translateX(-50%); top: calc(100% + 4px);"
                    v-if="!isValidPhoto"
                    class="tw-text-red-500 leading-14 tw-text-xs tw-absolute tw-bottom-0 tw-left-1/2 tw-text-center tw-w-full"
                  >Photo needed!</span>
                </transition>
                <input
                  class="tw-hidden"
                  type="file"
                  ref="photo-input"
                  accept="image/*"
                  @change="addUserPhoto"
                />
              </div>
              <div class="tw-pt-3 tw-ml-6">
                <CameraPhoto v-model="form.photoFileFromCamera" v-if="!isMobileView && !isValidPhoto" class="tw-mt-4" />
                
                <h4 v-if="!editMode.name" class="tw-text-base lg:tw-text-xl leading-19 font-title tw-font-bold">
                  {{ userFullName }}
                  <span>
                    <BaseButton
                      text="Edit Name"
                      class="tw-text-black bg-black-11 tw-py-1 tw-ml-1 tw-border-none tw-text-xs"
                      icon
                      @click="promptUpdateName"
                    >
                      <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                          l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                          M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                          c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                      </svg>
                    </BaseButton>
                  </span>
                </h4>
                <div v-else>
                  <div class="tw-inline-flex">
                    <InputGroup
                      label=""
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      input-classes="tw-p-3"
                      v-model="form.name"
                      class="tw-mr-1"
                    />
                    <InputGroup
                      label=""
                      name="lastName"
                      type="text"
                      placeholder="last name"
                      input-classes="tw-p-3"
                      v-model="form.last_name"
                    />
                  </div>
                  <div class="tw-flex tw-justify-start tw-py-2">
                    <div>
                      <BaseButton
                        :text="saveNameBtnText"
                        class="tw-text-white tw-bg-black"
                        :disabled="!canSaveName || saveNameBtnDisabled"
                        @click="saveName"
                      />
                    </div>
                    <div class="tw-pl-4">
                      <BaseButton
                        text="Cancel"
                        class="tw-text-black bg-black-11"
                        @click="cancelEditingName"
                      />
                    </div>
                  </div>
                </div>
                <h6 v-if="user.profession" class="tw-font-bold tw-text-xs leading-15 opacity-54 tw-mt-1 tw-uppercase">
                  {{ user.profession.profession }}
                </h6>
              </div>
            </div>
          </transition>

          <transition name="new" mode="out-in" appear>
            <!-- badges -->
            <div class="tw-bg-gray-200 tw-rounded-10 tw-p-2 tw-mt-8" v-if="!isSequentialProgram">
              <p class="tw-text-sm opacity-54 tw-mb-2">Your Badges</p>
              <UserGoalsBadges large />
            </div>
          </transition>

          <template v-if="shouldEditKengenDetails">
            <div>
              <div class="tw-pb-4">
                <KenGenUserSignUp
                  @input="updateFormKenGenDetails"
                  title="Update your Kengen details"
                  :default-values="userFormKenGenDefaultDetails"
                />
                
                <BaseButton
                  :disabled="!canSaveKengenDetails"
                  @click="saveKengenDetails"
                  text="Save Kengen details"
                  class="tw-py-3 tw-bg-black tw-text-white"
                />
              </div>
              <div class="tw-border opacity-18 tw-my-6"></div>
            </div>
          </template>
          <transition name="new" mode="out-in" appear>
            <!-- BIO -->
            <div class="tw-mt-14">
              <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
                <h5 :class="[
                  'tw-text-xs leading-15 tw-font-bold tw-py-2',
                  {'tw-text-red-500': !isValidBio},
                ]">Bio</h5>
                <BaseButton
                  text="Edit"
                  class="tw-text-black bg-black-11 tw-py-1 tw-border-none"
                  icon
                  v-if="!editMode.bio"
                  @click="promptUpdateBio"
                >
                  <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                      l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                      M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                      c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                  </svg>
                </BaseButton>
              </div>
              <transition name="fade" mode="out-in" appear>
                <div v-if="!editMode.bio">
                  <div v-if="isValidBio">
                    <h5 style="max-height: 44px;" class="tw-text-xs leading-14 opacity-54 tw-overflow-hidden ">{{ user.bio }}</h5>
                  </div>
                  <div
                    v-else
                    class="tw-rounded-sm tw-bg-red-100 tw-text-red-500 tw-text-center tw-py-4 tw-cursor-pointer"
                    @click="promptUpdateBio"
                  >
                    <p class=" tw-text-xs leading-14 tw-mb-3">You need to write your bio</p>
                    <BaseButton
                      text="Edit"
                      class="tw-text-black bg-black-11"
                      icon
                    >
                      <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                          l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                          M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                          c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                      </svg>
                    </BaseButton>
                  </div>
                </div>
                <div v-else>
                  <label class="tw-text-xs tw-text-black opacity-78">{{ bioLength }} / {{ maxBioLength }}</label>
                  <BaseTextarea
                    rows="3"
                    class="tw-border-b"
                    v-model="form.bio"
                    max-height="400px"
                    :value="user.bio"
                  />
                  <div class="tw-flex tw-justify-end tw-py-2">
                    <div>
                      <BaseButton
                        :text="saveBioBtnText"
                        class="tw-text-white tw-bg-black"
                        :disabled="!canSaveBio || saveBioBtnDisabled"
                        @click="saveBio"
                      />
                    </div>
                    <div class="tw-pl-4">
                      <BaseButton
                        text="Cancel"
                        class="tw-text-black bg-black-11"
                        @click="cancelEditingBio"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
          <div class="tw-border opacity-18 tw-my-6"></div>
          <transition name="new" mode="out-in" appear>
            <!-- AVAILABILITY -->
            <div class="tw-mb-6">
              <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
                <h5 class="tw-text-xs leading-15 tw-font-bold tw-mb-1 tw-py-2">Availability</h5>
                <BaseButton
                  text="Edit"
                  class="tw-text-black bg-black-11 tw-py-1 tw-border-none"
                  icon
                  v-if="!editMode.availability || (canEditAvailability && !editMode.availability)"
                  @click="editMode.availability = true"
                >
                  <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                      l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                      M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                      c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                  </svg>
                </BaseButton>
              </div>
              <transition name="fade" mode="out-in" appear>
                <div v-if="!editMode.availability">
                  <ul class="">
                    <li
                      class="tw-inline-block tw-mr-4 tw-mb-1 opacity-54"
                      v-for="(availability, index) in user.availabilities"
                      :key="index"
                    >
                      {{ availability.availability }}
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <SelectGroup
                    label=""
                    name="availability"
                    instruct="You can add multiple values"
                    nameKey="availability"
                    placeholder="Experience"
                    v-model.lazy="form.userAvailability"
                    :options="availabilityList"
                    :reduce="availability => availability.id || availability"
                    :multiple="true"
                    :clearable="true"
                  />
                  <div class="tw-flex tw-justify-end tw-py-2">
                    <div>
                      <BaseButton
                        :text="saveAvailabilityBtnText"
                        class="tw-text-white tw-bg-black"
                        :disabled="form.availability.length === 0"
                        @click="saveAvailability"
                      />
                    </div>
                    <div class="tw-pl-4">
                      <BaseButton
                        text="Cancel"
                        class="tw-text-black bg-black-11"
                        @click="editMode.availability = false"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
          <div class="tw-border opacity-18 tw-my-6"></div>
          <transition name="new" mode="out-in" appear>
            <!-- EDIT EMAIL ADDRESS -->
            <div class="tw-mb-6">
              <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
                <h5 class="tw-text-xs leading-15 tw-font-bold tw-mb-1 tw-py-2">E-mail address</h5>
                <BaseButton
                  text="Edit"
                  class="tw-text-black bg-black-11 tw-py-1 tw-border-none"
                  icon
                  v-if="!editMode.email"
                  @click="promptUpdateEmail"
                >
                  <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                      l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                      M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                      c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                  </svg>
                </BaseButton>
              </div>
              <transition name="fade" mode="out-in" appear>
                <div v-if="!editMode.email">
                  <div>
                    <h5 style="max-height: 44px;" class="tw-text-xs leading-14 opacity-54 tw-overflow-hidden ">{{ user.email }}</h5>
                  </div>
                </div>
                <div v-else>
                  <InputGroup
                    label=""
                    name="email"
                    type="email"
                    placeholder="email address"
                    :instruct="isKengen ? 'Please use your KenGen email address' : ''"
                    input-classes="tw-p-3"
                    v-model="form.email"
                  />
                  <div class="tw-flex tw-justify-start tw-py-2">
                    <div>
                      <BaseButton
                        :text="saveEmailBtnText"
                        class="tw-text-white tw-bg-black"
                        :disabled="!canSaveEmail || saveEmailBtnDisabled"
                        @click="saveEmail"
                      />
                    </div>
                    <div class="tw-pl-4">
                      <BaseButton
                        text="Cancel"
                        class="tw-text-black bg-black-11"
                        @click="cancelEditingEmail"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
          <div class="tw-border opacity-18 tw-my-6"></div>
          <transition name="new" mode="out-in" appear>
            <!-- EDIT PHONE NUMBER -->
            <div class="tw-mb-6">
              <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
                <h5 class="tw-text-xs leading-15 tw-font-bold tw-mb-1 tw-py-2">Phone number</h5>
                <BaseButton
                  text="Edit"
                  class="tw-text-black bg-black-11 tw-py-1 tw-border-none"
                  icon
                  v-if="!editMode.phoneNumber"
                  @click="promptUpdatePhoneNumber"
                >
                  <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                      l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                      M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                      c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                  </svg>
                </BaseButton>
              </div>
              <transition name="fade" mode="out-in" appear>
                <div v-if="!editMode.phoneNumber">
                  <div>
                    <h5 style="max-height: 44px;" class="tw-text-xs leading-14 opacity-54 tw-overflow-hidden ">{{ user.working_num }}</h5>
                  </div>
                </div>
                <div v-else>
                  <PhoneNumberInput2
                    label=""
                    name="phone"
                    placeholder="7********"
                    instruct="select your country code"
                    dial-codes
                    input-classes="tw-p-3"
                    v-model="form.phoneNumber"
                  />
                  <div class="tw-flex tw-justify-start tw-py-2">
                    <div>
                      <BaseButton
                        :text="savePhoneNumberBtnText"
                        class="tw-text-white tw-bg-black"
                        :disabled="!canSavePhoneNumber || savePhoneNumberBtnDisabled"
                        @click="savePhoneNumber"
                      />
                    </div>
                    <div class="tw-pl-4">
                      <BaseButton
                        text="Cancel"
                        class="tw-text-black bg-black-11"
                        @click="cancelEditingPhoneNumber"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </AppView>
</template>

<script>
import { mapGetters } from 'vuex';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import LicenseCard from '@/components/LicenseCard.vue';
import PhoneNumberInput2 from '@/components/PhoneNumberInput2.vue';
import KenGenUserSignUp from '@/views/_partials/KenGenUserSignUp.vue';
import CameraPhoto from '@/components/CameraPhoto.vue';
import UserGoalsBadges from '@/components/UserGoalsBadges.vue';

import { apiPost, apiGet, generateFormData } from '@/modules/apiHelper';
import profileMixin from '@/mixins/profile';
import { getPhoto, dataURItoBlob } from '@/modules/dataHelper';

export default {
  name: 'UserProfile',
  components: {
    InputGroup,
    SelectGroup,
    LicenseCard,
    PhoneNumberInput2,
    KenGenUserSignUp,
    CameraPhoto,
    UserGoalsBadges,
  },
  mixins: [profileMixin],
  data() {
    return {
      loading: true,
      error: undefined,
      user: {},
      canEditAvailability: false,
      saveBioBtnText: 'Save bio',
      saveBioBtnDisabled: false,
      //
      saveAvailabilityBtnText: 'Save',
      //
      savePhoneNumberBtnText: 'Save',
      savePhoneNumberBtnDisabled: false,
      //
      saveEmailBtnDisabled: false,
      saveEmailBtnText: 'Save',
      //
      saveNameBtnDisabled: false,
      saveNameBtnText: 'Save',
      //
      isMainApp: undefined,
      availabilityList: [],
      walletHistory: [],
      packages: [],
      pillars: [],
      form: {
        photo: '',
        photoFile: undefined,
        photoFileFromCamera: undefined,
        photoBlobFromCamera: undefined,
        bio: '',
        availability: [],
        userAvailability: [],
        linkedIn: undefined,
        phoneNumber: undefined,
        email: '',
        name: '',
        last_name: '',
      },
      editMode: {
        bio: false,
        photo: false,
        availability: false,
        phoneNumber: false,
        email: false,
        name: false,
      },
      userFormKenGenDetails: {},
      userFormKenGenDefaultDetails: {},
    };
  },
  watch: {
    'form.photoFileFromCamera': function (val) {
      if (val) {
        this.form.photo = val;

        this.$toasted.info('Saving profile photo', {
          duration: 5000,
          position: 'top-right',
        });

        // console.log(dataURItoBlob(val));

        const blobFile = dataURItoBlob(val);

        blobFile.lastModifiedDate = new Date();
        blobFile.name = 'a name.png'
        
        this.form.photoFile = blobFile;
      }
    },
    // eslint-disable-next-line func-names
    'form.photoFile': function (val) {
      if (val) {
        this.loadingPhoto = true;

        getPhoto(this.form.photoFile, (event) => {
          this.form.photo = event.target.result;
          this.savePhoto();
        });

        return '';
      }
      return true;
    },
    // eslint-disable-next-line func-names
    'form.userAvailability': function (val) {
      this.form.availability = val;
    },
  },
  computed: {
    ...mapGetters([
      'userTypeName',
      'userId',
    ]),
    userFullName() {
      return `${this.user.name} ${this.user.last_name}`;
    },
    canSaveProfile() {
      return this.form.photoFile || this.form.availability;
    },
    license() {
      return this.$store.state.user.mentee.license.package;
    },
    shouldEditKengenDetails() {
      if (this.user && this.isKengen) {
        return (this.user.staff.length == 0 || this.user.staff == '0')
          || !Number(this.user.profession?.id) // designation
          || !Number(this.user.department?.id)
          || !Number(this.user.dept_level?.id)
          || !Number(this.user.regional_area?.id)
          || !Number(this.user.division?.id)
          || !Number(this.user.sub_area?.id)
      }
      return false
    },
    canSaveKengenDetails() {
      return !!this.userFormKenGenDetails.staff.length
          && !!Number(this.userFormKenGenDetails.designation)
          && !!Number(this.userFormKenGenDetails.department)
          && !!Number(this.userFormKenGenDetails.dept_level)
          && !!Number(this.userFormKenGenDetails.regional)
          && !!Number(this.userFormKenGenDetails.division)
          && !!Number(this.userFormKenGenDetails.sub_area)
    }
  },
  methods: {
    async promptUpdateBio() {
      this.form.bio = this.user.bio;
      this.editMode.bio = true;
      await this.$nextTick();
      await this.$nextTick();
    },
    cancelEditingBio() {
      this.editMode.bio = false;
      this.form.bio = '';
    },
    promptUpdateEmail() {
      this.form.email = this.user.email;
      this.editMode.email = true;
    },
    cancelEditingEmail() {
      this.editMode.email = false;
      this.form.email = '';
    },
    promptUpdatePhoneNumber() {
      this.form.phoneNumber = this.user.working_num;
      this.editMode.phoneNumber = true;
    },
    cancelEditingPhoneNumber() {
      this.editMode.phoneNumber = false;
      this.form.phoneNumber = '';
    },
    promptUpdateName() {
      this.form.name = this.user.name;
      this.form.last_name = this.user.last_name;
      this.editMode.name = true;
    },
    cancelEditingName() {
      this.editMode.name = false;
      this.form.name = '';
      this.form.last_name = '';
    },
    async getUser() {
      await this.$store.dispatch('deleteUserData', this.userId);
      const user = await this.$store.dispatch('getUserData', this.userId);

      await this.$nextTick();

      this.user = { ...(user || {}) };
    },
    async savePhoto() {
      if (!this.canSaveProfile) return false;
      this.loadingPhoto = true;

      const result = await this.$store.dispatch('updateUserPhoto', [this.form.photoFile, (this.form.photoFile instanceof File ? false: true)]);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'Profile photo could not be uploaded, please try again.' });
      } else {
        await this.getUser();

        this.$toasted.success('Profile photo saved!', {
          duration: 3000,
          position: 'top-right',
        });
      }

      this.loadingPhoto = false;
      return true;
    },
    async saveBio() {
      if (!this.canSaveBio) return false;
      this.saveBioBtnText = 'saving...';
      this.saveBioBtnDisabled = true;

      const result = await this.$store.dispatch('updateUserBio', this.form.bio);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'Bio could not be uploaded, please try again.' });
      } else {
        await this.getUser();

        this.$toasted.success('Bio updated!', {
          duration: 3000,
          position: 'top-right',
        });

        this.editMode.bio = false;
      }

      this.saveBioBtnDisabled = false;
      this.saveBioBtnText = 'Save Bio';
      return true;
    },
    async saveAvailability() {
      if (this.form.availability.length === 0) return false;
      this.saveAvailabilityBtnText = 'saving...';

      const result = await this.$store.dispatch('updateUserAvailability', this.form.availability);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'Availability could not be uploaded, please try again.' });
      } else {
        await this.getUser();

        this.$toasted.success('Availability updated!', {
          duration: 3000,
          position: 'top-right',
        });

        this.form.userAvailability = this.user.availability;
        this.editMode.availability = false;
      }

      this.saveAvailabilityBtnText = 'Save';
      return true;
    },
    async savePhoneNumber() {
      if (!this.canSavePhoneNumber) return false;
      this.savePhoneNumberBtnText = 'saving...';
      this.savePhoneNumberBtnDisabled = true;

      const result = await this.$store.dispatch('updateUserPhoneNumber', this.form.phoneNumber);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'Phone number could not be updated, please try again.' });
      } else {
        await this.getUser();

        this.$toasted.success('Phone number updated!', {
          duration: 3000,
          position: 'top-right',
        });

        this.editMode.phoneNumber = false;
      }

      this.savePhoneNumberBtnDisabled = false;
      this.savePhoneNumberBtnText = 'Save';
      return true;
    },
    async saveEmail() {
      if (!this.canSaveEmail) return false;
      this.saveEmailBtnText = 'saving...';
      this.saveEmailBtnDisabled = true;

      const result = await this.$store.dispatch('updateUserEmail', this.form.email);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'email address could not be updated, please try again.' });
      } else {
        await this.getUser();

        this.$toasted.success('Email address updated!', {
          duration: 3000,
          position: 'top-right',
        });

        this.editMode.email = false;
      }

      this.saveEmailBtnDisabled = false;
      this.saveEmailBtnText = 'Save';
      return true;
    },
    async saveName() {
      if (!this.canSaveName) return false;
      this.saveNameBtnText = 'saving...';
      this.saveNameBtnDisabled = true;

      const results = await Promise.all([
        this.$store.dispatch('updateUserName', {name: this.form.name, nameid: 1}), // save first name
        this.$store.dispatch('updateUserName', {name: this.form.last_name, nameid: 3}), // save last name
      ]).then(([firstNameRes, lastNameRes]) => !!firstNameRes && !!lastNameRes)

      if (!results) {
        this.$toasted.global.appError({ errorMessage: 'name could not be updated, please try again.' });
      } else {
        await this.getUser();

        this.$toasted.success('Name updated!', {
          duration: 3000,
          position: 'top-right',
        });

        this.editMode.name = false;
      }

      this.saveNameBtnDisabled = false;
      this.saveNameBtnText = 'Save';
      return true;
    },
    getPackageById(packageId) {
      return this.packages.find(aPackage => Number(aPackage.id) === Number(packageId)) || {};
    },
    getPillarById(pillarId) {
      return this.pillars.find(pillar => Number(pillar.id) === Number(pillarId)) || {};
    },
    updateFormKenGenDetails(formData) {
      this.userFormKenGenDetails = Object.assign({}, this.userFormKenGenDetails, formData);
    },
    async saveKengenDetails() {
      if (!this.canSaveKengenDetails) return;

      const formData = generateFormData({
        userid: this.user.id,
        staff: this.userFormKenGenDetails.staff,
        designation: this.userFormKenGenDetails.designation,
        profession: this.userFormKenGenDetails.designation,
        department: this.userFormKenGenDetails.department,
        dept_level: this.userFormKenGenDetails.dept_level,
        region: this.userFormKenGenDetails.regional,
        division: this.userFormKenGenDetails.division,
        sub_area: this.userFormKenGenDetails.sub_area,
      });

      const result = await this.$store.dispatch('updateUserKengenDetails', formData);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'Kengen details could not be saved, please try again.' });
      } else {
        await this.getUser();
      }
    }
  },
  async created() {
    this.$store.commit('isFlatView', false);

    await this.$store.dispatch('setScope');
    // eslint-disable-next-line prefer-destructuring
    this.isMainApp = (await this.$store.dispatch('evaluateScope'))[1];

    await this.$store.dispatch('getUserData', this.userId)
      .catch((err) => {
        const errorMessage = 'Error in preparing your profile';
        console.warn(errorMessage, err);
        this.$toasted.global.appError({ errorMessage });
      })
      .then((user) => {
        this.user = { ...(user || {}) };
        this.form.userAvailability = this.user.availability;
        this.form.phoneNumber = this.user.working_num;
        this.form.email = this.user.email;

        if (!this.isValidBio || !this.isValidPhoto) {
          this.$toasted.info('You need to add your bio and profile photo', {
            duration: 4000,
            position: 'top-right',
          });
        }

        const kengenDetails = {
          staff: this.user.staff,
          designation: this.user.profession?.id,
          department: this.user.department?.id,
          dept_level: this.user.dept_level?.id,
          regional: this.user.regional_area?.id,
          division: this.user.division?.id,
          sub_area: this.user.sub_area?.id,
        }

        this.updateFormKenGenDetails(kengenDetails)
        this.userFormKenGenDefaultDetails = kengenDetails;

        this.loadingPhoto = false;
        this.loading = false;
      });
    //

    apiGet('availability')
      .catch((err) => {
        console.warn(err);
        this.canEditAvailability = false;
      })
      .then((res) => {
        if (!res || (res || {}).error) {
          this.canEditAvailability = false;
          return false;
        }

        this.availabilityList = res.data.availability;
        this.canEditAvailability = true;
        return true;
      });
    //

    if (this.isMentee && this.isMainApp) {
      apiPost('pay_hist', generateFormData({ userid: this.user.id }), 15)
        .catch((err) => {
          const errorMessage = 'Error in getting your wallet history';
          console.warn(errorMessage, err);
          this.$toasted.global.appError({ errorMessage });
        })
        .then((res) => {
          if (!res || (res || {}).error) {
            return false;
          }
  
          this.walletHistory = res.data.pay_hist;
  
          return true;
        });
      //
      this.$store.dispatch('getPackages').then((packages) => {
        this.packages = packages;
      });
    }

    this.$store.dispatch('getPillarGroups', []).then((pillars) => {
      this.pillars = pillars;
    });
  },
};
</script>

<style>

</style>
