import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import settings from './settings';
import chat from './chat';
import groupChat from './group-chat';
import ui from './ui';
import notifications from './notifications';
import scope from './scope';
import ellyEbby from './elly-ebby';
import goals from './goals';
import surveys from './surveys';
import videoMeetings from './video-meetings';
import mentorshipGroups from './mentorship-groups';
import journeys from './journeys';
import experts from './experts';
import modules from './modules';
import resources from './resources';
import match from './match';
import tasks from './tasks';

Vue.use(Vuex);

Vue.config.devtools = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
  state: {},
  modules: {
    user,
    settings,
    chat,
    groupChat,
    ui,
    notifications,
    scope,
    ellyEbby,
    goals,
    surveys,
    videoMeetings,
    mentorshipGroups,
    journeys,
    experts,
    modules,
    resources,
    match,
    tasks,
  },
});
