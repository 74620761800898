import resolve from '../../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import {
  toBoolean, urlFriendly,
} from '../../../modules/dataHelper';

export default {
  async createSequentialTask(context, [userId, formData, resources = [], linkResources = []]) {
    const task = await apiPost('prog_create_tasks', formData, 70)
      .catch((err) => {
        console.warn('Error in creating task \n', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return res.data;
      });

    let taskResources = [true, 100];
    if (task && task.taskid) {
      taskResources = await context.dispatch('uploadSequentialTaskResource', [task.taskid, resources, linkResources]);
    }

    if (task) {
      await Promise.all([
        context.dispatch('clearReferencedItem', `draftTask/${userId}`),
        context.dispatch('clearReferencedItem', `CreateTaskRefResources/${userId}`),
        context.dispatch('clearReferencedItem', `CreateTaskRefLinkResources/${userId}`),
      ]);
    }

    return [task, ...taskResources];
  },

  async approveSequentialTaskReport(context, taskId) {
    const formData = new FormData();
    formData.set('taskid', taskId);

    const response = await apiPost('prog_mark_task_complete', formData, 70)
      .catch((err) => {
        console.warn('approveSequentialTaskReport', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        // get the updated task Details
        context.dispatch('getSequentialTask', [taskId, true]);

        return true;
      });

    return response;
  },

  async revertSequentialTask(context, [taskId, reportText, resources]) {
    if (!taskId) return false;
    const task = await context.dispatch('getSequentialTask', [taskId]);
    const {
      userId,
    } = context.getters;

    // eslint-disable-next-line eqeqeq
    if (userId != task.mentorid && userId != task.menteeid) return false;

    const formData = new FormData();
    formData.set('taskid', taskId);
    let endpoint = 'prog_reject_task_report';

    if (reportText) {
      formData.set('review', reportText);

      if (resources.length) {
        formData.set('text', 2);
        formData.set('name', resources[0].name);
        formData.append('file1', resources[0], urlFriendly(resources[0].name));
        endpoint = 'prog_reject_task_report';
      } else {
        formData.set('text', 1);
        endpoint = 'prog_reject_task_report_text';
      }
    } else if (resources.length) {
      formData.set('text', 3);
      formData.set('name', resources[0].name);
      formData.append('file1', resources[0], urlFriendly(resources[0].name));
      endpoint = 'prog_reject_task_report';
    } else {
      console.warn('No text or resources provided');
      return false;
    }

    const response = await apiPost(endpoint, formData, 70)
      .catch((err) => {
        console.warn('revertSequentialTask ERROR', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.error)) return false;

        return true;
      });
    //

    // upload more resources
    const requests = [];
    if (resources.length > 1) {
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index < resources.length; index++) {
        const resource = resources[index];

        const data = generateFormData({
          taskid: taskId,
          name: resource.name,
          text: 3,
        });

        data.append('file1', resource, urlFriendly(resource.name));

        requests.push(resolve(apiPost(endpoint, data, 70)));
      }
    }

    const responses = await Promise.all(requests);

    if (response === false || responses.some((res) => res === false)) {
      console.warn('Error in reverting task');
      return false;
    }

    // remove the task from the cache store
    await apiStore.removeItem(`task/${taskId}`);

    return response;
  },
};
