import actions from './actions';

export default {
  state: {
    toggleRefreshMyResources: false,
  },
  mutations: {
    toggleRefreshMyResources(state) {
      state.toggleRefreshMyResources = !state.toggleRefreshMyResources;
    },
  },
  actions,
};
