<template>
  <AppView padd-bottom>
    <TopNav
      title="Task"
      title-class="font-title lg-app:ml-body tw-font-bold"
      :shrink-title="false"
      center-title
      type="back"
      show-back-button
      slot="top-app-view"
    />
    <div class="mx-body tw-mb-8 tw-h-full">
      <transition name="new" mode="out-in" appear>
        <div v-if="error">
          <p class="tw-text-center tw-text-base opacity-78 tw-my-20" v-html="error"></p>
        </div>
        <div v-else-if="loading">
          <p class="tw-text-center tw-text-xs opacity-54 tw-my-20">Loading...</p>
        </div>

        <div v-else class="tw-max-w-md tw-mx-auto">
          <TaskDetails class="tw--mx-6" :task="task" :show-action-buttons="false" />
          <transition name="new" mode="out-in">
            <div class="tw-mt-8" v-if="taskResources.length > 0">
              <div class="tw-flex tw-items-center tw-justify-between tw-relative">
                <h6 class="tw-text-sm">Task Resources</h6>
                <div class="tw-flex tw-justify-end tw-items-center">
                  <h6 class="tw-text-sm opacity-87 tw-font-bold leading-17 tw-mr-6">{{ taskResources.length || 0 }}</h6>
                  <!-- <BaseButton
                    icon
                    icon-right
                    text="Download All"
                    class="active:tw-bg-gray-300 focus:tw-bg-gray-300 hover:tw-bg-gray-300"
                    @click="downloadAllResources(taskResources)"
                  >
                    <svg class="tw-w-6 tw-h-6 my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                      y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                        M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                        c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                        C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                    </svg>
                  </BaseButton> -->
                </div>
              </div>
              <div class="divider tw-border-t tw-border-black opacity-11 tw-my-3 tw-w-full"></div>
              <ViewMore
                class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-4"
                :limit="4"
                :list="taskResources"
                v-slot="{ item: resource }"
              >
                <div class="info-card tw-shadow-app tw-relative">
                  <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.name || resource.title || resource.data || resource.resource }}</h4>
                  <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                    Uploaded {{ resource['createAt'] || resource['Date of Post'] || resource['dateOfPost'] | moment('calendar') }}
                  </h6>
                  <div class="tw-flex tw-items-end">
                    <p v-if="resource.resource_stat  == 1" class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                      Link
                    </p>
                    <p v-else class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                      {{ resourceType(resource.resource, true) }}
                    </p>
                    <a
                      v-if="resource.resource_stat == 1"
                      title="view/read"
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                      :href="resource.resource"
                      target="_blank"
                    >
                      <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </a>

                    <div class="tw-ml-auto tw-inline-flex tw-items-center" v-else>
                      <router-link
                        tag="a"
                        :to="{
                          name: 'ResourceView',
                          query: {
                            doc: `${apiUrl}/${resource.resource}`,
                            title: resource.name,
                          },
                        }"
                        title="view/read"
                        class="tw-mr-2 tw-mb-2"
                      >
                        <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                            c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                            c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                            c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                            S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                            C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                        </svg>
                      </router-link>
                      <button
                        class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                        @click.stop="downloadResource(resource.resource, resource.name)"
                      >
                        <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                            M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                            c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                            C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                        </svg>
                      </button>
                    </div>

                  </div>
                </div>
              </ViewMore>
            </div>
          </transition>

          <transition name="new" mode="out-in">
            <div class="tw-mt-4" v-if="revertedResources.length > 0">
              <div class="tw-flex tw-items-center tw-justify-between tw-relative">
                <h6 class="tw-text-base tw-text-app-primary-orange" style="max-width: 75%;">Mentor reverted this task with some resources</h6>
                <div class="tw-flex tw-justify-end tw-items-center">
                  <h6 class="tw-text-sm opacity-87 tw-font-bold leading-17 tw-mr-6">{{ revertedResources.length || 0 }}</h6>
                  <!-- <BaseButton
                    icon
                    icon-right
                    text="Download All"
                    class="active:tw-bg-gray-300 focus:tw-bg-gray-300 hover:tw-bg-gray-300"
                    @click="downloadAllResources(taskResources)"
                  >
                    <svg class="tw-w-6 tw-h-6 my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                      y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                        M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                        c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                        C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                    </svg>
                  </BaseButton> -->
                </div>
              </div>
              <div class="divider tw-border-t tw-border-black opacity-11 tw-my-3 tw-w-full"></div>
              <ViewMore
                class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-4"
                :limit="4"
                :list="revertedResources"
                v-slot="{ item: resource }"
              >
                <div class="info-card tw-shadow-app tw-relative">
                  <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.title || resource.name || resource.data || resource.resource }}</h4>
                  <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                    Uploaded {{ resource['createAt'] || resource['Date of Post'] || resource['dateOfPost'] | moment('calendar') }}
                  </h6>
                  <div class="tw-flex tw-items-end">
                    <p v-if="resource.title && !resource.file_type" class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                      Link
                    </p>
                    <p v-else class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                      {{ resourceType(resource.file_type || resource.resource, !resource.file_type ? true : false) }}
                    </p>
                    <a
                      v-if="resource.title && !resource.file_type"
                      title="view/read"
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                      :href="resource.resource"
                      target="_blank"
                    >
                      <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </a>

                    <div class="tw-ml-auto tw-inline-flex tw-items-center" v-else>
                      <router-link
                        tag="a"
                        :to="{
                          name: 'ResourceView',
                          query: {
                            doc: `${apiUrl}/${resource.data || resource.resource}`,
                            title: resource.name || resource.data || resource.resource,
                          },
                        }"
                        title="view/read"
                        class="tw-mr-2 tw-mb-2"
                      >
                        <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                            c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                            c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                            c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                            S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                            C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                        </svg>
                      </router-link>
                      <button
                        class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                        @click.stop="downloadResource(resource.data || resource.resource, resource.name || resource.data || resource.resource)"
                      >
                        <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                            M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                            c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                            C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                        </svg>
                      </button>
                    </div>

                  </div>
                </div>
              </ViewMore>
            </div>
          </transition>

          <transition name="new" mode="out-in">
            <div class="tw-mt-4" v-if="revertedTexts.length > 0">
              <h6 class="tw-text-base tw-text-app-primary-orange" style="max-width: 75%;">Mentor reverted this task with some comments</h6>
              <ol type="1" class="tw-list-decimal tw-pl-4">
                <li
                  v-for="revertedText in revertedTexts"
                  :key="revertedText.id"
                  class="tw-text-base tw-mt-4 tw-border-b tw-border-gray-300"
                >
                  <span v-html="revertedText.review"></span>
                  <!-- <br /> -->
                  <span class="tw-text-xs tw-pb-2 opacity-31">
                    {{ revertedText.dateOfPost | toJsDate(true) | moment('calendar') }}
                  </span>
                </li>
              </ol>
            </div>
          </transition>

          <transition name="new" mode="out-in">
            <div class="tw-mt-4" v-if="taskReportResources.length > 0">
              <div class="tw-flex tw-items-center tw-justify-between tw-relative">
                <h6 class="tw-text-base tw-text-app-primary-orange" style="max-width: 75%;">Mentee's reports on this task</h6>
                <div class="tw-flex tw-justify-end tw-items-center">
                  <h6 class="tw-text-sm opacity-87 tw-font-bold leading-17 tw-mr-6">{{ taskReportResources.length || 0 }}</h6>
                  <!-- <BaseButton
                    icon
                    icon-right
                    text="Download All"
                    class="active:tw-bg-gray-300 focus:tw-bg-gray-300 hover:tw-bg-gray-300"
                    @click="downloadAllResources(taskResources)"
                  >
                    <svg class="tw-w-6 tw-h-6 my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                      y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                        M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                        c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                        C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                    </svg>
                  </BaseButton> -->
                </div>
              </div>
              <div class="divider tw-border-t tw-border-black opacity-11 tw-my-3 tw-w-full"></div>
              <ViewMore
                class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-4"
                :limit="4"
                :list="taskReportResources"
                v-slot="{ item: resource }"
              >
                <div class="info-card tw-shadow-app tw-relative">
                  <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.title || resource.name || resource.data || resource.resource }}</h4>
                  <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                    Uploaded {{ resource['createAt'] || resource['Date of Post'] || resource['dateOfPost'] | moment('calendar') }}
                  </h6>
                  <div class="tw-flex tw-items-end">
                    <p v-if="resource.title && !resource.file_type" class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                      Link
                    </p>
                    <p v-else class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                      {{ resourceType(resource.file_type || resource.resource, !resource.file_type ? true : false) }}
                    </p>
                    <a
                      v-if="resource.title && !resource.file_type"
                      title="view/read"
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                      :href="resource.resource"
                      target="_blank"
                    >
                      <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </a>

                    <div class="tw-ml-auto tw-inline-flex tw-items-center" v-else>
                      <router-link
                        tag="a"
                        :to="{
                          name: 'ResourceView',
                          query: {
                            doc: `${apiUrl}/${resource.data || resource.resource}`,
                            title: resource.name || resource.data || resource.resource,
                          },
                        }"
                        title="view/read"
                        class="tw-mr-2 tw-mb-2"
                      >
                        <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                            c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                            c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                            c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                            S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                            C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                        </svg>
                      </router-link>
                      <button
                        class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current"
                        @click.stop="downloadResource(resource.data || resource.resource, resource.name || resource.data || resource.resource)"
                      >
                        <svg class="tw-w-6 tw-h-6 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                            M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                            c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                            C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                        </svg>
                      </button>
                    </div>

                  </div>
                </div>
              </ViewMore>
            </div>
          </transition>

          <transition name="new" mode="out-in">
            <div class="tw-mt-4" v-if="taskReportTexts.length > 0">
              <h6 class="tw-text-base tw-text-app-primary-orange" style="max-width: 75%;">Mentee's task report texts</h6>
              <ol type="1" class="tw-list-decimal tw-pl-4">
                <li
                  v-for="reportText in taskReportTexts"
                  :key="reportText.id"
                  class="tw-text-base tw-mt-4 tw-border-b tw-border-gray-300"
                >
                  <span v-html="reportText.review"></span>
                  <!-- <br /> -->
                  <span class="tw-text-xs tw-pb-2 opacity-31">
                    {{ reportText.dateOfPost | toJsDate(true) | moment('calendar') }}
                  </span>
                </li>
              </ol>
            </div>
          </transition>
        </div>
      </transition>
    </div>
    <!-- bottom-app-view -->
    <div
      v-if="!loading && !error"
      slot="bottom-app-view"
      class="px-body lg-app:tw-px-4 tw-flex tw-items-center tw-max-w-md tw-mx-auto tw-justify-center"
    >
      <div class="tw-mr-3 tw-flex-grow tw-flex-shrink" v-if="!taskIsCompleted(task.status)">
        <TaskButton
          class="tw-text-center tw-block tw-w-full tw-px-6 tw-py-3 tw-mb-6 tw-mx-auto"
          :task-id="task.id"
          :task-status="task.status"
        />
      </div>
      <div class="">
        <BaseButton
          text="Comment"
          class="tw-text-center box-border tw-text-black hover:bg-black-11 tw-py-3 tw-block box-border tw-mx-auto tw-mb-6"
          icon
          v-if="isMyMentor(task.mentorid)"
          @click="refTaskInChat(isMentee ? task.mentorid : task.menteeid, task)"
        >
          <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M46.1,5.3C35,5.3,25.6,12.4,22,22.2h-1c-0.1,0-0.3,0-0.4,0C9.2,22.2,0,31.5,0,42.8c0,5.8,2.4,11.1,6.5,15V59
              c0,5.3,3.5,7.8,6.7,7.8c1.4,0,3.5-0.4,5.8-2.7l0.8-0.8c0.3,0,0.5,0,0.8,0c6.2,0,11.9-2.7,15.8-7.5l0.7-0.9c2.9,1.1,5.9,1.6,9.1,1.6
              c0.5,0,1.1,0,1.7-0.1l2.9,2.9c2.2,2.2,4.3,2.7,5.8,2.7c3.2,0,6.7-2.5,6.7-7.8v-4.1c5.4-4.8,8.7-11.8,8.7-19.2
              C71.8,16.8,60.3,5.3,46.1,5.3z M20.4,58.2c-0.9,0-1.8-0.1-2.7-0.2l-2.6,2.6c-2.1,2-3.6,1.3-3.6-1.5v-3.6C7.6,52.5,5,47.9,5,42.8
              c0-8.5,6.9-15.4,15.4-15.4c0.1,0,0.2,0,0.3,0c-0.2,1.1-0.2,2.4-0.2,3.6c0,9,4.7,17,11.9,21.7C29.5,56,25.2,58.2,20.4,58.2z
              M57.9,47.7v6.6c0,2.9-1.6,3.5-3.7,1.5l-4.6-4.6c-1.1,0.2-2.4,0.3-3.6,0.3c-11.3,0-20.5-9.2-20.5-20.5S34.7,10.4,46,10.4
              S66.6,19.7,66.6,31C66.7,37.8,63.3,44,57.9,47.7z"/>
          </svg>
        </BaseButton>
      </div>
    </div>
  </AppView>
</template>

<script>
import TaskButton from '@/components/TaskButton.vue';
import TaskDetails from '@/views/_partials/TaskDetails.vue';
import { apiUrl } from '@/modules/apiHelper';

export default {
  name: 'FullTaskDetails',
  components: {
    TaskButton,
    TaskDetails,
  },
  props: ['id'],
  data() {
    return {
      loading: true,
      error: undefined,
      task: {},
      taskResources: [],
      revertedResources: [],
      revertedTexts: [],
      taskReportResources: [],
      taskReportTexts: [],
      apiUrl,
    };
  },
  methods: {
    isMyMentor(mentorId) {
      if (this.isMentee) {
        // eslint-disable-next-line eqeqeq
        return !!this.$store.state.user.mentee.mentors.find((mentor) => mentor?.user.id == mentorId);
      }

      return true;
    },
    async getTask() {
      const response = await this.$store.dispatch('getTask', [this.id, true])
        .catch(() => {
          this.error = 'Error in getting task';
          this.$toasted.global.appError({ errorMessage: this.error });
          return false;
        })
        .then((task) => {
          if (!task) return false;
          this.error = undefined;
          this.loading = false;

          this.task = task;

          return true;
        });

      if (!response) return;

      // get mentor task resources
      this.taskResources = await this.$store.dispatch('taskResources', this.id)
        .catch(() => {
          this.$toasted.global.appError({ errorMessage: 'Error in getting task resources' });
          return [];
        })
        .then((resources) => resources || []);
      //

      // get reverted resources
      this.revertedResources = await this.$store.dispatch('revertedTaskResources', this.id)
        .catch(() => {
          this.$toasted.global.appError({ errorMessage: 'Error in getting task resources' });
          return [];
        })
        .then((resources) => resources || []);
      //

      // get reverted resources
      this.revertedTexts = await this.$store.dispatch('revertedTaskTexts', this.id)
        .catch(() => {
          this.$toasted.global.appError({ errorMessage: 'Error in getting task reverted text' });
          return [];
        })
        .then((resources) => resources || []);
      //

      // mentee tasks reports
      this.taskReportResources = await this.$store.dispatch('taskReportResources', this.id)
        .catch(() => {
          this.$toasted.global.appError({ errorMessage: 'Error in getting task report resources' });
        })
        .then((resources) => resources || []);
      //

      this.taskReportTexts = await this.$store.dispatch('taskReportTexts', this.id)
        .catch(() => {
          this.$toasted.global.appError({ errorMessage: 'Error in getting task report' });
        })
        .then((texts) => texts || []);
    },
    async getSequentialTask() {
      const response = await this.$store.dispatch('getSequentialTask', [this.id, true])
        .catch(() => {
          this.error = 'Error in getting task';
          this.$toasted.global.appError({ errorMessage: this.error });
          return false;
        })
        .then((task) => {
          if (!task) return false;
          this.error = undefined;
          this.loading = false;

          this.task = task;

          return true;
        });

      if (!response) return;

      // get mentor task resources
      this.taskResources = await this.$store.dispatch('sequentialTaskResources', this.id)
        .catch(() => {
          this.$toasted.global.appError({ errorMessage: 'Error in getting task resources' });
          return [];
        })
        .then((resources) => resources || []);
      //

      // get reverted resources
      const revertedResources = await this.$store.dispatch('revertedSequentialTaskResources', this.id)
        .catch(() => {
          this.$toasted.global.appError({ errorMessage: 'Error in getting task resources' });
          return [];
        })
        .then((resources) => resources || []);
      //

      this.revertedResources = revertedResources.filter((resource) => resource.type === 'resource');
      this.revertedTexts = revertedResources.filter((resource) => resource.type === 'text');

      // mentee tasks reports
      const taskReportResources = await this.$store.dispatch('sequentialTaskReportResources', this.id)
        .catch(() => {
          this.$toasted.global.appError({ errorMessage: 'Error in getting task report resources' });
          return [];
        })
        .then((resources) => resources || []);
      //

      this.taskReportResources = taskReportResources.filter((resource) => resource.type === 'resource');
      this.taskReportTexts = taskReportResources.filter((resource) => resource.type === 'text');
    },
  },
  async created() {
    this.$store.commit('isFlatView', true);
    this.$store.commit('closeRightSideNav');
    this.$store.commit('showNotifications', false);
    this.$store.commit('showTaskDetails', false);
    this.error = undefined;
    this.loading = true;

    const isPermitted = await this.assertTask(this.id);
    if (!isPermitted) return false;

    if (this.isSequentialProgram) {
      await this.getSequentialTask();
    } else {
      await this.getTask();
    }

    return true;
  },
};
</script>

<style>

</style>
