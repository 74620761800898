/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import { apiPost, generateFormData, apiStore } from '../../../modules/apiHelper';
import {
  toBoolean, isStaleData, now,
} from '../../../modules/dataHelper';

export default {
  async getSequentialTask(context, [taskid, refresh = false]) {
    const localDb = await apiStore.getItem(`task/${taskid}`) || [0, undefined];

    if (localDb[1] && !isStaleData(localDb[0]) && !refresh) return localDb[1];

    const goals = await context.dispatch('getProgramModules', []);

    const formData = new FormData();
    formData.set('taskid', taskid);

    const task = await apiPost('prog_tasks_dt', formData, 70)
      .then((res) => {
        // console.log(res);
        if (!res) return {};
        if (toBoolean(res.data.error)) return {};

        const tsk = res.data.prog_tasks_dt[0];

        if (tsk) {
          // get tasks' goals
          const taskGoal = goals.find((goal) => goal.id == tsk.goalid);

          tsk.goal = taskGoal || {};

          apiStore.setItem(`task/${taskid}`, [now(), tsk]);
        }

        return tsk || {};
      });

    //
    return task;
  },

  // ? How to cache the tasks
  async sequentialTasksList(context, [mentorid, menteeid, goalid]) {
    const formData = generateFormData({
      mentorid,
      menteeid,
      goalid,
    });

    const goals = await context.dispatch('getProgramModules', []);
    const allGoals = goals.flat();

    const response = await apiPost('prog_task_list_match', formData, 70)
      .catch((err) => {
        console.warn('tasksList failed! ', err);
      })
      .then((res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        const tasks = res.data.prog_task_list_match;

        const mappedTasks = tasks.map((task) => {
          const taskGoal = allGoals.find((goal) => goal.id == task.goalid);

          return {
            ...task,
            goal: taskGoal || {},
          };
        });

        return mappedTasks;
      });

    return response;
  },
};
