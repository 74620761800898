import authStoreModule from '@/store/auth';

export default {
  name: 'scopeMixin',
  computed: {
    isKengen() {
      return this.$store.state.scope.isKengen;
    },
    isFellowship() {
      return this.$store.state.scope.isFellowship;
    },
    isGoalOrientedProgram() {
      return this.$store.getters.isGoalOrientedProgram;
    },
    isSequentialProgram() {
      return this.$store.getters.isSequentialProgram;
    },
  },
};
