<template>
  <div class="tw-relative">
    <template v-if="isKengen">
      <KengenLogin />
    </template>
    <template v-else-if="isFellowship">
      <FellowshipLogin />
    </template>
    <template v-else>
      <AnyLogin />
    </template>
  </div>
</template>

<script>
import KengenLogin from '@/views/_companies/kengen/Login.vue';
import FellowshipLogin from '@/views/_companies/fellowship/Login.vue';
import AnyLogin from '@/views/_companies/any/Login.vue';

export default {
  name: 'Login',
  components: {
    KengenLogin,
    FellowshipLogin,
    AnyLogin,
  },
};
</script>

<style>

</style>
