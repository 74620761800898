export default function updateProp(state, [prop, value, prop2]) {
  if (prop2) {
    if (Object.prototype.hasOwnProperty.call(state, prop)) {
      state[prop][prop2] = value;
    } else {
      console.warn('error::updateProp', prop, '-', value, '-', prop2);
    }
  } else {
    state[prop] = value;
  }
}
