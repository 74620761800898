import { apiPost } from '../../../modules/apiHelper';
// import { urlFriendly } from '../../../modules/dataHelper';

/**
 *
 * @param {Number} senderid admin's id
 * @param {Number} userid specific
 * @param {*} to
 * @param {*} file1
 * @param {*} resource_type
 */
// eslint-disable-next-line camelcase
// export default function (senderid, userid, to, file1, resource_type = 0) {
export default function shareResource(endpoint, formData, path) {
  // if (!Array.isArray(to) && !userid) {
  //   console.warn('parameter to is not an array [required].\n', '[{medium|comp_stat}, {value}]');
  // }

  // const formData = generateFormData({
  //   senderid,
  //   name: file1.name,
  //   resource_type,
  // });

  // formData.append('file1', file1, urlFriendly(file1.name));

  // if (userid) {
  //   formData.set('userid', userid);
  // } else {
  //   formData.set(to[0], to[1]);
  // }

  const percComplete = [0];
  const requestHeaders = { 'Content-Type': 'multipart/form-data' };
  const uploadHandler = (progressEvent) => {
    percComplete[0] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    // console.log(percComplete);
  };

  //
  const request = apiPost(endpoint, formData, path, requestHeaders, uploadHandler)
    .catch((err) => {
      console.warn('shareResource failed!', err);
      return false;
    })
    .then((res) => {
      if (!res) return false;
      if (res.data.error) return false;
      return true;
    });

  //
  return [request, percComplete];
}
