<template>
  <div>
    <div v-if="userPrograms.length === 0">
      <p class="tw-text-center tw-px-6 tw-py-10">
        You have not been enlisted to any program.<br />
        If this is a mistake, kindly contact admin.
      </p>
    </div>

    <template v-else>
      <h4 class="tw-text-center tw-my-5">
        Select Program to login to
      </h4>

      <div class="tw-flex tw-flex-wrap">

        <div v-for="userProgram in userPrograms" :key="userProgram.id" class="tw-w-full md:tw-w-1/2 tw-p-2 tw-cursor-pointer" @click="selectProgram(userProgram)">
          <div class="tw-text-center tw-p-4 tw-border tw-rounded-10 transition-fast hover:bg-black-11">
            <img :src="userPhoto(userProgram.logo)" class="tw-inline-block tw-h-[100px] md:tw-h-[200px] tw-mb-5 tw-object-contain" />

            <h4 class="tw-text-app-primary-orange font-title">
              {{ userProgram.name }}
            </h4>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UserProgramsLoginOptions',
  props: {
    userPrograms: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectProgram(program) {
      this.$emit('program-selected', program);
    },
  },
};
</script>

<style>

</style>
