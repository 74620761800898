import actions from './actions/index';

export default {
  state: {
    tasks: [],
    hasGroupTasks: false,
  },
  getters: {
    getTask: (state) => (id) => state.tasks.find((t) => t.id === id),
  },
  mutations: {
    addTask(state, task) {
      if (!state.tasks.find((m) => m.id === task.id)) {
        state.tasks.unshift(task);
      } else {
        const index = state.tasks.findIndex((t) => t.id === task.id);

        if (index >= 0) {
          state.tasks[index] = { ...state.tasks[index], ...task };
        }
      }
    },
    updateTask(state, { id, prop, value }) {
      const index = state.tasks.findIndex((t) => t.id === id);

      if (index >= 0) {
        // console.log('updating mentor ', prop, state.mentors.find(m => m.id === id));
        state.tasks[index][prop] = value;
      }
    },
    updateHasGroupTasks(state, has) {
      state.hasGroupTasks = has;
    },
  },
  actions,
};
