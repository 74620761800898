/* eslint-disable eqeqeq */
import _orderBy from 'lodash/orderBy';
import resolve from '../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../modules/apiHelper';
import { invalideDate, isStaleData, now } from '../../modules/dataHelper';

const matchEndedSurveyType = 1;

export default {
  state: {
    surveyTypes: [
      {
        id: 0,
        type: 'Not set',
      },
      {
        id: 1,
        type: 'When a mentorship session ends',
      },
    ],
  },
  actions: {
    // gets the survey, the survey questions and the questions' ratings
    // todo: improve this to filter by match's goal id
    async getMatchEndedSurvey(context, getSurveyQuestions = false) {
      const { companyid } = context.rootState.user;

      const companySurvey = await resolve(apiPost('survey_pillar_log', generateFormData({ companyid }), 37))
        .then((data) => {
          if (!data) return false;

          return (data.survey_pillar_log || []).find((survey) => Number(survey.type) == matchEndedSurveyType);
        });
      //

      if (!getSurveyQuestions) return companySurvey;
      if (!companySurvey) return false;

      // get survey questions
      const companySurveyQuestions = await resolve(apiPost('survey_log', generateFormData({ companyid }), 39))
        .then((data) => {
          if (!data) return false;

          return (data.survey_log || []).filter((question) => Number(question.pillarid) === Number(companySurvey.id));
        });
      //

      if (!companySurveyQuestions) return false;

      // get questions ratings
      const questionsRatings = await resolve(apiPost('ratings_log', generateFormData({ companyid }), 39))
        .then((data) => {
          if (!data) return false;

          return data.ratings_log;
        });
      //

      if (!questionsRatings) return false;

      // map ratings to their questions
      const mappedQuestions = _orderBy(companySurveyQuestions.map((question) => {
        const questionRatings = questionsRatings.filter((que) => que.suvid == question.id);

        return {
          ...question,
          ratings: _orderBy(questionRatings, (r) => r.dateOfPost),
        };
      }), (q) => q.dateOfPost);

      return {
        ...companySurvey,
        questions: mappedQuestions,
      };
    },
    getMatchEndedSurveyUserResponse(context) {
      // const userid = context.rootState.user.id;
      // const { companyid } = context.rootState.user;

      console.log(context);


      return {};
    },
    async saveUserSurveyResponse(context, form) {
      const userid = context.rootState.user.id;
      const {
        programid,
      } = context.rootState.user;

      const promises = [];

      const sendResponse = async (userResponse, isLastResponse = false) => {
        const formData = generateFormData({
          qid: userResponse.questionId,
          progid: programid,
          userid,
        });

        if (userResponse.questionType === 'Text') {
          formData.set('response', userResponse.rating);
        } else {
          formData.set('rateid', userResponse.rating);
        }

        if (userResponse.questionType === 'Choices') {
          if (isLastResponse) {
            formData.set('q', 1);
          }

          const choicesPromises = [];

          // loop through the ratings and set the form data
          Object.entries(userResponse.rating).forEach(([ratingId, isSelected]) => {
            if (!isSelected) return;
            const choicesFormData = generateFormData({
              qid: userResponse.questionId,
              progid: programid,
              userid,
              rateid: ratingId,
            });

            choicesPromises.push(resolve(apiPost('prog_sub_suv', choicesFormData, 72)));
          });

          return Promise.all(choicesPromises);
        }

        if (isLastResponse) {
          formData.set('q', 1);
        }

        const result = await resolve(apiPost('prog_sub_suv', formData, 72));

        return result;
      };

      form.forEach((formItem, index) => {
        promises.push(sendResponse(formItem, index == (form.length - 1)));
      });

      const result = await Promise.all(promises)
        .then((prom) => prom.every((p) => !!p));
      //

      return result;
    },
    async userCanTakeSurvey(context) {
      const userid = context.rootState.user.id;
      const { companyid } = context.rootState.user;

      const eligibleUsers = await resolve(apiPost('survey_user_pending_log', generateFormData({ companyid }), 42));

      if (!eligibleUsers) {
        return false;
      }

      return !!eligibleUsers.survey_user_pending_log.find((user) => user.userid == userid);
    },
    async getSurveyQuestionTypes(context, refresh = false) {
      const { programid } = context.rootState.user;
      if (!programid) return false;

      const surveyQuestionTypes = await apiStore.getItem(`surveyQuestionTypes/${programid}`) || [invalideDate, []];

      if (!refresh && !isStaleData(surveyQuestionTypes[0]) && surveyQuestionTypes[1].length) return surveyQuestionTypes[1];

      const result = await resolve(apiPost('prog_suv_q_type_log', generateFormData({
        progid: programid,
      }), 72))
        .then((data) => {
          if (!data) return false;

          const log = data.prog_suv_q_type_log || [];

          if (log.length) {
            apiStore.setItem(`surveyQuestionTypes/${programid}`, [now(), log]);
          }

          return log;
        });
      //

      return result;
    },

    async getSurveyTypes(context, refresh = false) {
      const { programid } = context.rootState.user;

      const surveyTypes = await apiStore.getItem(`surveyTypes/${programid}`) || [invalideDate, []];

      if (!refresh && !isStaleData(surveyTypes[0]) && surveyTypes[1].length) return surveyTypes[1];

      const result = await resolve(apiPost('prog_suv_type_log', generateFormData({ progid: programid }), 72))
        .then((data) => {
          if (!data) return false;

          return data.prog_suv_type_log || [];
        });
      //

      return result;
    },

    async getSurveyQuestions(context) {
      const response = await resolve(apiPost('prog_suv_checker', generateFormData({
        userid: context.rootGetters.userId,
      }), 72));

      if (!response) return false;

      // 0 means there is no pending survey
      if (Number(response.prog_suv_checker) === 0) {
        return false;
      }

      const surveyType = response.type;

      const surveyQuestionTypes = await context.dispatch('getSurveyQuestionTypes');
      const surveyTypes = await context.dispatch('getSurveyTypes');
      const selectedSurveyType = surveyTypes.find((type) => type.types === surveyType);

      const result = await resolve(apiPost('prog_suv_q_t_log', generateFormData({
        typeid: selectedSurveyType.id,
      }), 72))
        .then(async (resQuestions) => {
          if (!resQuestions) return false;

          // console.log('resQuestions', resQuestions);

          // Get ratings for each question
          const ratingsPromises = [];
          (resQuestions.prog_suv_q_t_log || []).forEach((question) => {
            const surveyQuestionType = (surveyQuestionTypes || []).find((type) => Number(type.id) === Number(question.q_type));

            ratingsPromises.push(
              context.dispatch('getSurveyQuestionRatings', [question.id, true])
                .then((ratings) => ({
                  ...question,
                  type: surveyQuestionType,
                  surveyType: selectedSurveyType,
                  ratings: ratings || [],
                })),
            );
          });

          const surveyQuestionsWithRatings = await Promise.all(ratingsPromises);

          return surveyQuestionsWithRatings;
        });
      //

      return result;
    },

    async getSurveyQuestionRatings(context, [questionId, refresh = false]) {
      const questionRatings = await apiStore.getItem(`questionRatings/${questionId}`) || [invalideDate, []];

      if (!refresh && !isStaleData(questionRatings[0]) && questionRatings[1].length) return questionRatings[1];

      const result = await resolve(apiPost('prog_suv_ratings_log', generateFormData({
        qid: questionId,
      }), 72))
        .then((data) => {
          if (!data) return false;

          const programSurveyQuestionRatings = (data.prog_suv_ratings_log || []);

          if (programSurveyQuestionRatings.length) {
            apiStore.setItem(`questionRatings/${questionId}`, [now(), programSurveyQuestionRatings]);
          }

          return programSurveyQuestionRatings;
        });
      //

      return result;
    },
  },
};
