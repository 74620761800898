import {
  whichBuild, apiStore, apiGet, apiPost, generateFormData,
} from '../../modules/apiHelper';
import { now, isStaleData, objectIsValid } from '../../modules/dataHelper';
import resolve from '../../modules/api/resolve';

export default {
  state: {
    scopeId: 0,
    isKengen: process.env.VUE_APP_COMPANY_NAME === 'kengen',
    isFellowship: process.env.VUE_APP_COMPANY_NAME === 'fellowship',
    programType: undefined,
    programTypes: {
      goalOriented: 1,
      sequential: 2,
    },
  },
  getters: {
    scopeId(state) {
      return state.scopeId;
    },
    isGoalOrientedProgram(state) {
      if (!state.programType) return true;
      return state.programType === state.programTypes.goalOriented;
    },
    isSequentialProgram(state) {
      if (!state.programType) return false;
      return state.programType === state.programTypes.sequential;
    },
  },
  mutations: {
    setScope(state, id) {
      if (!id) return;
      state.scopeId = id;
    },
    setProgramType(state, programType) {
      if (!programType) return;
      state.programType = Number(programType);
    },
  },
  actions: {
    /**
     *
     * @param {Object} context $store context
     * @returns {Array} *
     * @ {Boolean} isValidScope if url is a valid scope whether it's the main app or a company url (domain url)
     * @ {Boolean} isMainApp if url is ementored.com
     * @ {Boolean} isDevBuild checks if url is a developer scope
     * @ {Number} scopeId
     *
     * *
     */
    async evaluateScope(context) {
      await context.dispatch('setScope'); // first set scope by guessing, using the url

      if (process.env.NODE_ENV === 'development') {
        return [true, false, true, context.state.scopeId];
      }
      if (whichBuild(false)) return [true, true, true, context.state.scopeId];


      // * evaluates the current url
      const curr = window.location.host; // app.ementored.com

      const mainAppRegexTest = new RegExp(process.env.VUE_APP_MAIN_APP_URL);
      const isMainApp = mainAppRegexTest.test(curr);

      const scopeRegexTest = new RegExp(process.env.VUE_APP_SCOPE_URL);
      const isValidScope = scopeRegexTest.test(curr);


      if (isValidScope && context.rootState.user.companyid) {
        context.commit('setScope', context.rootGetters.userCompanyId);
      }

      return [isValidScope, isMainApp, false, context.getters.scopeId];
      // return [true, false, false];
    },

    // --------------------------------------------------------------------

    // more like guess the scope of the app
    async setScope(context) {
      const curr = window.location.host;

      await context.dispatch('getCompanies').then((scopes) => {
        if (!Array.isArray(scopes)) return;
        let scope;

        if (process.env.NODE_ENV === 'development' || whichBuild(false)) {
          scope = scopes.find(({ id }) => Number(id) === Number(process.env.VUE_APP_DEFAULT_COMPANY_ID_PRODUCTION));
        } else {
          // find by the website url
          scope = scopes.find(({ id, website }) => (website.split('www.').pop().split('://').pop() === curr ? (id || Number(process.env.VUE_APP_DEFAULT_COMPANY_ID_PRODUCTION)) : undefined));
        }

        context.commit('setScope', (scope || {}).id);
      });
    },

    // --------------------------------------------------------------------

    async getCompanies() {
      const request = resolve(apiGet('company', 6, null, true));

      // wait for the xhr
      const result = await request;
      return (result || {}).company || false;
    },

    // --------------------------------------------------------------------

    async getCompany(context, [CompanyId, refresh = false]) {
      const companyId = CompanyId
        || context.rootGetters.userCompanyId
        || (process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEFAULT_COMPANY_ID : process.env.VUE_APP_DEFAULT_COMPANY_ID_PRODUCTION);
      //
      if (Number.isNaN(Number(companyId))) return false;

      // eslint-disable-next-line no-underscore-dangle
      const _company = await apiStore.getItem(`company/${companyId}`) || [0, {}];

      if (!refresh && !isStaleData(_company[0]) && objectIsValid(_company[1])) return _company[1];

      const companies = await context.dispatch('getCompanies', refresh);

      const company = Array.isArray(companies)
        ? companies.find(({ id }) => Number(companyId) === Number(id)) || false
        : false;

      if (!company) return false;

      await apiStore.setItem(`company/${companyId}`, [now(), company]);

      return company;
    },

    // --------------------------------------------------------------------

    async getProgram(context, [ProgramId, refresh = false]) {
      const programId = ProgramId || context.rootGetters.userProgramId;

      if (Number.isNaN(Number(programId))) return false;

      const cachedProgram = await apiStore.getItem(`program/${programId}`) || [0, {}];

      if (!refresh && !isStaleData(cachedProgram[0]) && objectIsValid(cachedProgram[1])) {
        context.commit('setProgramType', cachedProgram[1].type);
        return cachedProgram[1];
      }

      const program = await resolve(apiPost('prog', generateFormData({ progid: programId }), 63));
      const theProgram = program?.prog[0];

      if (!theProgram) return false;

      await apiStore.setItem(`program/${programId}`, [now(), theProgram]);

      context.commit('setProgramType', theProgram.type);

      return theProgram;
    },
  },
};
