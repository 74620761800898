
const openAppRoutes = async () => [{
  path: '/public/video-meeting/:linkid',
  name: 'publicVideoMeeting',
  component: () => import(/* webpackChunkName: "public" */ '../views/Open/VideoMeeting.vue'),
  props: true,
},
{
  path: '/public/expert-questions/:linkid',
  name: 'publicExpertPage',
  component: () => import(/* webpackChunkName: "public" */ '../views/Open/ExpertQuestions.vue'),
  props: true,
}];

export default openAppRoutes;
