import form from '../modules/formHelper';

export default {
  name: 'chatMixin',
  data() {
    return {
      openAttachmentMenu: false,
      form: {
        attachment: undefined, // File || Object
        text: '',
        attachmentId: undefined,
        attachmentType: undefined,
      },
      userId: this.id || this.$route.params.id,
      loading: true,
      sending: false,
      disabled: true,
      textareaScrollHeight: 'auto',
      focusMode: false,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.text': async function (val) {
      this.$emit('input', val);

      this.textareaScrollHeight = 'auto';

      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();

      if (this.$refs['text-input-el']) {
        const currentTextareaHeight = `${this.$refs['text-input-el']?.scrollHeight || 0}px`;
        this.textareaScrollHeight = currentTextareaHeight;
      }

      if (form.isValidTextInput(val)) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
};
