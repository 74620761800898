<template>
  <AppView
    body-class="tw-flex tw-flex-col"
    padd-bottom
  >
    <TopNav
      slot="top-app-view"
      :class="{ 'tw-shadow-app' : isMobileView }"
      title="Group Chat Arena"
      center-title
      title-class="lg:ml-body tw-tracking-wide font-title tw-font-bold"
      :show-user-photo="false"
      @input="openSideNav()"
    />
    <div class="tw-h-full tw-w-full tw-flex tw-flex-col tw-flex-grow lg-grid">
    <div class="lg-grid__col-2/3"></div>
    <div
      :class="[
        'tw-flex-grow tw-overflow-auto tw-w-full tw-h-full tw-pt-8 tw-flex tw-justify-end tw-flex-col transition-fast lg-grid__col-2/3'
      ]"
      ref="chat-body"
      v-observe-scrollheight="scrollDown"
    >
      <div v-if="loading">
        <p class="tw-text-xs tw-text-center tw-py-10 opacity-54">Getting your messages. Please wait..</p>
      </div>
      <transition
        name="new"
        mode="out-in"
        tag="span"
        v-else-if="!hasMessages"
      >
        <div class="transition-slow">
          <!-- GOODIES :: new chat! [Say hi!] -->
          <h4 class="tw-text-right px-body opacity-31 tw-text-base tw-font-bold font-title tw-italic tw-tracking-wide">Say hi!</h4>
        </div>
      </transition>
      <template
        v-for="(message, index) in groupChatMessages"
      >
        <div
          :key="`spacer-${index}`"
          v-if="(groupChatMessages[index - 1] || {}).senderid !== groupChatMessages[index].senderid"
          class="tw-h-4"
        >
        </div>
        <GroupChatBubbleText
          :key="message.id"
          :message="message"
          :senderId="message.senderid"
          :status="message.status"
          :attachment="message.resourceid"
          @update="scrollDown()"
          @reply="setReplyToMessage"
        />
      </template>
    </div>

    <portal to="bottom-app-view" slim>
      <div class="lg-grid">
        <div
          :class="[
            'tw-flex-grow-0 tw-flex-shrink-0 tw-w-full lg-grid__col-2/3'
          ]"
          ref="chat-bottom-bar"
        >
          <!-- chat action bar -->
          <transition name="new" mode="out-in" appear>
            <span class="tw-block tw-w-full tw-py-1 tw-text-xs tw-text-center tw-text-red-500" v-if="fetchingGroupChatError">Error in fetching group chat messages. Please reload page</span>
          </transition>

          <div
            :class="[
              'tw-mb-6 tw-flex tw-flex-nowrap tw-items-end transition-fast',
              { 'mx-body lg:tw-mr-0': !focusMode },
              { 'tw-mx-2 lg:tw-ml-8 lg:tw-mr-0': focusMode },
            ]"
          >
            <div
              class="tw-flex tw-flex-wrap tw-shadow-app tw-p-1 tw-bg-white tw-flex-1 tw-items-end tw-mr-4 tw-relative"
              style="border-radius: 24px;"
            >
              <!-- attach view! -->
              <transition name="fade" mode="out-in">
                <div
                  class="tw-w-full tw-max-w-screen-sm tw-m-1 tw-py-4 tw-px-6 tw-bg-gray-300"
                  style="border-radius: 24px;"
                  v-if="hasAttached && form.attachment"
                >
                  <div class="tw-flex tw-items-center tw-justify-between">
                    <div>
                      <p class="tw-text-xs opacity-31 tw-uppercase" v-if="(form.attachment.chat_message || '').length">{{form.attachment.name}}</p>
                      <p class="tw-text-xs opacity-31 tw-uppercase" v-else>Resource</p>
                      <h4 class="tw-font-bold tw-text-sm leading-17 opacity-54 tw-break-all">
                        {{ form.attachment.chat_message || form.attachment.title || form.attachment.name || form.attachment.data }}
                      </h4>
                    </div>
                    <button
                      class="tw-flex-shrink-0 tw-p-1 opacity-54"
                      @click="clearAttachment()"
                    >
                      <svg version="1.1" class="tw-w-5 tw-h-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
                          L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
                          s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
                      </svg>
                    </button>
                  </div>
                  <!-- <p class="tw-text-xs opacity-31 tw-uppercase" v-else-if="isResourceRef">Resource</p>
                  <h4 class="tw-font-bold tw-text-sm leading-17 opacity-54" v-else-if="isResourceRef">
                    {{ form.attachment.name || form.attachment.data }}
                  </h4> -->

                </div>
              </transition>
              <!-- attachment menu -->
              <transition mode="out-in" name="fade">
                <div
                  v-show="openAttachmentMenu"
                  class="tw-absolute tw-pb-4 tw-z-40"
                  style="bottom: 100%;"
                >
                  <div class="tw-mb-2">
                    <div
                      class="tw-flex tw-items-center tw-cursor-pointer"
                      @click="attachResource()"
                    >
                      <span class="tw-p-3 tw-bg-white tw-shadow-app tw-rounded-full">
                        <svg  class="tw-w-6 tw-h-6"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M62.1,14h-8.8c-0.8,0-1.6-0.6-1.6-1.6V2.6C52,1.2,50.8,0,49.4,0H12.1c-2.8,0-5,2.2-5,5v62c0,2.8,2.2,5,5,5h47.9
                            c2.8,0,5-2.2,5-5V16.6C64.9,15.2,63.7,14,62.1,14z M60.9,66.8c0,0.6-0.4,1-1,1H12.1c-0.6,0-1-0.4-1-1V5c0-0.6,0.6-1,1-1H48v8.4
                            c0,3,2.4,5.6,5.6,5.6h7.4V66.8z M51,29.9c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2s0.8-2,2-2H49C50.2,27.9,51,28.7,51,29.9z M51,41.9
                            c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2c0-1.2,0.8-2,2-2H49C50.2,39.9,51,40.7,51,41.9z M51,53.9c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2
                            s0.8-2,2-2H49C50.2,51.9,51,52.7,51,53.9z"/>
                        </svg>
                      </span>
                      <span class="tw-uppercase tw-mx-2">RESOURCES</span>
                    </div>
                  </div>
                  <!-- <div class="">
                    <button
                      class="tw-flex tw-items-center"
                      @click="attachTask()"
                    >
                      <span class="tw-p-3 tw-bg-white tw-shadow-app tw-rounded-full">
                        <svg class="tw-w-6 tw-h-6"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <g>
                            <path d="M64.4,17.3c0-4.6-3.7-8.2-8.2-8.2h-4.2c-0.7-2.4-3-4.1-5.5-4.1h-3.3C41.5,1,37-1,33,0.6C31,1.4,29.3,3,28.6,5h-3.2
                              c-2.5,0-4.8,1.7-5.5,4.1h-4.2c-4.6,0-8.2,3.7-8.2,8.2v46.5c0,4.6,3.7,8.2,8.2,8.2h40.7c4.6,0,8.2-3.7,8.2-8.2V17.3H64.4z
                              M22.9,10.7c0-1.4,1.1-2.5,2.5-2.5h4.4c0.8,0,1.5-0.5,1.6-1.3c0.5-2.5,2.9-4.1,5.3-3.6c1.8,0.4,3.2,1.8,3.6,3.6
                              c0.2,0.8,0.9,1.3,1.6,1.3h4.5c1.4,0,2.5,1.1,2.5,2.5v1.2c0,1.4-1.1,2.5-2.5,2.5H25.2c-1.4,0-2.5-1.1-2.5-2.5L22.9,10.7z M61.1,63.8
                              c0,2.7-2.2,4.9-4.9,4.9H15.5c-2.7,0-4.9-2.2-4.9-4.9V17.3c0-2.7,2.2-4.9,4.9-4.9h4c0.2,3,2.7,5.4,5.8,5.4h21c3,0,5.6-2.3,5.8-5.4h4
                              c2.7,0,4.9,2.2,4.9,4.9V63.8z"/>
                            <path d="M26.7,28h-7.8c-0.9,0-1.6,0.7-1.6,1.6v7.8c0,0.9,0.7,1.6,1.6,1.6h7.8c0.9,0,1.6-0.7,1.6-1.6v-7.8
                              C28.4,28.7,27.6,28,26.7,28z M25.1,35.8h-4.5v-4.5h4.5V35.8z"/>
                            <path d="M51.9,31.8H36c-0.9,0-1.6,0.7-1.6,1.6S35.1,35,36,35h15.9c0.9,0,1.6-0.7,1.6-1.6S52.8,31.8,51.9,31.8z"/>
                            <path d="M26.7,46.9h-7.8c-0.9,0-1.6,0.7-1.6,1.6l0,0v7.8c0,0.9,0.7,1.6,1.6,1.6h7.8c0.9,0,1.6-0.7,1.6-1.6v-7.8
                              C28.4,47.8,27.6,46.9,26.7,46.9L26.7,46.9z M25.1,54.9h-4.5v-4.5h4.5V54.9z"/>
                            <path d="M51.9,50.9H36c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h15.9c0.9,0,1.6-0.7,1.6-1.6C53.5,51.6,52.8,50.9,51.9,50.9z"
                              />
                          </g>
                        </svg>
                      </span>
                      <span class="tw-uppercase tw-mx-2">TASK</span>
                    </button>
                  </div> -->
                </div>
              </transition>
              <button
                ref="attachment-menu-button"
                :class="[
                  'tw-inline-block tw-relative tw-flex-shrink-0 tw-flex-grow-0 tw-p-2 tw-rounded-full tw-outline-none focus:tw-bg-gray-300 active:tw-bg-gray-300 transition-fast',
                  { 'tw-bg-gray-300': openAttachmentMenu }
                ]"
                @click.prevent.stop="toggleAttachmentMenu()"
                @blur.prevent.stop="openAttachmentMenu = false"
              >
                <svg version="1.1" class="tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M55.9,14.3v11v14.5v11c0,13.3-7.1,21.2-19.8,21.2c-12.9,0-20-7.6-20-21.2V4.4c0-1.4,0.9-2.3,2.3-2.3c1.4,0,2.3,0.9,2.3,2.3
                    v46.5c0,11,5.1,16.6,15.4,16.6c12.7,0,15.2-9,15.2-16.6v-11V25.3v-11c0-4.1-3-9.7-9.7-9.7c-7.1,0-9.7,6-9.7,9.7v17l0,0v15.6
                    c0,1.8,0,4.8,1.4,6.2c0.5,0.7,1.4,0.9,2.5,0.9c1.6,0,2.5-0.2,3.2-1.2c1.4-1.4,1.4-4.1,1.4-6v-0.7V25.3c0-1.4,0.9-2.3,2.3-2.3
                    s2.3,0.9,2.3,2.3v21.6c0,2.5,0,6.7-2.8,9.4c-1.6,1.6-3.7,2.3-6.7,2.3c-2.5,0-4.4-0.7-6-2.3c-2.8-2.8-2.8-6.9-2.5-9.4v-0.5V31.3v-8.7
                    v-8.3C27.1,7.1,32.4,0,41.4,0S55.9,7.1,55.9,14.3z"/>
                </svg>
              </button>
              <!-- textarea -->
              <textarea
                ref="text-input-el"
                rows="1"
                class="leading-24 tw-p-2 tw-text-base tw-flex-1 tw-bg-transparent tw-w-full tw-resize-none transition-fast"
                :style="'height: ' + textareaScrollHeight + '; max-height: 240px;'"
                placeholder="Type a message"
                v-model="form.text"
                @focus.prevent.stop="focusMode = true"
                @blur.prevent.stop="focusMode = false"
              ></textarea>
            </div>
            <!-- send button -->
            <template>
              <BaseButton
                text=""
                icon
                style="line-height: 0; font-size: 0;"
                class="tw-shadow-app tw-bg-app-primary-orange tw-rounded-full tw-text-app-primary-orange-contrast tw-py-3 tw-border-0 focus:tw-opacity-80 transition-fast"
                :disabled="disabled || sending"
                @click="sendMessage()"
              >
                <svg slot="icon" class="tw-w-6 tw-h-6 tw-mx-3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M70.9,0.9c-0.8-0.8-2.2-1-3.3-0.6l-65.8,25c-1.2,0.6-2,1.8-1.8,3c0,1.2,0.8,2.2,2,2.6l28.8,10l10.4,29.1
                    c0.4,1.2,1.6,2,2.8,2l0,0c1.4,0,2.6-1,3-2.2L71.9,4.1C72.2,2.9,71.7,1.7,70.9,0.9z M44.1,66.1l-9.8-27c-0.4-0.8-1-1.6-1.8-1.8
                    L5.7,28.1L67.1,4.9L44.1,66.1z M39.6,32.3c-0.8-0.8-0.8-2,0-2.8l10-10c0.8-0.8,2-0.8,2.8,0s0.8,2,0,2.8l-10,10
                    c-0.4,0.4-1,0.6-1.4,0.6C40.7,33,40.1,32.5,39.6,32.3z"/>
                </svg>
              </BaseButton>
            </template>
          </div>
        </div>
      </div>
    </portal>
  </div>
  </AppView>
</template>

<script>
import _debounce from 'lodash/debounce';
import _uniqBy from 'lodash/uniqBy';
import { customObservable } from '@/modules/misc';
import GroupChatBubbleText from '@/components/GroupChatBubbleText.vue';
import { mapGetters } from 'vuex';
import form from '@/modules/formHelper';

let observer;

export default {
  name: 'GroupChat',
  components: {
    GroupChatBubbleText,
  },
  directives: {
    observeScrollheight: {
      inserted(el, binding) {
        const config = {
          childList: true,
        };
        observer = customObservable(
          el,
          config,
          _debounce((mutationsList) => {
            // make uniq mutation.type
            const mutations = _uniqBy(Object.entries(mutationsList), ['type']);
            mutations.forEach(() => {
              if (typeof binding.value === 'function') binding.value();
            });
          }, 180),
        );
      },
    },
  },
  data() {
    return {
      openAttachmentMenu: false,
      form: {
        attachment: undefined, // Object
        text: '',
        attachmentId: undefined,
        attachmentType: undefined,
        chatReplyId: undefined,
      },
      loading: true,
      error: undefined,
      sending: false,
      disabled: true,
      textareaScrollHeight: 'auto',
      focusMode: false,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.text': async function (val) {
      this.$emit('input', val);

      this.textareaScrollHeight = 'auto';

      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();

      if (this.$refs['text-input-el']) {
        const currentTextareaHeight = `${this.$refs['text-input-el'].scrollHeight}px`;
        this.textareaScrollHeight = currentTextareaHeight;
      }

      if (form.isValidTextInput(val)) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
  computed: {
    ...mapGetters([
      'groupChatMessages',
      'fetchingGroupChatError',
      'userId',
    ]),
    hasMessages() {
      return this.groupChatMessages.length > 0;
    },
    useLgGrid() {
      return !!this.lgGrid;
    },
    hasAttached() {
      return !!this.form.attachmentId || !!this.form.chatReplyId;
    },
  },
  methods: {
    isResourceRef() {
      return !!this.form.attachment.data;
    },
    setReplyToMessage(message, user) {
      this.form.chatReplyId = message.id;
      this.form.attachment = { ...message, ...user };
    },
    async sendMessage() {
      // disable the textarea
      this.sending = true;

      // send message
      const response = await this.$store.dispatch('sendGroupChatMessage', this.form);

      await this.$nextTick();
      this.sending = false;

      if (this.$refs['text-input-el']) {
        this.$refs['text-input-el'].focus();
      }

      // validate response
      if (!response) {
        this.$toasted.global.appError({
          errorMessage: 'Could not send message. Try again later',
        });
      } else {
        this.form.text = '';
        this.form.attachmentId = undefined;
        this.form.attachmentType = undefined;
        this.form.chatReplyId = undefined;
        this.form.attachment = undefined;
        this.$store.dispatch('clearReferencedItem', 'refResource/GroupChat');

        // get prompt for user
        this.$store.dispatch('getCurrentUserJourneyAction');
      }
    },
    toggleAttachmentMenu() {
      this.openAttachmentMenu = !this.openAttachmentMenu;

      if (!this.openAttachmentMenu && this.$refs['attachment-menu-button']) {
        this.$refs['attachment-menu-button'].blur();
      }
    },
    async clearAttachment() {
      // todo: clear userViewData from the store
      await this.$store.dispatch('clearReferencedItem', 'refResource/GroupChat');

      this.form.attachment = { };
      this.form.attachmentId = undefined;
      this.form.attachmentType = undefined;
      this.form.chatReplyId = undefined;
      setTimeout(() => {
        this.$emit('view-mounted');
      }, 1000);
    },
    async scrollDown() {
      if (this.$refs['chat-body']) {
        await this.$nextTick();
        window.scrollTo(0, this.$refs['chat-body'].scrollHeight);
      }
    },
    async attachResource() {
      await this.clearAttachment();
      await this.$nextTick();
      this.$router.push({ name: 'resources', query: { to: 'group-chat' } });
    },
  },
  async mounted() {
    await this.$nextTick();
    this.$store.commit('isOnGroupChat');
    await this.$nextTick();
    this.$emit('view-mounted');
    const $this = this;

    setTimeout(async function hasMessages() {
      if (typeof $this.groupChatMessages !== 'object') {
        setTimeout(hasMessages, 1000);
      } else {
        $this.loading = false;
        await $this.$nextTick();
        $this.scrollDown();
        $this.$emit('view-mounted');
      }
    }, 1000);

    const referencedResource = await this.$store.dispatch('getChatReferencedItem', 'refResource/GroupChat');

    if (referencedResource) {
      this.form.attachment = referencedResource;
      this.form.attachmentId = this.form.attachment.id;
      this.form.attachmentType = 'resourceid';
      this.form.resourceType = this.form.attachment.file_type;
    }

    await this.$nextTick();
    await this.$nextTick();
    this.scrollDown();
  },
  beforeDestroy() {
    this.$store.commit('resetGroupChatFetchSpeed');

    if (typeof observer === 'object') {
      if (Object.prototype.hasOwnProperty.call(observer, 'disconnect')) observer.disconnect();
    }
  },
};
</script>

<style>

</style>
