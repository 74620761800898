import { mapGetters } from 'vuex';
// import _flatten from 'lodash/flatten';
import _compact from 'lodash/compact';
import { toBoolean } from '@/modules/dataHelper';
import { apiPost } from '@/modules/apiHelper';

// const RESOURCE_TYPES = {
//   pillarresource: 1,
//   pillarlink: 2,
//   resource: 3,
//   link: 4,
// };

export default {
  name: 'MyResourceMixin',
  props: ['id'],
  data() {
    return {
      shareTo: _compact([this.$route.query.to || this.id]),
      user: {},
      selectedResource: undefined,
      selectedUsers: [],
    };
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
    myMentorsOrMentees() {
      return this.isMentor ? this.$store.getters.myMentees : this.$store.getters.myMentors;
    },
    shareToWho() {
      if (!this.shareTo[0]) {
        return undefined;
      }
      if (this.user.user) {
        return `to ${this.user.user.name}`;
      }
      return undefined;
    },
    totalSelectedResources() {
      return this.selectedResources.length + this.selectedLinkResources.length;
    },
    resourcesAreSelected() {
      const totalSelected = this.selectedResources.length + this.selectedLinkResources.length;
      return totalSelected > 1;
    },
    selectedUsersCountLabel() {
      return this.selectedUsers.length > 0 ? `to ${this.selectedUsers.length}` : '';
    },
    usersAreSelected() {
      return this.selectedUsers.length > 0;
    },
    allUsersAreSelected() {
      return this.selectedUsers.length === this.myMentorsOrMentees?.length;
    },
  },
  methods: {
    getResourceDetails(resource) {
      return {
        isLinkResource: resource?.file_type === 'link',
        isGoalLinkResource: resource?.file_type === 'link' && !!resource?.goal?.id,
        isResource: resource?.file_type !== 'link',
        isGoalResource: resource?.file_type !== 'link' && !!resource?.goal?.id,
      };
    },
    // eslint-disable-next-line consistent-return, default-param-last
    async shareResource(ev, to = [], resource = this.selectedResource) {
      // TODO: Update this ?
      // * if selected resources are meant for creating a task
      // save to apiStore, then redirect
      // if (this.toShareToTask) {
      //   if (isLinkResource) {
      //     await apiStore.setItem(`CreateTaskRefLinkResources/${this.id}`, resource);
      //   } else {
      //     await apiStore.setItem(`CreateTaskRefResources/${this.id}`, resource);
      //   }
      //   this.$router.push({
      //     name: 'mentorMenteeCreateTask',
      //     params: {
      //       id: this.id,
      //     },
      //   });

      //   this.$toasted.info('Resource(s) selected', {
      //     duration: 2500,
      //     position: 'top-right',
      //   });

      //   await this.$nextTick();

      //   return true;
      // }

      // TODO: Update this
      // * if selected resources are meant for sharing to group chat
      if (this.shareTo[0] === 'group-chat') {
        // set userViewData to be picked by group chat @ onMounted
        await this.$store.dispatch('saveResourceRef', ['GroupChat', resource]);

        this.$toasted.info('Resource selected', {
          duration: 2500,
          position: 'top-right',
        });

        // redirect to group chat
        this.$router.push({
          name: 'GroupChat',
        });

        return true;
      }

      const usersToShareTo = this.shareTo.length > 0 ? this.shareTo : _compact(to);

      if (usersToShareTo.length === 0) {
        await this.openPopOver(ev);
        await this.$nextTick();
        this.selectResource(resource);
        return false;
      }

      this.$toasted.info('Sharing...', {
        duration: 4000,
        position: 'top-right',
      });

      this.$store.commit('openPopOver', [false]);

      // send multiple resources directly to multiple users
      const promises = [];
      usersToShareTo.forEach((userId) => {
        const request = this.$store.dispatch('shareResource', [userId, resource]);
        promises.push(request);
      });

      Promise.all(promises)
        .catch((err) => {
          console.warn('shareResource[] err: ', err);
          this.$toasted.global.appError();
          return false;
        })
        .then(async (res) => {
          if (res.some((r) => r === false)) return false;

          this.$toasted.success('Resources sent!', {
            duration: 5000,
            position: 'top-right',
          });

          this.unselectResource();
          this.selectedUsers = [];

          if (usersToShareTo.length === 1) {
            await this.$store.dispatch('saveResourceRef', [`chat/${usersToShareTo[0]}`, resource]);

            const chatRouteName = this.isMentee ? 'mentorChat' : 'mentorMenteeChat';

            this.$router.push({ name: chatRouteName, params: { id: usersToShareTo[0] } });
          }

          return true;
        });
    },
    selectResource(resource) {
      this.selectedResource = resource;
    },
    unselectResource() {
      this.selectedResource = null;
    },
    userIsSelected(id) {
      return this.selectedUsers.indexOf(id) >= 0;
    },
    selectAllUsers(val, checked) {
      // console.log(checked, val);
      if (!checked) {
        this.selectedUsers = [];
      } else {
        this.myMentorsOrMentees.forEach((user) => {
          const id = this.isMentee ? user.mentorid : user.menteeid;
          if (this.selectedUsers.indexOf(id) < 0) {
            this.addUserToShare(id, checked);
          }
        });
      }
    },
    addUserToShare(userId, checked) {
      // console.log('adding', checked);
      const index = this.selectedUsers.indexOf(userId);
      if (index < 0 && checked) {
        this.selectedUsers.push(userId);
      } else if (!checked) {
        this.selectedUsers.splice(index, 1);
      }
    },
    async openUploadModal() {
      this.$store.commit('openModal', [true]);
    },
    async deleteResource(resource) {
      // you can only delete resource that belongs to you
      if (Number(resource.userid) !== this.userId) return;
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(`Are you sure you want to delete "${resource.name || resource.title || resource.data}"?`);
      if (!confirm) return;

      this.getMyResources(resource);

      // TODO: Update this

      const formData = new FormData();
      formData.set('resourceid', resource.id);

      let apiRequest;

      if (this.isMentee) {
        apiRequest = this.resourceIsLink(resource)
          ? apiPost('del_resource_link', formData, 19)
          : apiPost('del_resource', formData, 1);
      } else {
        apiRequest = this.resourceIsLink(resource)
          ? apiPost('resource_link', formData, 36)
          : apiPost('del_add_resource_pillar', formData, 19);
      }

      await apiRequest
        .catch(() => {
          this.$toasted.appError();
          return false;
        })
        .then((res) => {
          if (!res) return;
          if (toBoolean(res.data.error)) {
            this.$toasted.appError({ errorMessage: 'Sorry we can\'t delete resource at the moment' });
          }

          this.$toasted.success('Resource deleted', { duration: 4000 });
          this.getMyResources();
        });
    },
  },
  async mounted() {
    if (this.shareTo.length > 0) {
      if (this.shareTo[0] !== 'group-chat') {
        const user = await this.$store.dispatch('getUserData', this.shareTo[0]) || {};
        this.user = { ...user };
      }
    }
  },
};
