import resolve from '../../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { isStaleData, now, toBoolean } from '../../../modules/dataHelper';

const RESOURCE_TYPES = {
  resource: 'resource',
  link: 'link',
};

export default {
  async getProgramResource(context, [resourceid, resourceType = RESOURCE_TYPES.resource, compStat = 0, sharedBy]) {
    const localDb = await apiStore.getItem(`resource/${resourceid}/${resourceType}`) || [0, undefined];

    if (localDb[1] && !isStaleData(localDb[0])) return localDb[1];

    let apiUrl;
    let apiEndpoint;

    switch (resourceType) {
      case RESOURCE_TYPES.resource:
        apiUrl = 'prog_share_resource_dt';
        apiEndpoint = 68;
        break;
      case RESOURCE_TYPES.link:
        apiUrl = 'prog_share_link_dt';
        apiEndpoint = 68;
        break;
      default:
        return false;
    }

    const formData = generateFormData({
      resourceid,
    });

    const resource = await apiPost(apiUrl, formData, apiEndpoint)
      .then((res) => {
        if (!res) return {};
        if (toBoolean(res.data.error)) return {};

        const resc = res.data[apiUrl][0];

        if (resc?.id) {
          resc.compStat = Number(compStat);
          resc.sharedBy = sharedBy;
          apiStore.setItem(`resource/${resourceid}/${resourceType}`, [now(), resc]);
        }

        return resc || {};
      });
    //

    return resource;
  },

  async getMyProgramResources(context) {
    const userId = context.rootState.user.id;
    const programId = context.rootState.user.programid;

    //
    const formData = generateFormData({
      userid: userId,
      progid: programId,
    });

    const goalResourcesPromise = resolve(apiPost('my_prog_resource_goal', formData, 68)).then(async (res) => {
      if (!res) return [];

      const resources = await Promise.all(res.my_prog_resource_goal.map((resource) => context.dispatch('getProgramResource', [resource.resourceid, RESOURCE_TYPES.link])));

      return resources;
    });

    const resourcesPromise = resolve(apiPost('my_prog_resource', formData, 68)).then(async (res) => {
      if (!res) return [];

      const resources = await Promise.all(res.my_prog_resource.map((resource) => context.dispatch('getProgramResource', [resource.resourceid, RESOURCE_TYPES.link])));

      return resources;
    });

    const [goalResources, resources] = await Promise.all([goalResourcesPromise, resourcesPromise]);

    return {
      goalResources,
      resources,
    };
  },

  async getMyProgramLinkResources(context) {
    const userId = context.rootState.user.id;
    const programId = context.rootState.user.programid;

    //
    const formData = generateFormData({
      userid: userId,
      progid: programId,
    });

    const goalLinkResourcesPromise = resolve(apiPost('my_prog_resource_goal_link', formData, 68)).then(async (res) => {
      if (!res) return [];

      const resources = await Promise.all(res.my_prog_resource_goal_link.map((resource) => context.dispatch('getProgramResource', [resource.resourceid, RESOURCE_TYPES.link])));

      return resources;
    });

    const linkResourcesPromise = resolve(apiPost('my_prog_resource_link', formData, 68)).then(async (res) => {
      if (!res) return [];

      const resources = await Promise.all(res.my_prog_resource_link.map((resource) => context.dispatch('getProgramResource', [resource.resourceid, RESOURCE_TYPES.link])));

      return resources;
    });

    const [goalLinkResources, linkResources] = await Promise.all([goalLinkResourcesPromise, linkResourcesPromise]);

    return {
      goalLinkResources,
      linkResources,
    };
  },

  async getAdminProgramLinkResources(context) {
    const userId = context.rootState.user.id;
    const programId = context.rootState.user.programid;

    //
    const formData = generateFormData({
      userid: userId,
      progid: programId,
    });

    const goalLinkResourcesPromise = resolve(apiPost('prog_share_goal_resource_link_log_user_a', formData, 68)).then(async (res) => {
      if (!res) return [];

      // eslint-disable-next-line eqeqeq
      const notMyResources = res.prog_share_goal_resource_link_log_user_a.filter((resource) => resource.senderid != userId);
      const resources = await Promise.all(notMyResources.map((resource) => context.dispatch('getProgramResource', [resource.resourceid, RESOURCE_TYPES.link, resource.comp_stat, resource.senderid])));

      return resources;
    });

    const linkResourcesPromise = resolve(apiPost('prog_share_resource_log_link_user', formData, 68)).then(async (res) => {
      if (!res) return [];

      // eslint-disable-next-line eqeqeq
      const notMyResources = res.prog_share_resource_log_link_user.filter((resource) => resource.senderid != userId);
      const resources = await Promise.all(notMyResources.map((resource) => context.dispatch('getProgramResource', [resource.resourceid, RESOURCE_TYPES.link, resource.comp_stat, resource.senderid])));

      return resources;
    });

    const [goalLinkResources, linkResources] = await Promise.all([goalLinkResourcesPromise, linkResourcesPromise]);

    return {
      goalLinkResources,
      linkResources,
    };
  },

  async getAdminProgramResources(context) {
    const userId = context.rootState.user.id;
    const programId = context.rootState.user.programid;

    //
    const formData = generateFormData({
      userid: userId,
      progid: programId,
    });

    const goalResourcesPromise = resolve(apiPost('prog_share_goal_resource_log_user_a', formData, 68)).then(async (res) => {
      if (!res) return [];

      // eslint-disable-next-line eqeqeq
      const notMyResources = res.prog_share_goal_resource_log_user_a.filter((resource) => resource.senderid != userId);
      const resources = await Promise.all(notMyResources.map((resource) => context.dispatch('getProgramResource', [resource.resourceid, RESOURCE_TYPES.resource, resource.comp_stat, resource.senderid])));

      return resources;
    });

    const resourcesPromise = resolve(apiPost('prog_share_resource_log_user', formData, 68)).then(async (res) => {
      if (!res) return [];

      // eslint-disable-next-line eqeqeq
      const notMyResources = res.prog_share_resource_log_user.filter((resource) => resource.senderid != userId);
      const resources = await Promise.all(notMyResources.map((resource) => context.dispatch('getProgramResource', [resource.resourceid, RESOURCE_TYPES.resource, resource.comp_stat, resource.senderid])));

      return resources;
    });

    const [goalResources, resources] = await Promise.all([goalResourcesPromise, resourcesPromise]);

    return {
      goalResources,
      resources,
    };
  },
};
