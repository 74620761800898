<template>
  <div>
    <!-- popover - List my mentors -->
    <portal to="popover-menu">
      <ul>
        <li>
          <BaseCheckBox
            @change="selectAllUsers"
            :checked="allUsersAreSelected"
            label="All"
            value="all"
          />
        </li>
        <li
          v-for="({ user }, index) in myMentorsOrMentees"
          :key="index"
          :class="[
            'tw-flex tw-items-center popover__item',
            { 'tw-bg-black hover:bg-black-78 tw-text-white hover:tw-text-white': userIsSelected(user.id) }
          ]"
        >
          <BaseCheckBox
            :value="user.id"
            @change="addUserToShare(user.id, !userIsSelected(user.id))"
            :checked="userIsSelected(user.id)"
            class="tw-mr-4"
          />
          <div
          class="tw-flex tw-items-center"
          @click="addUserToShare(user.id, !userIsSelected(user.id))"
          >
            <UserPhoto
              photo-size="tw-h-4 tw-w-4"
              class="tw-flex-shrink-0"
              :photo="user.photo"
              :user-name="user"
            />
            <p class="tw-ml-4 tw-text-sm leading-17 opacity-78">{{ user.name }} {{ user.lname }}</p>
          </div>
        </li>
      </ul>
      <div class="tw-sticky tw-bottom-0 tw-py-2 tw-px-4 tw-text-right">
        <BaseButton
          :text="`Share ${selectedUsersCountLabel}`"
          :disabled="!usersAreSelected"
          @click="shareResource($event, selectedUsers)"
        />
      </div>

    </portal>

    <transition mode="out-in" name="fade" appear>
      <ContentLoadingTwoGrid v-if="loadingResources && isMobileView" style="margin-left: -36px; margin-right: -36px;"/>
      <ContentLoadingThreeGrid v-else-if="loadingResources" style="margin-left: -36px; margin-right: -36px;"/>

      <div v-else-if="errorResources">
        <p class="tw-text-red-400 tw-py-24" v-html="errorResources"></p>
      </div>

      <div v-else>
        <div class="tw-mb-4" v-if="hasAnyLinkResources">
          <h6 class="tw-text-xs tw-mb-2">Links</h6>
          <h6 class="tw-text-xs tw-mb-2 opacity-54">Click on the categories to view resources</h6>
          <div v-if="!selectedLinkCategory" class="tw-flex stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-full tw-flex-wrap">
            <!-- show link resources groups -->
            <div
              v-for="groupId in Object.keys(linkResources)"
              :key="groupId"
              class=""
              @click="selectedLinkCategory = groupId"
            >
              <div v-if="linkResources[groupId].length > 0" class="info-card tw-border hover:tw-border-white hover:tw-shadow-app transition-fast tw-relative tw-cursor-pointer tw-flex tw-flex-col tw-mb-2">
                <div class="tw-px-2 tw-py-6">
                  <h4 v-if="groupId === 'other'" class="leading-19 tw-text-base">
                    Other
                  </h4>
                  <h4 v-else class="leading-19 tw-text-base">
                    {{ isSequentialProgram ? linkResources[groupId][0].goal.goal : linkResources[groupId][0].goal.goal_name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <!-- breadcrumb -->
            <p class="tw-inline-flex tw-items-center tw-text-sm font-nunito tw-px-2 tw-bg-gray-300 tw-rounded-r-10">
              <span class="hover:tw-underline tw-cursor-pointer" @click="selectedLinkCategory = null">All Categories</span>
              <span class="tw-w-2"></span>
              <svg slot="icon" class="tw-w-3 tw-h-3 tw-origin-center tw-rotate-180 opacity-54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M56,4.2c0,1.2-0.4,2-1.2,2.9l-29,29l29,29c1.6,1.6,1.6,4.1,0,5.7s-4.1,1.6-5.7,0L17.2,39c-1.6-1.6-1.6-4.1,0-5.7l31.9-32
                  c1.6-1.6,4.1-1.6,5.7,0C55.6,2.1,56,3,56,4.2z"/>
              </svg>
              <span class="tw-w-2"></span>
              <span>{{selectedLinkCategoryName}}</span>
            </p>
            <div class="tw-mt-2">
              <BaseButton
                text="Back"
                icon
                class=""
                @click="selectedLinkCategory = null"
              >
                <svg slot="icon" class="my-btn__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M56,4.2c0,1.2-0.4,2-1.2,2.9l-29,29l29,29c1.6,1.6,1.6,4.1,0,5.7s-4.1,1.6-5.7,0L17.2,39c-1.6-1.6-1.6-4.1,0-5.7l31.9-32
                    c1.6-1.6,4.1-1.6,5.7,0C55.6,2.1,56,3,56,4.2z"/>
                </svg>
              </BaseButton>
            </div>

            <BaseCard class="tw-flex tw-flex-no-wrap tw-shadow-app tw-py-2 tw-px-4 tw-mb-3 tw-mt-3 tw-items-center tw-border-t-2 tw-border-app-white-87 tw-transition-slow">
              <div class="tw-inline-flex tw-items-center">
                <svg version="1.1" class="tw-w-6 tw-h-6 sm:tw-inline-block tw-hidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M32.2,72c-0.7,0-1.6-0.2-2.3-0.7c-1.1-0.7-1.8-2.1-1.8-3.4V43.7L7.8,5.7C7.2,4.3,7.4,3,8.1,1.8C8.7,0.7,9.9,0,11.5,0h49
                    c1.4,0,2.7,0.7,3.4,1.8s0.9,2.5,0.5,3.9L45.7,43.7v17.8c0,1.6-0.9,3-2.3,3.6l-9.6,6.4C33.4,71.8,32.7,72,32.2,72z M12.2,4.6
                    l20.1,37.4c0.2,0.7,0.5,1.1,0.5,1.8V67l8.4-5.7V43.5c0-0.5,0-1.1,0.2-1.6L59.6,4.3H12.2V4.6z"/>
                </svg>
                <p class="leading-19 sm:tw-ml-2">Search</p>
              </div>
              <BaseInput
                v-model="searchLinkTextQuery"
                type="text"
                placeholder="Search"
                input-class="tw-ml-8 tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-flex-grow tw-min-w-1/4"
              />
            </BaseCard>

            <ViewMore
              class="tw-flex stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
              :limit="isMobileView ? 2 : 3"
              :list="filteredSelectedLinkResources"
              v-slot="{ item: resource }"
            >
              <div class="info-card tw-shadow-app tw-relative tw-flex tw-flex-col">
                <div class="tw-flex-grow">
                  <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.name || resource.data }}</h4>
                  <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                    Uploaded {{ resource['createAt'] || resource['dateOfPost'] || resource['dateofPost'] | moment('calendar') }}
                  </h6>
                </div>
                <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-mt-4 tw-px-2 opacity-78">
                  <!-- delete resource btn goes here -->
                  <div class="tw-ml-auto"></div>
                </div>
                <div class="tw-flex tw-items-end tw-justify-between">
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                    LINK
                  </p>

                  <div class="tw-inline-flex tw-items-center">
                    <a
                      title="view/read"
                      class="tw-mr-2 tw-mb-2 tw-box-border tw-p-1 tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                      :href="resource.data"
                      target="_blank"
                    >
                      <svg
                        class="tw-w-6 tw-h-6"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </a>
                    <button
                      class="tw-mr-2 tw-mb-2 tw-p-1 hover:tw-bg-gray-300 tw-fill-current focus:tw-bg-gray-300 tw-rounded-sm active:tw-bg-gray-300"
                      @click.stop="shareResource($event, selectedUsers, resource)"
                    >
                      <!-- <span class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-10"></span> -->
                      <span class="tw-inline-block tw-uppercase tw-font-bold tw-text-xs tw-align-middle">SHARE</span>
                      <svg class="tw-w-6 tw-h-6 tw-ml-1 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M71.1,25.6L56.6,10.8c-1-1-2.4-1-3.3,0c-1,1-1,2.4,0,3.3l11.2,11.5H25.3C11.5,25.6,0,36.8,0,50.9v8.6c0,1.4,1,2.4,2.4,2.4
                          s2.4-1,2.4-2.4v-8.6c0-11.2,9.1-20.5,20.5-20.5h39.1L53.2,41.8c-1,1-1,2.4,0,3.3c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7
                          l14.6-14.8C72.3,29.2,72.3,27.1,71.1,25.6z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </ViewMore>
          </div>
        </div>

        <div class="tw-mb-4" v-if="hasAnyResources">
          <h6 class="tw-text-xs tw-mb-2">Documents and Media</h6>
          <h6 class="tw-text-xs tw-mb-2 opacity-54">Click on the categories to view resources</h6>
          <div v-if="!selectedCategory" class="tw-flex stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-full tw-flex-wrap">
            <!-- show resources groups -->
            <div
              v-for="groupId in Object.keys(resources)"
              :key="groupId"
              class=""
              @click="selectedCategory = groupId"
            >
              <div v-if="resources[groupId].length > 0" class="info-card tw-border hover:tw-border-white hover:tw-shadow-app transition-fast tw-relative tw-cursor-pointer tw-flex tw-flex-col tw-mb-2">
                <div class="tw-px-2 tw-py-6">
                  <h4 v-if="groupId === 'other'" class="leading-19 tw-text-base">
                    Other
                  </h4>
                  <h4 v-else class="leading-19 tw-text-base">
                    {{ isSequentialProgram ? resources[groupId][0].goal.goal : resources[groupId][0].goal.goal_name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <!-- breadcrumb -->
            <p class="tw-inline-flex tw-items-center tw-text-sm font-nunito tw-px-2 tw-bg-gray-300 tw-rounded-r-10">
              <span class="hover:tw-underline tw-cursor-pointer" @click="selectedCategory = null">All Categories</span>
              <span class="tw-w-2"></span>
              <svg slot="icon" class="tw-w-3 tw-h-3 tw-origin-center tw-rotate-180 opacity-54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M56,4.2c0,1.2-0.4,2-1.2,2.9l-29,29l29,29c1.6,1.6,1.6,4.1,0,5.7s-4.1,1.6-5.7,0L17.2,39c-1.6-1.6-1.6-4.1,0-5.7l31.9-32
                  c1.6-1.6,4.1-1.6,5.7,0C55.6,2.1,56,3,56,4.2z"/>
              </svg>
              <span class="tw-w-2"></span>
              <span>{{selectedCategoryName}}</span>
            </p>
            <div class="tw-mt-2">
              <BaseButton
                text="Back"
                icon
                class=""
                @click="selectedCategory = null"
              >
                <svg slot="icon" class="my-btn__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M56,4.2c0,1.2-0.4,2-1.2,2.9l-29,29l29,29c1.6,1.6,1.6,4.1,0,5.7s-4.1,1.6-5.7,0L17.2,39c-1.6-1.6-1.6-4.1,0-5.7l31.9-32
                    c1.6-1.6,4.1-1.6,5.7,0C55.6,2.1,56,3,56,4.2z"/>
                </svg>
              </BaseButton>
            </div>

            <BaseCard class="tw-flex tw-flex-no-wrap tw-shadow-app tw-py-2 tw-px-4 tw-mb-3 tw-mt-3 tw-items-center tw-border-t-2 tw-border-app-white-87 tw-transition-slow">
              <div class="tw-inline-flex tw-items-center">
                <!--  -->
                <svg version="1.1" class="tw-w-6 tw-h-6 sm:tw-inline-block tw-hidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                  <path d="M32.2,72c-0.7,0-1.6-0.2-2.3-0.7c-1.1-0.7-1.8-2.1-1.8-3.4V43.7L7.8,5.7C7.2,4.3,7.4,3,8.1,1.8C8.7,0.7,9.9,0,11.5,0h49
                    c1.4,0,2.7,0.7,3.4,1.8s0.9,2.5,0.5,3.9L45.7,43.7v17.8c0,1.6-0.9,3-2.3,3.6l-9.6,6.4C33.4,71.8,32.7,72,32.2,72z M12.2,4.6
                    l20.1,37.4c0.2,0.7,0.5,1.1,0.5,1.8V67l8.4-5.7V43.5c0-0.5,0-1.1,0.2-1.6L59.6,4.3H12.2V4.6z"/>
                </svg>
                <p class="leading-19 sm:tw-ml-2">Search</p>
              </div>
              <BaseInput
                v-model="searchTextQuery"
                type="text"
                placeholder="Search"
                input-class="tw-ml-8 tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-flex-grow tw-min-w-1/4"
              />
            </BaseCard>

            <ViewMore
              class="tw-flex stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
              :limit="isMobileView ? 2 : 3"
              :list="filteredSelectedResources"
              v-slot="{ item: resource }"
            >
              <div class="info-card tw-shadow-app tw-relative tw-flex tw-flex-col">
                <div class="tw-flex-grow">
                  <h4 class="leading-19 tw-text-base tw-p-2 tw-break-words">{{ resource.name || resource.data }}</h4>
                  <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                    Uploaded {{ resource['createAt'] || resource['dateOfPost'] || resource['dateofPost'] | moment('calendar') }}
                  </h6>
                </div>
                <div class="tw-flex tw-items-end tw-justify-between">
                  <p class="tw-text-sm tw-leading-17 tw-font-bold tw-p-2 tw-mt-3 tw-uppercase info-card__action-info info-card__action-info--left">
                    {{ resourceType(resource.file_type) }}
                  </p>
                  <div class="tw-inline-flex tw-items-center">
                    <router-link
                      tag="a"
                      :to="{
                        name: 'ResourceView',
                        query: {
                          doc: `${apiUrl}/${resource.data}`,
                          title: resource.name || resource.data || resource.path,
                        },
                      }"
                      title="view/read"
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
                          c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
                          c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
                          c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
                          S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                          C42.1,39.4,39.3,42.2,35.9,42.2z"/>
                      </svg>
                    </router-link>
                    <button
                      class="leading-14 tw-p-1 tw-ml-auto tw-mr-2 tw-mb-1 tw-box-border tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
                      @click.stop="downloadResource(resource.data, resource.name)"
                    >
                      <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                        y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
                          M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
                          c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
                          C62.8,33.4,62.8,31.7,61.6,30.5z"/>
                      </svg>
                    </button>
                    <button
                      class="tw-mr-2 tw-mb-2 tw-p-1 hover:tw-bg-gray-300 tw-fill-current focus:tw-bg-gray-300 tw-rounded-sm active:tw-bg-gray-300"
                      @click.stop="shareResource($event, selectedUsers, resource)"
                    >
                      <!-- <span class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-10"></span> -->
                      <span class="tw-inline-block tw-uppercase tw-font-bold tw-text-xs tw-align-middle">SHARE</span>
                      <svg class="tw-w-6 tw-h-6 tw-ml-1 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <path d="M71.1,25.6L56.6,10.8c-1-1-2.4-1-3.3,0c-1,1-1,2.4,0,3.3l11.2,11.5H25.3C11.5,25.6,0,36.8,0,50.9v8.6c0,1.4,1,2.4,2.4,2.4
                          s2.4-1,2.4-2.4v-8.6c0-11.2,9.1-20.5,20.5-20.5h39.1L53.2,41.8c-1,1-1,2.4,0,3.3c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7
                          l14.6-14.8C72.3,29.2,72.3,27.1,71.1,25.6z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </ViewMore>
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _groupBy from 'lodash/groupBy';
// import _orderBy from 'lodash/orderBy';
import BaseCheckBox from '@/components/BaseCheckBox.vue';
import BaseButton from '@/components/BaseButton.vue';
import ContentLoadingTwoGrid from '@/components/ContentLoadingTwoGrid.vue';
import ContentLoadingThreeGrid from '@/components/ContentLoadingThreeGrid.vue';
import BaseInput from '@/components/BaseInput.vue';
import MyResourceMixin from '@/mixins/myResources';
import { apiUrl } from '@/modules/apiHelper';

export default {
  name: 'MyResources',
  mixins: [MyResourceMixin],
  components: {
    BaseButton,
    BaseInput,
    BaseCheckBox,
    ContentLoadingTwoGrid,
    ContentLoadingThreeGrid,
  },
  data() {
    return {
      selectedCategory: null,
      selectedLinkCategory: null,
      resources: {},
      linkResources: {},
      modules: [],
      pillars: [],
      loadingResources: false,
      errorResources: null,
      searchLinkTextQuery: '',
      searchTextQuery: '',
      apiUrl,
    };
  },
  watch: {
    toggleRefreshMyResources() {
      this.getMyProgramResources();
    },
    popOverIsOpen() {
      this.selectedUsers = [];
    },
  },
  computed: {
    ...mapGetters([
      'popOverIsOpen',
    ]),
    toggleRefreshMyResources() {
      return this.$store.state.resources.toggleRefreshMyResources;
    },
    selectedCategoryName() {
      if (!this.selectedCategory) return '';

      if (this.selectedCategory === 'other') {
        return 'Other';
      }

      if (this.isSequentialProgram) {
        return this.resources[this.selectedCategory][0].goal.goal;
      }

      return this.resources[this.selectedCategory][0].goal.goal_name;
    },
    selectedLinkCategoryName() {
      if (!this.selectedLinkCategory) return '';

      if (this.selectedLinkCategory === 'other') {
        return 'Other';
      }

      if (this.isSequentialProgram) {
        return this.linkResources[this.selectedLinkCategory][0].goal.goal;
      }

      return this.linkResources[this.selectedLinkCategory][0].goal.goal_name;
    },
    filteredSelectedResources() {
      const searchText = this.searchTextQuery.toLowerCase();
      const resources = this.resources[this.selectedCategory] || [];
      return resources.filter((resource) => {
        if ((resource?.name || '').toLowerCase().includes(searchText)) {
          return resource;
        }

        return null;
      });
    },
    filteredSelectedLinkResources() {
      const searchText = this.searchLinkTextQuery.toLowerCase();
      const resources = this.linkResources[this.selectedLinkCategory] || [];
      return resources.filter((resource) => {
        if ((resource?.name || '').toLowerCase().includes(searchText) || (resource?.data || '').toLowerCase().includes(searchText)) {
          return resource;
        }

        return null;
      });
    },
    hasAnyResources() {
      return Object.values(this.resources).flat().length > 0;
    },
    hasAnyLinkResources() {
      return Object.values(this.linkResources).flat().length > 0;
    },
  },
  methods: {
    async getMyProgramResources() {
      this.loadingResources = true;

      // get admin program resources
      const [
        myProgramResources,
        myProgramLinkResources,
      ] = await Promise.all([
        this.$store.dispatch('getMyProgramResources'),
        this.$store.dispatch('getMyProgramLinkResources'),
      ]);

      // console.log('getMyProgramResources', myProgramResources);
      // console.log('getMyProgramLinkResources', myProgramLinkResources);

      // load modules to goal resources
      myProgramResources.goalResources.forEach((resource) => {
        // eslint-disable-next-line eqeqeq
        const module = this.modules.find((m) => m.id == resource.goalid);
        if (module) {
          resource.goal = module;
        }
      });

      myProgramLinkResources.goalLinkResources.forEach((resource) => {
        // eslint-disable-next-line eqeqeq
        const module = this.modules.find((m) => m.id == resource.goalid);
        if (module) {
          resource.goal = module;
        }
      });

      const groupKey = this.isSequentialProgram ? 'goalid' : 'pillarid';
      const groupedGoalLinkResources = _groupBy(myProgramLinkResources.goalLinkResources, groupKey);
      const groupedGoalResources = _groupBy(myProgramResources.goalResources, groupKey);

      this.resources = { ...groupedGoalResources, other: myProgramResources.resources };
      this.linkResources = { ...groupedGoalLinkResources, other: myProgramLinkResources.linkResources };

      this.loadingResources = false;
    },
    async getModules() {
      const modules = ((await this.$store.dispatch('getProgramModules', [])) || []).flat();

      if (Array.isArray(modules)) {
        this.modules = [...modules];
      }
    },
    async getPillars() {
      const pillars = await this.$store.dispatch('getPillarGroups', []);

      if (Array.isArray(pillars)) {
        this.pillars = [...pillars];
      }
    },
  },
  async mounted() {
    await this.$nextTick();

    if (this.isSequentialProgram) {
      await this.getModules();
    } else {
      await this.getPillars();
    }

    this.getMyProgramResources();
  },
};
</script>

<style>

</style>
