<template>
  <div>
    <transition name="fade" appear>
      <div v-show="openModal" class="modal-container tw-z-50 tw-fixed tw-flex tw-justify-center tw-items-center tw-h-screen tw-w-screen tw-left-0 tw-top-0">
        <div :class="
          [
            'modal tw-bg-white tw-fixed tw-z-50 tw-flex tw-flex-col tw-flex-grow-0 tw-flex-shrink-0',
            { 'modal--open': openModal },
            { 'modal--fullscreen': isFullScreenModal },
            { 'modal--dialog': isDialogModal },
          ]"
        >
          <div class="modal__header">
            <button
              class="tw-absolute tw-right-0 tw-top-0 tw-m-2 tw-inline-block"
              @click="close()"
              v-if="selfCloseModal"
            >
              <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
                  L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
                  s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
              </svg>
            </button>
            <portal-target name="modal-header">
              <slot name="header" />
            </portal-target>
          </div>
          <div class="modal__body tw-flex-grow">
            <portal-target name="modal-body">
              <slot name="body" />
            </portal-target>
          </div>
          <div class="modal__footer">
            <portal-target name="modal-footer">
              <slot name="footer" />
            </portal-target>
          </div>
        </div>
        <transition name="fade" appear>
          <div v-show="openModal" @click="close()" class="modal-bg tw-fixed tw-w-screen tw-h-screen tw-bg-black opacity-31 tw-z-40 tw-left-0 tw-top-0"></div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BaseModal',
  props: {
    type: {
      type: String,
      default: 'dialog', // in future, can be bottom-modal, full-page-modal
    },
  },
  computed: {
    ...mapState({
      openModal: state => state.ui.openModal,
      modalType: state => state.ui.modalType,
      selfCloseModal: state => state.ui.selfCloseModal,
    }),
    isDialogModal() {
      return this.modalType === 'dialog';
    },
    isFullScreenModal() {
      return this.modalType === 'full-screen';
    },
  },
  methods: {
    close() {
      this.$store.commit('openModal', [false]);
    },
  },
};
</script>

<style>

</style>
