/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import _debounce from 'lodash/debounce';
import _find from 'lodash/find';
import { apiStore, apiPost, apiGet } from '../../../modules/apiHelper';
import {
  toArray,
  objectIsValid,
  isStaleData,
  toBoolean,
  urlFriendly,
  now,
  invalideDate,
} from '../../../modules/dataHelper';

export default {
  async getUserData(context, userid = undefined) {
    if (userid === undefined || userid === null || Number(userid) === 0) {
      const user = await context.dispatch('getMyData');
      return user;
    }

    const [userStoreExpiry, userStoreData] = await apiStore.getItem(`user/${userid}`) || [0, {}];

    if (objectIsValid(userStoreData) && !isStaleData(userStoreExpiry)) return userStoreData;

    const { companyid } = context.rootState.user;

    const formData = new FormData();
    formData.set('userid', userid);

    const user = await Promise.all([
      apiPost('log_user', formData, 0), // 0
      apiGet('gender'), // 1
      undefined, // 2
      undefined, // 3
      context.dispatch('companyProfessions', companyid), // 4
      apiGet('experience'), // 5
      apiGet('availability'), // 6
      apiPost('profile_resources', formData, 1), // 7
      context.dispatch('companyRegions', companyid), // 8
      context.dispatch('companyDivisions', companyid), // 9
      context.dispatch('companyDepartments', companyid), // 10
      context.dispatch('companySubAreas', companyid), // 11
      context.dispatch('companyDeptLevels', companyid), // 12
    ]).then((res) => {
      if (!res || !(res || [])[0]) return {};
      if (!res[0].data.log_user.length) {
        context.commit(
          'xhrError',
          `[error: uData/${userid}--i/${context.rootGetters.userId}] Sorry we could not get a requested data. Please contact admin to fix this issue.`,
        );
        return {};
      }

      const {
        id,
        name,
        middle,
        // eslint-disable-next-line camelcase
        last_name,
        // eslint-disable-next-line camelcase
        working_num,
        email,
        type,
        profession,
        experience,
        availability,
        city,
        country,
        gender,
        bio,
        status,
        code,
        up,
        course,
        packid,
        licenseAt,
        // eslint-disable-next-line camelcase
        license_end,
        py,
        bal,
        opt,
        staff,
        regional_area,
        division,
        department,
        sub_area,
        dept_level,
      } = res[0].data.log_user[0];

      return {
        id,
        name,
        middle,
        last_name,
        working_num,
        email,
        type,
        bio,
        companyid: Number(companyid) || 1, // ! if id is 0 (zero) for some reason
        status: toBoolean(status),
        NDA: toBoolean(up),
        COC: toBoolean(code),
        course: toBoolean(course),
        packageid: packid,
        professionid: profession, // * for mentees in company scopes
        licensestartdate: licenseAt,
        licenseenddate: license_end,
        py,
        bal,
        opt,
        staff,
        regional_area: _find(res[8], {
          id: regional_area,
        }),
        division: _find(res[9], {
          id: division,
        }),
        department: _find(res[10], {
          id: department,
        }),
        sub_area: _find(res[11], {
          id: sub_area,
        }),
        dept_level: _find(res[12], {
          id: dept_level,
        }),
        photo: res[7].data.profile_resources.length > 0
          ? res[7].data.profile_resources[0].data || ''
          : '',
        gender: _find(res[1].data.gender, {
          id: gender,
        }),
        // country: _find(res[2].data.users, {
        //   id: country,
        // }),
        // city: _find(res[3].data.county, {
        //   id: city,
        // }),
        profession: _find(res[4], {
          id: `${profession}`,
        }) || {},
        experience: _find(res[5].data.experience, {
          id: `${experience}`,
        }) || {},
        availability: toArray(availability),
        availabilities: toArray(availability).map((a) => _find(res[6].data.availability, {
          id: `${a}`,
        })),

      };
    });

    if (objectIsValid(user)) {
      if (userid === context.rootState.user.id) {
        apiStore.setItem('userbio', user.bio);
        apiStore.setItem('userphoto', user.photo);
      }

      apiStore.setItem(`user/${userid}`, [now(), {
        ...user,
      }]);
    }

    return user;
  },
  async deleteUserData(context, userid) {
    await apiStore.removeItem(`user/${userid}`);
  },
  async updateUserPhoto(context, [file, isBlob = false]) {
    if (!file) {
      console.warn('No file sent ', file);
      return false;
    }

    const userid = context.rootState.user.id;
    const formData = new FormData();
    formData.set('userid', userid);

    if (isBlob) {
      const fileName = `profile${now()}.png`;
      formData.set('name', fileName);
      formData.append('file1', file, fileName);
    } else {
      const fileName = file.name.split('.')[0];
      formData.set('name', urlFriendly(fileName, true));
      formData.append('file1', file, urlFriendly(file.name, true));
    }

    const response = await apiPost('add_profile_pic', formData, 3)
      .catch((err) => {
        console.warn('AddProfilePic failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        await context.dispatch('getUserData', userid);

        context.commit('refreshMyPhoto');

        return true;
      });

    return response;
  },
  async updateUserBio(context, bio) {
    if (!bio) {
      console.warn('No bio => ', bio);
      return false;
    }

    const userid = context.rootState.user.id;
    const formData = new FormData();
    formData.set('userid', userid);
    formData.set('bio', bio);

    const response = await apiPost('bio', formData, 1)
      .catch((err) => {
        console.warn('SaveUserBio failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        await apiStore.setItem('userbio', bio);
        context.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },
  async updateUserAvailability(context, availability) {
    if (!availability) {
      console.warn('No availability => ', availability);
      return false;
    }
    const userid = context.rootState.user.id;
    const formData = new FormData();
    formData.set('userid', userid);
    formData.set('availability', availability);

    const response = await apiPost('change_availability', formData, 1)
      .catch((err) => {
        console.warn('UpdateUserAvailability failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        await context.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },
  async updateUserPhoneNumber(context, phoneNumber) {
    if (!phoneNumber) {
      console.warn('No phone number => ', phoneNumber);
      return false;
    }

    const userid = context.rootState.user.id;
    const formData = new FormData();
    formData.set('userid', userid);
    formData.set('phone', phoneNumber);

    const response = await apiPost('change_phone', formData, 0)
      .catch((err) => {
        console.warn('updateUserPhoneNumber failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        context.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },
  async updateUserEmail(context, email) {
    if (!email) {
      console.warn('No email => ', email);
      return false;
    }

    const userid = context.rootState.user.id;
    const formData = new FormData();
    formData.set('userid', userid);
    formData.set('email', email);

    const response = await apiPost('change_email', formData, 0)
      .catch((err) => {
        console.warn('updateUserEmail failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        context.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },
  async updateUserCode(context) {
    const userid = context.rootState.user.id;
    const formData = new FormData();
    formData.set('userid', userid);
    formData.set('code', 1);

    const response = await apiPost('create_code', formData, 8)
      .catch((err) => {
        console.warn('UpdateUserCode failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        await context.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },
  async updateUserNda(context) {
    const userid = context.rootState.user.id;
    const formData = new FormData();
    formData.set('userid', userid);
    formData.set('up', 1);

    const response = await apiPost('up_user', formData, 43)
      .catch((err) => {
        console.warn('updateUserNda failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        await context.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },
  async updateUserKengenDetails(context, formData) {
    const userid = context.rootState.user.id;

    const response = await apiPost('ups', formData, 44)
      .catch((err) => {
        console.warn('updateUserKengenDetails failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        await context.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },
  async updateUserName(context, { nameid, name }) {
    const userid = context.rootState.user.id;
    const formData = new FormData();

    formData.set('userid', userid);
    formData.set('name', name);
    formData.set('nameid', nameid); // 1 = first name, 2 = middle, 3 = last name

    const response = await apiPost('change_name', formData, 0)
      .catch((err) => {
        console.warn('updateUserName failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`user/${userid}`);
        context.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },

  /**
   *
   * Updates vuex User data with apiStore data
   * @returns {Array} [userid, userurl, username, usertype, licensetype (depr), companyid, userBio, userPhoto, ... userTypeName, userGoals, programid]
   */
  async getLoggedInUserData({
    state,
    getters,
    commit,
    dispatch,
  }) {
    const details = await Promise.all([
      state.id || apiStore.getItem('userid'), // 0
      state.url || apiStore.getItem('userurl'), // 1
      state.name || apiStore.getItem('username'), // 2
      state.type || apiStore.getItem('usertype'), // 3
      undefined, // 4 // ! licensetype not needed
      state.companyid || apiStore.getItem('companyid'), // ! 5
      await apiStore.getItem('userbio') || '', // 6
      await apiStore.getItem('userphoto') || '', // 7
    ]);

    const nextKey = () => {
      const key = details.length;
      details[key] = undefined;
      return key;
    };
    const keyUserTypeName = nextKey(); // 8
    const keyUserGoals = nextKey(); // 9
    details[nextKey()] = state.programid || await apiStore.getItem('programid'); // 10
    details[nextKey()] = getters.isSequentialProgram; // 11

    // whether 'mentor' or 'mentee'
    details[keyUserTypeName] = getters.userTypeName;

    if (details[keyUserTypeName] && state[details[keyUserTypeName]] && !getters.isSequentialProgram) {
      details[keyUserGoals] = (state[details[keyUserTypeName]].goals.length === 0)
        ? await apiStore.getItem(`${details[keyUserTypeName]}goals`) || []
        : state[details[keyUserTypeName]].goals;
    } else {
      details[keyUserGoals] = getters.isSequentialProgram
        ? [{}] // fake goal for sequential programs
        : [];
    }

    // update the store to match the localDB
    commit('updateUser', ['id', details[0]]);
    commit('updateUser', ['url', details[1]]);
    commit('updateUser', ['name', details[2]]);
    commit('updateUser', ['type', details[3]]);
    commit('updateUser', ['companyid', details[5]]);
    commit('updateUser', ['programid', details[10]]);

    if (state[details[keyUserTypeName]]) {
      // eslint-disable-next-line prefer-destructuring
      state[details[keyUserTypeName]].goals = details[keyUserGoals];
    }

    if (state.id) {
      // confirm from db the user type, if there's a mismatch, logout with authError
      await dispatch('logUser', false);
    }

    return details;
  },

  // looks at a user's data to determine if he is new to the app
  async userIsNew({ getters, dispatch }) {
    // first sets/gets
    // await dispatch('getLoggedInUserData');
    // gets (sure bet)
    const userData = await dispatch('getLoggedInUserData');
    const user = await dispatch('getUserData', getters.userId);

    if (userData[9].length === 0) {
      userData[9] = await dispatch('getUserGoals');
    }

    return [
      await dispatch('logUser', true), // checks server data match
      userData[6].length > 0, // User profile is set // removed photo validation
      userData[9].length > 0, // User goals are set
      user.COC || false, // User has accepted T&Cs
      user.course || false, // mentor MIP classes
      toBoolean(user.packageid) || false, // mentee package
      user.NDA || false,
      user,
    ];
  },

  /**
   * MOST BASIC ACTION
   * This confirms the right user is logged in
   *    else logs session out
   */
  logUser: _debounce(async ({
    state,
    dispatch,
    commit,
  }, strict = false) => {
    // console.info('Confirming user...');
    const formData = new FormData();
    formData.set('userid', state.id);

    return apiPost('log_user', formData)
      .then(async (res) => {
        if (!res) return false;
        if (!(res.data || {}).log_user) return false;

        if (!res.data.log_user.length) {
          await dispatch(
            'userLogOut',
            'Error in your log in details. Please log in again.',
          );
          console.warn('user data not returned');
          return false;
        }

        const user = res.data.log_user[0];
        const expiry = await apiStore.getItem('session-expiry') || invalideDate;

        if (Number(user.type) !== state.type || (isStaleData(expiry) && strict)) {
          console.warn('authError!');
          await dispatch(
            'userLogOut',
            'Error in your log in details. Please log in again.',
          );
          return false;
        }

        commit('updateUser', ['name', user.name]);

        await Promise.all([

          dispatch('updateUserSession', ['username', user.name]),
          dispatch('updateUserSession', ['userphoto', user.photo]),
          dispatch('updateUserSession', ['userbio', user.bio]),
          dispatch('userLogIn', {
            userid: user.id,
            url: user.u,
            type: user.type,
            companyid: Number(user.companyid),
          }),

        ]);

        return true;
      })
      .catch(() => {
        commit('xhrError', 'Sorry, an error occured. Check your internet connection.');
      });
  }, 700, {
    leading: true, // very important, otherwise you are logged out
  }),

  async postUserLastSeen(context) {
    if (context.state.id) {
      // console.log('logging');
      // await apiStore.setItem('session-expiry', new Date().getTime());
      const formData = new FormData();
      formData.set('userid', context.state.id);
      return apiPost('last_seen', formData, 1);
    }
    return false;
  },
};
