import authStoreModule from '@/store/auth';

export default {
  name: 'authMixin',
  methods: {
    registerAuthStoreModule() {
      const store = this.$store;

      // eslint-disable-next-line no-underscore-dangle
      if (!(store && store._modules.root._children.auth)) {
        store.registerModule('auth', authStoreModule);
      } else {
        store.commit('clearAuthData');
      }
    },
    unregisterAuthStoreModule() {
      const store = this.$store;

      if (store && store.state.auth) {
        store.unregisterModule('auth');
      }
    },
    unregisterUserStoreModule(userStoreName) {
      // eslint-disable-next-line no-underscore-dangle
      const userStoreModule = this.$store._modules.root._children.user;
      // eslint-disable-next-line no-underscore-dangle
      // console.log('STORE NAME', userStoreName, userStoreModule._children[userStoreName]);

      // eslint-disable-next-line no-underscore-dangle
      if (userStoreModule._children[userStoreName]) {
        this.$store.unregisterModule(['User', userStoreName]);
      }
    },
  },
};
