import _debounce from 'lodash/debounce';
import form from '../modules/formHelper';
import {
  apiStore,
  apiPost,
} from '../modules/apiHelper';
import {
  toBoolean,
} from '../modules/dataHelper';

export default {
  name: 'LoginMixin',
  data() {
    return {
      loading: true,
      btnText: 'Log in',
      btnDisabled: false,
      email: undefined,
      password: undefined,
      errors: {
        email: undefined,
        auth: this.$store.state.user.authError,
      },
      destroyJivo: 0,
      user: undefined,
      userPrograms: [],
    };
  },
  computed: {
    canSubmit() {
      return form.isValidEmail(this.email)
        && form.isValidTextInput(this.password, true, form.passwordRegex);
    },
    formData() {
      const formData = new FormData();

      formData.set('email', this.email);
      formData.set('password', this.password);

      return formData;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    email: _debounce(function (val) {
      if (!form.isValidEmail(val)) {
        this.errors.email = 'Invalid email address';
      } else {
        this.errors.email = undefined;
      }
    }, 300),
    // eslint-disable-next-line func-names
    password: _debounce(function (val) {
      if (!form.isValidTextInput(val, true, form.passwordRegex)) {
        this.errors.password = 'Tip: Your password is atleast 8 characters long';
      } else {
        console.log('cleared!');
        this.errors.password = undefined;
        this.errors.password = undefined;
        this.errors.password = undefined;
      }
    }, 150),
  },
  methods: {
    onSubmit() {
      if (!this.canSubmit) {
        this.$store.commit('authError', 'Cannot submit the form. Please check for empty fields');
        // this.$toasted.global.appError({ errorMessage: 'Cannot submit the form. Please check for empty fields', duration: 4000, position: 'top-right' });
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';
      this.$store.commit('authError', undefined);

      return apiPost('login', this.formData)
        .catch((err) => {
          console.warn('login error', err);
          this.$toasted.global.appError();
        })
        .then(async (res) => {
          this.btnDisabled = false;
          this.btnText = 'Log in';
          if (!res) return false;

          const {
            data,
          } = res;

          if (toBoolean(data.error)) {
            this.$store.commit('authError', data.message);
            this.$toasted.global.appError({
              errorMessage: data.message,
              duration: 4000,
              position: 'top-right',
            });

            return false;
          }

          // unregister all usertType modules
          this.unregisterUserStoreModule('mentor');
          this.unregisterUserStoreModule('mentee');

          if (data.status === 'Verify') {
            // redirect to verify
            this.$router.push({
              name: 'verify-account',
              query: {
                email: this.email,
                s: 'ev',
              },
            });

            return true;
          }

          await this.$store.dispatch('clearUserSession');

          this.user = data;
          this.$store.commit('authError', false);

          await this.getUserPrograms();
          await this.$nextTick();

          this.showUserProgramsPrompt();

          return true;
        });
    },

    async getUserPrograms() {
      if (!this.user?.userid) return false;

      const userPrograms = await this.$store.dispatch('getUserPrograms', this.user?.userid);

      if (Array.isArray(userPrograms)) {
        this.userPrograms = userPrograms;
      }

      return true;
    },

    showUserProgramsPrompt() {
      this.$store.commit('openModal', [true]);
    },

    async userSelectProgram(program) {
      const data = {
        ...this.user,
        programid: program.id,
      };

      await this.$store.dispatch('userLogIn', data);

      this.$toasted.success(data.message, {
        duration: 4000,
        theme: 'outline',
        position: 'bottom-right',
      });

      await apiStore.removeItem('forgotpasswordsent');
      await this.$nextTick();
      await this.$nextTick();

      if (typeof window.jivo_destroy === 'function') {
        console.log('destroyed from login');
        this.destroyJivo = +1;
        window.jivo_destroy();
      }

      /**
       * RELOADING HELPS IN REGISTERING THE PARENT COMPONENT (MENTOR/MENTEE)APP
       * SOMEHOW THE /APP COMPONENT FUNCTION DOES NOT RUN IF NOT RELOADED
       * [TODO] FIX THIS TO JUST USE $router FUNCTIONS
       */
      window.location.reload();

      return true;
    },
  },
};
