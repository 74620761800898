<template>
  <div>
    <transition mode="out-in" name="fade">
      <div v-if="!meeting?.id">
        <div class="tw-w-full tw-p-8 tw-bg-gray-300 tw-rounded-10">
          <p class="tw-text-sm tw-text-center opacity-78">
            Create a meeting first before adding participants
          </p>
        </div>
      </div>

      <div v-else>
        <template v-if="meeting?.id && participants.length === 0">
          <div class="tw-flex tw-bg-app-primary-blue tw-px-4 tw-py-2 tw-rounded-10 tw-mb-4">
            <div class="tw-pl-5">
              <p class="tw-text-white opacity-78 tw-text-sm">
                Now add Participants to your meeting!
              </p>
            </div>
          </div>
        </template>

        <div class="tw-inline-flex">
          <div class="tw-inline-block tw-py-1 tw-px-3 tw-rounded-full tw-bg-gray-200" v-for="user in selectedUsers" :key="user.id">
            {{user.name}} {{user.last_name}}
          </div>
        </div>

        <!-- participant form -->
        <form
          ref="add-meeting-participants-form"
          id="add-meeting-participants-form"
          method="post"
          action="/add_meeting_participants"
          class="tw-pb-16 md:tw-max-w-md"
          @submit.prevent="onSubmit"
        >
          <div class="tw-mb-4">
            <SelectGroup
              label=""
              name="participants"
              nameKey="name"
              input-classes="tw-font-sans tw-bg-gray-200 my-select--huge"
              v-model="participants"
              :reduce="participant => participant.id"
              :options="listOptions"
              :selected="participants"
              :multiple="true"
              :clearable="true"
              :error="formError"
            />
          </div>

          <div>
            <BaseButton
              :text="saveButtonText"
              type="submit"
              style="margin-top: 40px;"
              class="tw-shadow-app tw-block tw-w-full tw-bg-black tw-border-transparent tw-text-white tw-py-3"
              :disabled="!canSubmit || saveButtonDisabled"
              @click="onSubmit"
            />
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import SelectGroup from '@/components/SelectGroup.vue';

const defaultButtonText = 'SAVE MEETING PARTICIPANTS';

export default {
  name: 'AddMeetingParticipants',
  components: {
    SelectGroup,
  },
  props: {
    meeting: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      listOptions: [],
      participants: [],
      formError: undefined,
      saveButtonText: defaultButtonText,
      saveButtonDisabled: false,
    };
  },
  watch: {
    'meeting.id': {
      async handler(value) {
        if (value) {
          this.setFormData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    canSubmit() {
      return this.participants.length;
    },
    // transforms userIds to user objects
    selectedUsers() {
      const participants = [...this.participants];

      return participants.map((participant) => {
        return this.listOptions.find((user) => Number(user.id) === Number(participant));
      });
    },
  },
  methods: {
    async getMeetingParticipantsOptions() {
      const users = [];

      if (this.isMentor) {
        // if mentor, get my mentees
        users.push(...this.$store.getters.myMentees.map((mentees) => mentees.user));
      } else {
        // if mentee, get my mentors
        users.push(...this.$store.getters.myMentors.map((mentors) => mentors.user));
      }

      this.listOptions = users;

      // ? what to do for group chats?
      // if prefect, get mentees or mentors
    },
    async onSubmit() {
      if (!this.canSubmit) return;

      this.saveButtonDisabled = true;
      this.saveButtonText = 'SAVING...';

      await this.$store.dispatch('saveParticipants', {
        meetingId: this.meeting.linkid,
        participants: [...this.participants, this.$store.state.user.id],
        // participants: [...this.participants]
      });

      this.$toasted.success('Saved!', { duration: 4000 });

      this.saveButtonDisabled = false;
      this.saveButtonText = defaultButtonText;
    },
    async setFormData() {
      this.getMeetingParticipantsOptions();

      const users = this.$route.query['add-users'];
      const participantsFromQuery = Array.isArray(users) ? users : [users];
      const participantsFromDb = ((await this.$store.dispatch('getParticipants', this.meeting.id)) || []).map((u) => u.id);

      [...participantsFromQuery, ...participantsFromDb].forEach((userId) => {
        // make sure user exists on the participants listOptions
        const found = this.listOptions.find((user) => Number(user.id) === Number(userId));

        if (found) {
          this.participants.push(userId);
        }
      });
    }
  },
};
</script>

<style>

</style>
