<template>
  <div>
    <!-- Upload form modal -->
    <portal to="modal-body">
      <div class="tw-w-full tw-p-4 tw-pt-6">
        <div class="tw-flex tw-mt-4 tw-mb-2">
          <button
            :class="[
              'tw-w-1/2 tw-text-center tw-text-black tw-leading-6 tw-text-sm tw-block tw-rounded-t-10 tw-border tw-py-4',
              { 'tw-bg-white tw-border-gray-500 tw-border-b-white tw-font-bold': activeTab == 'file' },
              { 'tw-bg-gray-200 tw-border-gray-100 opacity-78': activeTab != 'file' },
            ]"
            @click="activeTab = 'file'"
          >
            Upload File
          </button>
          <button
            :class="[
              'tw-w-1/2 tw-text-center tw-text-black tw-leading-6 tw-text-sm tw-block tw-rounded-t-10 tw-border tw-py-4',
              { 'tw-bg-white tw-border-gray-500 tw-border-b-white tw-font-bold': activeTab == 'link' },
              { 'tw-bg-gray-200 tw-border-gray-100 opacity-78': activeTab != 'link' },
            ]"
            @click="activeTab = 'link'"
          >
            Upload Link
          </button>
          <button></button>
        </div>
        <!-- <div class="tw-m-1">
          <p class="tw-text-center opacity-54 tw-text-xs">
            Upload media
          </p>
        </div> -->
        <div v-show="activeTab == 'file'">
          <div class="tw-mb-1">
            <SelectGroup
              v-if="isSequentialProgram"
              :class="{ '--loading-data': modules.length == 0 }"
              label="Module"
              name="module"
              instruct="Please select the category of the resource you want to upload"
              nameKey="goal"
              placeholder="Resource category"
              input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1"
              v-model="upload.moduleid"
              :options="modules"
              :reduce="aModule => aModule.id"
              :error="uploadErrors.moduleid"
              :clearable="false"
              :multiple="false"
            />
            <SelectGroup
              v-else
              :class="{ '--loading-data': pillars.length == 0 }"
              label="Pillar"
              name="pillar"
              instruct="Please select the category of the resource you want to upload"
              nameKey="goal_group"
              placeholder="Resource category"
              input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1"
              v-model="upload.pillarid"
              :options="pillars"
              :reduce="pillar => pillar.id"
              :error="uploadErrors.pillarid"
              :clearable="false"
              :multiple="false"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Resource file"
              name="resource-file"
              ref="resource-file-input"
              instruct="File must be less than 8MBS"
              type="file"
              accepts="*"
              placeholder="Upload a file"
              @input="fileChange"
              :error="uploadErrors.file"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Resource Name"
              name="resource-name"
              ref="resource-name"
              type="text"
              placeholder="Resource name"
              v-model="upload.name"
              :value="upload.name"
              :error="uploadErrors.name"
            />
          </div>
        </div>
        <div v-show="activeTab == 'link'">
          <div class="tw-mb-1">
            <SelectGroup
              v-if="isSequentialProgram"
              :class="{ '--loading-data': modules.length == 0 }"
              label="Module"
              name="module"
              instruct="Please select the category of the resource you want to upload"
              nameKey="goal"
              placeholder="Resource category"
              input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1"
              v-model="upload.moduleidLink"
              :options="modules"
              :reduce="aModule => aModule.id"
              :error="uploadErrors.moduleidLink"
              :clearable="false"
              :multiple="false"
            />
            <SelectGroup
              v-else
              :class="{ '--loading-data': pillars.length == 0 }"
              label="Pillar"
              name="pillar"
              instruct="Please select the category of the resource you want to upload"
              nameKey="goal_group"
              placeholder="Resource category"
              input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1"
              v-model="upload.pillaridLink"
              :options="pillars"
              :reduce="pillar => pillar.id"
              :error="uploadErrors.pillaridLink"
              :clearable="false"
              :multiple="false"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Link Name"
              name="link-name"
              ref="link-name"
              type="text"
              placeholder="Link name"
              v-model="upload.name"
              :value="upload.name"
              :error="uploadErrors.name"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Link URL"
              name="link-url"
              ref="link-url"
              type="text"
              placeholder="URL"
              v-model="upload.link"
              :value="upload.link"
              :error="uploadErrors.link"
            />
          </div>
        </div>
      </div>
    </portal>
    <portal to="modal-footer">
      <div class="tw-w-full tw-border-t tw-p-4">
        <BaseButton
          class="tw-py-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl tw-uppercase"
          :text="uploadButtonText"
          :disabled="!canUploadResource || !uploadResource"
          @click="doUploadResource()"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import form from '@/modules/formHelper';
import { maxFileSize } from '@/modules/misc';

export default {
  name: 'UploadResourceModal',
  components: {
    InputGroup,
    SelectGroup,
  },
  data() {
    return {
      activeTab: 'file',
      pillars: [],
      modules: [],
      upload: {
        file: undefined,
        name: undefined,
        link: undefined,
        pillarid: undefined,
        pillaridLink: undefined,
        moduleid: undefined,
        moduleidLink: undefined,
      },
      uploadErrors: {
        file: undefined,
        name: undefined,
        link: undefined,
        pillarid: undefined,
        pillaridLink: undefined,
        moduleid: undefined,
        moduleidLink: undefined,
      },
      uploadResource: true,
      uploadButtonText: 'upload',
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'upload.file': function (val) {
      if (!val) {
        this.upload.file = undefined;
        this.upload.name = undefined;
        this.uploadErrors.file = 'File required';
        this.uploadErrors.name = 'File name required';
        return false;
      }
      this.uploadErrors.file = undefined;
      this.uploadErrors.name = undefined;

      if (val.size > maxFileSize) {
        this.upload.file = undefined;
        this.upload.name = undefined;

        this.$toasted.info('Please upload a file 8MB maximum', {
          duration: 4000,
          position: 'top-right',
        });

        if (this.$refs['resource-file-input']) {
          console.log(this.$refs['resource-file-input']);
          const uploadFileInput = this.$refs['resource-file-input'].$el.querySelector('input');
          uploadFileInput.value = null;
        }
        this.clearFileNameInput();

        return false;
      }

      if (!form.isValidFile(val) && val) {
        this.uploadErrors.file = 'Invalid file type';
        this.upload.file = undefined;
        this.upload.name = undefined;
        this.clearFileNameInput();
        return false;
      }
      this.upload.name = val.name.split('.').slice(0, -1).join('');

      this.clearFileNameInput(this.upload.name || '');
      return true;
    },
    // eslint-disable-next-line func-names
    'upload.name': function (val) {
      if (!form.isValidTextInput(val)) {
        this.uploadErrors.name = 'File name required';
        return;
      }

      this.uploadErrors.name = undefined;
    },
    // eslint-disable-next-line func-names
    'upload.link': function (val) {
      if ((val || '').length < 5) {
      // if (!form.isValidUrl(val)) {
        this.uploadErrors.link = 'Valid link required';
        return;
      }

      this.uploadErrors.link = undefined;
    },
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
    canUploadResource() {
      if (
        (
          this.activeTab === 'file'
          && form.isValidFile(this.upload.file)
          && form.isValidTextInput(this.upload.name)
          && this.upload.file.size < maxFileSize
        )
        || (
          this.activeTab === 'link'
          && (this.upload.link || '').length > 5
          // && form.isValidUrl(this.upload.link)
          && form.isValidTextInput(this.upload.name)
        )
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    clearFileInput() {
      if (this.$refs['resource-file-input']) {
        const uploadFileInput = this.$refs['resource-file-input'].$el.querySelector('input');
        uploadFileInput.value = null;
      }
    },
    clearFileNameInput(val = '') {
      if (this.$refs['resource-name']) {
        const uploadNameInput = this.$refs['resource-name'].$el.querySelector('input');
        uploadNameInput.value = val;
        uploadNameInput.dispatchEvent(new Event('input'));
      }
    },
    clearLinkInput(val = '') {
      if (this.$refs['link-url']) {
        const uploadNameInput = this.$refs['link-url'].$el.querySelector('input');
        uploadNameInput.value = val;
        uploadNameInput.dispatchEvent(new Event('input'));
      }
    },
    clearLinkNameInput(val = '') {
      if (this.$refs['link-name']) {
        const uploadNameInput = this.$refs['link-name'].$el.querySelector('input');
        uploadNameInput.value = val;
        uploadNameInput.dispatchEvent(new Event('input'));
      }
    },
    fileChange(val) {
      [this.upload.file] = val;
    },
    async resetViewData() {
      // this.$refs['resource-file-input'].value = '';
      await this.$nextTick();
      this.upload.file = undefined;
      this.upload.name = undefined;
      this.upload.link = undefined;
      this.upload.pillarid = undefined;
      this.upload.pillaridLink = undefined;
      this.upload.moduleid = undefined;
      this.upload.moduleidLink = undefined;
      await this.$nextTick();
      this.uploadErrors.file = undefined;
      this.uploadErrors.name = undefined;
      this.uploadErrors.link = undefined;
      this.uploadErrors.pillarid = undefined;
      this.uploadErrors.pillaridLink = undefined;
      this.uploadErrors.moduleid = undefined;
      this.uploadErrors.moduleidLink = undefined;
    },
    async doUploadResource() {
      await this.$nextTick();

      if (!this.canUploadResource || !this.uploadResource) {
        return;
      }

      const uploadType = this.activeTab === 'link' ? 'link' : 'file';
      this.uploadButtonText = 'uploading...';
      this.uploadResource = false;
      let request;

      if (uploadType === 'link') {
        const formData = {
          userid: this.userId,
          name: this.upload.name,
          link: this.upload.link,
          programId: this.$store.state.user.programid,
        };

        if (this.isSequentialProgram) {
          if (this.upload.moduleidLink) {
            formData.moduleid = this.upload.moduleidLink;
          }
          request = this.$store.dispatch('uploadSequentialLinkResource', formData);
        } else {
          if (this.upload.pillaridLink) {
            formData.pillarid = this.upload.pillaridLink;
          }

          request = this.$store.dispatch('uploadLinkResource', formData);
        }

        request.catch((err) => {
          console.warn('Error in uploading resource', err);
          this.$toasted.global.appError();
        })
          .then((res) => {
            this.uploadButtonText = 'upload';
            this.uploadResource = true;

            if (!res) {
              this.$toasted.global.appError({
                errorMessage: 'Error in uploading resource',
              });

              return false;
            }

            const message = `Success in uploading ${this.upload.name}`;
            this.$toasted.show(message, {
              type: 'success',
              position: 'bottom-right',
              fullWidth: true,
              duration: 5000,
            });

            this.resetViewData();
            this.clearLinkInput();
            this.clearLinkNameInput();
            this.$store.commit('openModal', [false]);
            this.$store.commit('toggleRefreshMyResources');
            return true;
          });

        return;
      }

      const formData = {
        name: this.upload.name,
        userid: this.userId,
        resource: this.upload.file,
        programId: this.$store.state.user.programid,
      };

      if (this.isSequentialProgram) {
        if (this.upload.moduleid) {
          formData.moduleid = this.upload.moduleid;
        }
        request = await this.$store.dispatch('uploadSequentialResource', formData);
      } else {
        if (this.upload.pillarid) {
          formData.pillarid = this.upload.pillarid;
        }
        request = await this.$store.dispatch('uploadResource', formData);
      }

      new Promise((resolve) => {
        const $this = this;
        const [res, progress] = request;

        res.then((_res) => {
          resolve(_res);
        });

        setTimeout(async function again() {
          const p = progress[0];
          // console.log(progress[0]);
          $this.uploadButtonText = `working... (${p}%)`;
          if (p < 100) {
            setTimeout(again, 100);
          } else {
            await resolve((await res));
          }
        }, 500);
      }).then((res) => {
        this.uploadButtonText = 'upload';
        this.uploadResource = true;

        if (!res) {
          this.$toasted.global.appError({
            errorMessage: 'Error in uploading resource',
          });
          return false;
        }

        const message = `Success in uploading ${this.upload.name}`;
        this.$toasted.show(message, {
          type: 'success',
          position: 'bottom-right',
          fullWidth: true,
          duration: 5000,
        });

        this.resetViewData();
        this.clearFileInput();
        this.clearFileNameInput();
        this.$store.commit('openModal', [false]);
        this.$store.commit('toggleRefreshMyResources');
        return true;
      });
    },
    async getPillars() {
      const pillars = await this.$store.dispatch('getPillarGroups', []);

      if (Array.isArray(pillars)) {
        this.pillars = [...pillars];
      }
    },
    async getModules() {
      const modules = ((await this.$store.dispatch('getProgramModules', [])) || []).flat();

      if (Array.isArray(modules)) {
        this.modules = [...modules];
      }
    },
  },
  async mounted() {
    if (this.isMentor && !this.isSequentialProgram) {
      await this.getPillars(); // for mentor
    }

    if (this.isSequentialProgram) {
      await this.getModules(); // for mentor and admin resources
    }
  },
};
</script>

<style>

</style>
