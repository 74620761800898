/* eslint-disable eqeqeq */

export default {
  async assertMatch(context, userid) {
    const requests = {
      mentee: 'getMyMentors',
      mentor: 'getMyMentees',
    };

    const myMatches = await context.dispatch(requests[context.getters.userTypeName]) || [];

    // find if userid exists in my matches
    return !!myMatches.find((match) => match.user.id == userid);
  },

  // get goal details of the match
  // matchedUserId is the user id of mentor/mentee matched with the logged in user
  async getMatchGoal(context, matchedUserId) {
    const { userTypeName } = context.getters;

    const requests = {
      mentee: 'getMyMentors',
      mentor: 'getMyMentees',
    };

    const matchedUserTypes = {
      mentee: 'mentorid',
      mentor: 'menteeid',
    };

    const goals = await context.dispatch('getGoals', []);

    const myMatches = await context.dispatch(requests[userTypeName]) || [];

    // match.mentorid | match.menteeid
    const match = myMatches.find((m) => m[matchedUserTypes[userTypeName]] == matchedUserId);

    if (match) {
      return (goals || []).find((goal) => goal.id == match.goalid);
    }

    return {};
  },
};
