import { apiStore } from '../../../modules/apiHelper';
import { isStaleData, now } from '../../../modules/dataHelper';
import getSequentialTask from './get-sequential-task';
// import getTask from './get-task';
// import mentee from './mentee';
// import mentor from './mentor';
import sequentialMentee from './sequential-mentee';
import sequentialMentor from './sequential-mentor';
import sequentialTaskResources from './sequential-task-resources';

// todo: update the commented actions to be used for goal-based programs
export default {
  // ...getTask,
  // ...mentee,
  // ...mentor,
  ...getSequentialTask,
  ...sequentialMentee,
  ...sequentialMentor,
  ...sequentialTaskResources,

  async getTaskDraft(context, userId) {
    const [expiry, draft] = await apiStore.getItem(`draftTask/${userId}`) || [0, [undefined, undefined, undefined, undefined]];

    // expiry of 12 hours
    if (isStaleData(expiry, now(), 43200000)) {
      await apiStore.removeItem(`draftTask/${userId}`);
      return [undefined, undefined, undefined, undefined];
    }

    return draft;
  },

  async getCreateTaskReferencedItem(context, refId) {
    const refItem = await apiStore.getItem(refId);
    return refItem;
  },

  async saveCreateTaskDraft(context, [refId, draftData]) {
    const refItem = await apiStore.setItem(`draftTask/${refId}`, [now(), draftData]);
    return refItem;
  },
};
