/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import { apiPost } from '../../../modules/apiHelper';
import {
  toBoolean, urlFriendly,
} from '../../../modules/dataHelper';

export default {
  // resources param could be files to be uploaded
  async uploadSequentialTaskResource(context, [taskid, resources = [], linkResources = []]) {
    const requests = [];
    const percentagesComplete = [];
    const apiAction = context.getters.userTypeName === 'mentor'
      ? 'prog_share_task_resource'
      : 'add_task_report_resource'; // not used by mentee
    //

    Array.from(resources).forEach((resource, index) => {
      // console.log(resource);
      const formData = new FormData();
      formData.set('taskid', taskid);
      // formData.set('senderid', context.getters.userId);

      if (resource.id) {
        formData.set('resourceid', resource.id);
        // formData.set('resourcetype', resource.title ? 2 : 1); // 1 = resourceid, 2 = link resourceid
      } else {
        formData.set('name', resource.name);
        formData.append('file1', resource.resource, urlFriendly(resource.resource.name));
        formData.set('resource_stat', 3); // 3 = file
      }

      requests.push(apiPost(
        apiAction,
        formData,
        70,
        {
          'Content-Type': 'multipart/form-data',
        },
        (progressEvent) => {
          percentagesComplete[index] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      ));
    });

    linkResources.forEach((resource) => {
      const formData = new FormData();
      formData.set('taskid', taskid);
      formData.set('link', resource.link);
      formData.set('name', resource.name);
      formData.set('resource_stat', 1); // 1 = link

      requests.push(apiPost(
        apiAction,
        formData,
        70,
      ));
    });

    const response = Promise.all(requests)
      .catch((err) => {
        console.warn('uploadSequentialTaskResource failed!', err);
      })
      .then((res) => {
        if (!res) return false;
        const positive = res.every((r) => !!r);
        if (!positive) return false;
        const haveErrors = res.every((r) => toBoolean(r.data.error));
        if (haveErrors) return false;

        return true;
      });

    return [response, percentagesComplete];
  },

  async sequentialTaskResources(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('prog_task_resource_list', formData, 70)
      .catch((err) => {
        console.warn('sequentialTaskResources failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        return res.data.prog_task_resource_list || [];

        // const resources = [];
        // const resourcesProm = [];

        // (res.data.prog_task_resource_list || []).forEach(({ resourceid, resource_type }) => {
        //   const resourceTypePillar = resource_type == 2 ? RESOURCE_TYPES.pillarlink : RESOURCE_TYPES.pillarresource;

        //   const r = context.dispatch('getResource', [resourceid, resourceTypePillar]);

        //   resourcesProm.push(r);

        //   r.then((resource) => {
        //     // console.log(resource);
        //     if (objectIsValid(resource)) {
        //       resources.push(resource);
        //     }
        //   });
        // });

        // await Promise.all(resourcesProm);
        // return resources;
      });

    return response;
  },

  async sequentialTaskReportResources(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('prog_submit_task_report_list', formData, 70)
      .catch((err) => {
        console.warn('sequentialTaskReportResources failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const splitTextAndResources = (res.data.prog_submit_task_report_list || []).reduce((acc, curr) => {
          if (curr.review.length) {
            acc.push({
              ...curr,
              type: 'text',
            });
          }

          if (curr.resource.length) {
            acc.push({
              ...curr,
              type: 'resource',
            });
          }
          return acc;
        }, []);

        return splitTextAndResources;
      });

    return response;
  },

  async sequentialTaskReportTexts(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('task_review_log', formData, 70)
      .catch((err) => {
        console.warn('taskReportText failed: ', err);
        return '';
      })
      .then(async (res) => {
        if (!res) return '';
        if (toBoolean(res.data.error)) return '';

        return res.data.task_review_log.filter((log) => log.taskid == taskid);
      });
    //

    return response;
  },

  async revertedSequentialTaskResources(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('prog_reject_task_report_list', formData, 70)
      .catch((err) => {
        console.warn('revertedSequentialTaskResources failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        return (res.data.prog_reject_task_report_list || []).reduce((acc, curr) => {
          if (curr.review.length) {
            acc.push({
              ...curr,
              type: 'text',
            });
          }

          if (curr.resource.length) {
            acc.push({
              ...curr,
              type: 'resource',
            });
          }
          return acc;
        }, []);
      });
    //

    return response;
  },

  async revertedSequentialTaskTexts(context, taskid) {
    const formData = new FormData();
    formData.set('taskid', taskid);

    const response = await apiPost('task_reject_log', formData, 70)
      .catch((err) => {
        console.warn('revertedTaskTexts failed: ', err);
        return '';
      })
      .then(async (res) => {
        if (!res) return '';
        if (toBoolean(res.data.error)) return '';

        return res.data.task_reject_log.filter((log) => log.taskid == taskid);
      });
    //

    return response;
  },
};
