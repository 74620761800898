import _sortBy from 'lodash/sortBy';
import resolve from '../../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { isStaleData, now } from '../../../modules/dataHelper';

const getModulesByStatus = async (endpoint, programid) => resolve(apiPost(endpoint, generateFormData({
  progid: programid,
}), 63)).then((data) => {
  if (!data) return false;
  // console.log(data);

  const programModules = data[endpoint] || [];
  return programModules;
});

const sortModulesByStartDate = (modules) => _sortBy(modules, (m) => new Date(m.start).getTime());

export default {
  /**
   * load program modules
   * @param {*} context
   * @param {*} param `[programID:ID, refresh:boolean]`
   * @returns `[ended, current, upcoming]`
   */
  async getProgramModules(context, [ProgramId, refresh = false]) {
    const programid = ProgramId || context.rootGetters.userProgramId;
    if (!programid) return false;
    const CACHE_KEY = `modules/${programid}`;

    const modules = await apiStore.getItem(CACHE_KEY) || [0, []];
    // checks empty modules. if all are empty, then falsey
    const cachedModulesAreOkay = (modules[1] || [])[0]?.length || (modules[1] || [])[1]?.length || (modules[1] || [])[2]?.length;

    if (!refresh && !isStaleData(modules[0]) && cachedModulesAreOkay) return modules[1];

    const [
      endedModules,
      activeModules,
      upcomingModules,
    ] = await Promise.all([
      getModulesByStatus('prog_goal_end', programid),
      getModulesByStatus('prog_goal_act', programid),
      getModulesByStatus('prog_goal_inactive', programid),
    ]);

    const allOk = [endedModules, activeModules, upcomingModules].every((m) => !!m);
    const allModules = [
      sortModulesByStartDate(endedModules || []),
      sortModulesByStartDate(activeModules || []),
      sortModulesByStartDate(upcomingModules || []),
    ];

    if (allOk) {
      apiStore.setItem(CACHE_KEY, [now(), allModules]);
    }

    return allModules;
  },

  async getActiveProgramModules(context, [ProgramId, refresh = false]) {
    const programid = ProgramId || context.rootGetters.userProgramId;
    if (!programid) return false;

    const CACHE_KEY = `modules/${programid}/active`;

    const modules = await apiStore.getItem(CACHE_KEY) || [0, []];
    // checks empty modules. if all are empty, then falsey
    const cachedModulesAreOkay = (modules[1] || [])[0]?.length || (modules[1] || [])[1]?.length || (modules[1] || [])[2]?.length;

    if (!refresh && !isStaleData(modules[0]) && cachedModulesAreOkay) return modules[1][1];

    const activeModules = await getModulesByStatus('prog_goal_act', programid);

    return activeModules || [];
  },

  async getUpcomingProgramModules(context, [ProgramId, refresh = false]) {
    const programid = ProgramId || context.rootGetters.userProgramId;
    if (!programid) return false;

    const CACHE_KEY = `modules/${programid}/upcoming`;

    const modules = await apiStore.getItem(CACHE_KEY) || [0, []];
    // checks empty modules. if all are empty, then falsey
    const cachedModulesAreOkay = (modules[1] || [])[0]?.length || (modules[1] || [])[1]?.length || (modules[1] || [])[2]?.length;

    if (!refresh && !isStaleData(modules[0]) && cachedModulesAreOkay) return modules[1][1];

    const upcomingModules = await getModulesByStatus('prog_goal_inactive', programid);

    return upcomingModules || [];
  },
};
