<template>
  <div v-if="error">
    <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center"> {{ error }} </p>
  </div>

  <div v-else-if="loading">
    <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center">Loading...</p>
  </div>

  <div v-else class="tw-mt-8 px-body lg-grid lg:tw-flex box-border">
    <div class="lg-grid__col-1/3 lg:tw-order-last">
      <div class="tw-border-app-deep-blue tw-border tw-p-4 tw-rounded-lg">
        <h6 class="tw-text-xs tw-ml-1 tw-mr-2 lg:tw-text-right">Program modules</h6>
        <v-select
          class="my-select my-select--huge tw-w-full lg:tw-text-right"
          placeholder=""
          label="goal"
          :value="selectedGoalId"
          @input="setSelectedGoalId"
          :options="goals"
          :reduce="goal => goal.id"
          :clearable="false"
          :filterable="false"
          :searchable="false"
          append-to-body
          :calculate-position="dropdownPosition"
        />
      </div>
      <!-- SELECTED GOAL INFO -->
      <transition name="fade" mode="out-in">
        <div class="tw-mt-4 tw-mb-8 tw-flex tw-flex-wrap" v-show="selectedGoalId">
          <div class="tw-w-1/2">
            <h4 class="leading-14 font-14 tw-text-gray-700 tw-mb-1">{{ totalSelectedGoalTasks }} {{ 'task' | pluralize(totalSelectedGoalTasks, 'tasks') }}</h4>
            <h4 v-show="!!totalTasksCompleted" class="leading-14 font-14 tw-text-gray-700 tw-mb-1">{{ totalTasksCompleted }} {{'task' | pluralize(totalTasksCompleted, 'tasks') }} completed</h4>
            <h4 v-show="!!totalTasksOverdue" class="leading-14 font-14 tw-text-red-700">{{ totalTasksOverdue }} {{'task' | pluralize(totalTasksOverdue, 'tasks') }} overdue</h4>
          </div>
          <div class="tw-w-full tw-text-right">
            <BaseButton
              text="Create task"
              class="tw-inline-block tw-my-6"
              icon
              v-show="selectedGoalTasks.length > 0"
              :to="{ name: 'mentorMenteeCreateTask', query: { goal: selectedGoalId } }"
            >
              <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
                  c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
              </svg>
            </BaseButton>
          </div>
          <div class="tw-w-full tw-mt-8 tw-hidden lg:tw-block">
            <p class="tw-text-right tw-text-sm leading-17 opacity-31 tw-w-full" v-html="selectedGoal.description">
            </p>
            <!-- <BaseButton
              text="Comment"
              class="tw-text-center tw-text-white tw-bg-black tw-block tw-w-full tw-mt-8"
              icon
            >
              <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M46.1,5.3C35,5.3,25.6,12.4,22,22.2h-1c-0.1,0-0.3,0-0.4,0C9.2,22.2,0,31.5,0,42.8c0,5.8,2.4,11.1,6.5,15V59
                  c0,5.3,3.5,7.8,6.7,7.8c1.4,0,3.5-0.4,5.8-2.7l0.8-0.8c0.3,0,0.5,0,0.8,0c6.2,0,11.9-2.7,15.8-7.5l0.7-0.9c2.9,1.1,5.9,1.6,9.1,1.6
                  c0.5,0,1.1,0,1.7-0.1l2.9,2.9c2.2,2.2,4.3,2.7,5.8,2.7c3.2,0,6.7-2.5,6.7-7.8v-4.1c5.4-4.8,8.7-11.8,8.7-19.2
                  C71.8,16.8,60.3,5.3,46.1,5.3z M20.4,58.2c-0.9,0-1.8-0.1-2.7-0.2l-2.6,2.6c-2.1,2-3.6,1.3-3.6-1.5v-3.6C7.6,52.5,5,47.9,5,42.8
                  c0-8.5,6.9-15.4,15.4-15.4c0.1,0,0.2,0,0.3,0c-0.2,1.1-0.2,2.4-0.2,3.6c0,9,4.7,17,11.9,21.7C29.5,56,25.2,58.2,20.4,58.2z
                  M57.9,47.7v6.6c0,2.9-1.6,3.5-3.7,1.5l-4.6-4.6c-1.1,0.2-2.4,0.3-3.6,0.3c-11.3,0-20.5-9.2-20.5-20.5S34.7,10.4,46,10.4
                  S66.6,19.7,66.6,31C66.7,37.8,63.3,44,57.9,47.7z"/>
              </svg>
            </BaseButton> -->
          </div>
        </div>
      </transition>
    </div>

    <div class="lg-grid__col-2/3 lg:tw-pr-8">
      <div v-if="selectedGoalTasks.length === 0" class="px-body tw-py-16 tw-text-center">
        <!-- svg for has no task -->
        <h3 class="font-title tw-text-2xl tw-leading-tight tw-font-bold opacity-54 tw-mb-4">Mentee has no task in "{{ selectedGoal.goal }}"</h3>
        <h4 class="tw-text-base tw-leading-tight tw-font-bold opacity-54 tw-mb-8">Create his first task</h4>
        <BaseButton
          text="Create Task"
          class="tw-my-4 tw-py-3 tw-bg-gray-200"
          icon
          :to="{ name: 'mentorMenteeCreateTask', query: { goal: selectedGoalId } }"
        >
          <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
              c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
          </svg>
        </BaseButton>
      </div>
      <div v-else>

        <!-- TASKS ON REVIEW GOAL TASK (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <TasksOnReview v-if="totalTasksOnReview > 0" :tasks="selectedGoalTasks" />
        </transition>

        <!-- ONGOING GOAL TASK (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksOngoing > 0" class="tw-overflow-x-visible">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Ongoing Tasks</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOngoing }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
                <!-- date-class="tw-text-white opacity-78" -->
              <!-- LIST GRID -->
              <ViewMore
                :limit="1"
                :list="ongoingTasks"
                v-slot="{ item: task }"
                more-btn-text="More Ongoing Tasks"
              >
                <TaskCard
                  :task="task"
                  :task-user-id="menteeId"
                  :full-width-on-large="true"
                  :show-task-details-on-click="true"
                  :show-mentor="false"
                  due-class="tw-text-green-500 tw-bg-blue-100 tw-inline-block tw-rounded-full tw-px-2 tw-py-1"
                  due-prefix="Due"
                  due-date-ref="end_tym"
                  date-class="tw-text-white-- opacity-78"
                  class="lg:tw-pt-2 tw-mb-4 tw-border tw-border-app-deep-blue tw-bg-app-deep-blue-- tw-text-white-- tw-shadow-app"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

        <!-- OVERDUE GOAL TASKS (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksOverdue > 0" class="tw-overflow-x-visible tw-mt-10">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Overdue Tasks</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksOverdue }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
              <!-- LIST GRID -->
              <ViewMore
                class="tw-flex stacked-grid stacked-grid--stack-full lg:stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
                :limit="4"
                :list="overdueTasks"
                v-slot="{ item: task }"
              >
                <TaskCard
                  :task="task"
                  :task-user-id="menteeId"
                  :full-width-on-large="false"
                  :show-mentor="false"
                  due-class="tw-text-red-500"
                  due-prefix="Due"
                  due-date-ref="end_tym"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

        <!-- UPCOMING GOAL TASKS (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksUpcoming > 0" class="tw-overflow-x-visible">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs lg:tw-text-sm lg:tw-font-bold">Upcoming Tasks</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksUpcoming }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
              <!-- LIST GRID -->
              <ViewMore
                class="tw-flex stacked-grid stacked-grid--stack-full tw-flex-wrap tw-items-start tw-mt-4"
                :limit="2"
                :list="upcomingTasks"
                v-slot="{ item: task }"
              >
                <TaskCard
                  :task="task"
                  :task-user-id="menteeId"
                  :full-width-on-large="false"
                  :show-mentor="false"
                  due-class="tw-text-blue-400"
                  due-prefix="Starting"
                  due-date-ref="start_tym"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

        <!-- COMPLETED GOAL TASKS (selectedGoalTasks) -->
        <transition name="new" mode="out-in" appear>
          <div v-if="totalTasksCompleted > 0" class="tw-overflow-x-visible">
            <div class="tw-flex tw-justify-between">
              <h6 class="opacity-54 tw-text-xs">Completed Tasks</h6>
              <h6 class="tw-text-xs lg:tw-text-sm lg:tw-font-bold">{{ totalTasksCompleted }}</h6>
            </div>
            <div class="divider tw-mt-2 tw-border opacity-18"></div>
            <div class="tw-my-4 lg-app:tw-mt-2 tw-overflow-visible">
              <!-- LIST GRID -->
              <ViewMore
                class="tw-flex stacked-grid tw-flex-wrap tw-items-start tw-mt-4"
                :limit="4"
                :list="completedTasks"
                v-slot="{ item: task }"
              >
                <TaskCard
                  :task="task"
                  :task-user-id="menteeId"
                  :full-width-on-large="false"
                  :show-mentor="false"
                  due-class="tw-text-blue-400"
                />
              </ViewMore>
            </div>
          </div>
        </transition>

      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import TasksOnReview from '@/views/_partials/TasksOnReview.vue';
import TaskCard from '@/components/TaskCard.vue';
import tasksListMixin from '@/mixins/taskslist';

export default {
  name: 'MyMenteeModuleTasks',
  components: {
    TasksOnReview,
    TaskCard,
  },
  mixins: [tasksListMixin],
  props: ['id', 'mentee', 'menteeId'],
  data() {
    return {
      loading: true,
      error: undefined,
      loadingEndingMatch: false,
      goals: [[]],
      tasks: [],
      tasksName: 'selectedGoalTasks',
    };
  },
  watch: {
    selectedGoalId(val) {
      if (val) {
        this.getSequentialTasks();
      }
    },
  },
  computed: {
    selectedGoal() {
      return this.goals.find((g) => g.id === this.selectedGoalId);
    },
    selectedGoalId() {
      return this.findViewState(`menteeViewSelectedGoal/${this.id}`) || (this.goals[0] || {}).id;
    },
    selectedGoalTasks() {
      if (!this.selectedGoalId) {
        this.$toasted.info('Select a goal to view its task', {
          duration: 5000,
        });

        return [];
      }

      // eslint-disable-next-line eqeqeq
      return this.tasks.filter((t) => t.goalid == this.selectedGoalId);
    },
    totalSelectedGoalTasks() {
      if (!this.selectedGoalId) {
        return undefined;
      }
      return this.totalTasks;
    },
  },
  methods: {
    ...mapMutations([
      'updateUserViewStates',
    ]),
    setSelectedGoalId(val) {
      this.updateUserViewStates({
        [`menteeViewSelectedGoal/${this.id}`]: val,
      });
    },
    showSelectedGoalDetails() {
      console.log('more info...');
    },
    async getSequentialTasks() {
      if (!this.selectedGoalId) {
        return;
      }

      const tasks = await this.$store.dispatch('sequentialTasksList', [this.$store.state.user.id, this.menteeId, this.selectedGoalId]);
      this.tasks = tasks;
    },
  },
  async created() {
    const modules = await this.$store.dispatch('getProgramModules', []);
    const [endedModules, activeModules] = modules;

    this.goals = [
      ...endedModules.map((m) => ({ ...m, goal: `${m.goal} (Ended)`, status: 'ended' })),
      ...activeModules.map((m) => ({ ...m, goal: `${m.goal} (Currently Active)`, status: 'active' })),
    ];

    const activeModule = activeModules.length > 0 ? activeModules[0] : endedModules[endedModules.length - 1];

    this.setSelectedGoalId(activeModule.id);
    this.getSequentialTasks();

    this.loading = false;
    this.error = undefined;

    this.$emit('view-mounted');
  },
};
</script>

<style>

</style>
