import _sortBy from 'lodash/sortBy';
import { toBoolean } from '../modules/dataHelper';

export default {
  name: 'TasksListMixin',
  computed: {
    TASKS() {
      return this[this.tasksName] || [];
    },
    totalTasks() {
      return this.TASKS.length || 0;
    },
    totalTasksOngoing() {
      return this.ongoingTasks.length || 0;
    },
    totalTasksOverdue() {
      return this.overdueTasks.length || 0;
    },
    totalTasksUpcoming() {
      return this.upcomingTasks.length || 0;
    },
    totalTasksCompleted() {
      return this.completedTasks.length || 0;
    },
    totalTasksOnReview() {
      return this.tasksOnReview.length || 0;
    },
    ongoingTasks() {
      const tasks = this.TASKS.filter((task) => {
        const today = new Date();

        if (
          this.taskTimeIsValid(task.start_tym, task.end_tym)
          // if start_tym is before today
          && this.taskTimeIsValid(task.start_tym, today)
          // if today is before end_tym
          && this.taskTimeIsValid(today, task.end_tym)
          && !toBoolean(task.status)
        ) {
          return task;
        }
        return undefined;
      });

      return _sortBy(tasks, 'end_tym');
    },
    overdueTasks() {
      return this.TASKS.filter((task) => {
        const today = new Date();

        if (
          this.taskTimeIsValid(task.start_tym, task.end_tym)
          // if end time is before today
          && this.taskTimeIsValid(task.end_tym, today)
          && Number(task.status) !== 1 // if is not completed
          && Number(task.status) !== 2 // if is not in review stage
        ) {
          return task;
        }
        return undefined;
      });
    },
    upcomingTasks() {
      const tasks = this.TASKS.filter((task) => {
        const today = new Date();

        if (
          this.taskTimeIsValid(task.start_tym, task.end_tym)
          // if start time is after today || if today is before start time
          && this.taskTimeIsValid(today, task.start_tym)
          && Number(task.status) !== 1 // if is not completed
          && Number(task.status) !== 2 // if is not in review stage
        ) {
          // console.log('upcoming task', task);
          return task;
        }
        return undefined;
      });

      return _sortBy(tasks, 'start_tym');
    },
    allCompletedTasks() {
      return this.TASKS.filter((task) => (Number(task.status) === 1 && this.taskTimeIsValid(task.start_tym, task.end_tym)));
    },
    completedTasks() {
      return this.TASKS.filter((task) => (Number(task.status) === 1 && this.taskTimeIsValid(task.start_tym, task.end_tym)));
    },
    tasksOnReview() {
      return _sortBy(
        this.TASKS.filter((task) => (Number(task.status) === 2 && this.taskTimeIsValid(task.start_tym, task.end_tym))),
        'dateOfPost',
      );
    },
  },
};
