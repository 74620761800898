import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

export default {
  name: 'settingsMixin',
  data() {
    return {
      selectedGoals: [],
      scope: false,
      isUpdating: false,
      goals: [],
      error: undefined,
    };
  },
  watch: {
    selectedGoals(goals = []) {
      this.$emit('input', goals);
    },
  },
  computed: {
    hasSelectedGoals() {
      return this.selectedGoals.length > 0;
    },
  },
  methods: {
    totalSelectedGoals(goalGroupId = undefined) {
      // eslint-disable-next-line no-var
      var total = 0;
      if (!goalGroupId) {
        return this.selectedGoals.length;
      }

      const goals = this.goalsByGroup(goalGroupId);
      goals.forEach((goal) => {
        if (this.selectedGoals.find(t => t === goal.id)) {
          total += 1;
        }
      });

      return total;
    },
    totalGoals(goalGroupId = undefined) {
      if (!goalGroupId) {
        return this[this.selectedDataName].length;
      }
      return this.goalsByGroup(goalGroupId).length;
    },
    goalsByGroup(id, group = this.selectedDataName, groupid = this.groupIdName) {
      // ignore data type in comparing ids
      // eslint-disable-next-line eqeqeq
      return this[group].filter(g => g[groupid] == id) || [];
    },
    goalIsEmpty(id) {
      return this.goalsByGroup(id).length === 0;
    },
    goalIsSelected(id) {
      return !!this.selectedGoals.find(t => t === id);
    },
    /**
     * sort goal groups that have data to appear first
     */
    sortGoalGroups() {
      // update: sort as how it came
      // this.goalGroups = _reverse(_sortBy(this.goalGroups, group => this.totalGoals(group.id)));
    },
    selectGoal(id) {
      if (this.isDisabled) {
        this.$toasted.info('You can\'t change your settings at the moment. Please wait, or reload page', {
          position: 'top-right',
          duration: 1000,
        });
        return false;
      }
      if (this.goalIsSelected(id)) {
        this.unselectGoal(id);
        return true;
      }

      if (this.totalSelectedGoals() < this.totalGoalsCanSelect) {
        this.selectedGoals.push(id);
      } else if (this.totalSelectedGoals() === this.totalGoalsCanSelect) {
        // prompt maximum selected goals
        // console.warn('maximum selectable goals reached');
        const message = `Maximum of ${this.totalGoalsCanSelect} goals selected`;

        this.$toasted.info(message, {
          duration: 8000,
          position: 'top-right',
        });
        return false;
      }

      return true;
    },
    unselectGoal(id) {
      if (this.isDisabled) {
        this.$toasted.info('You can\'t change your settings at the moment. Please wait, or reload page', {
          position: 'top-right',
          duration: 1000,
        });
        return false;
      }
      const index = this.selectedGoals.findIndex(t => t === id);
      if (index >= 0) {
        this.selectedGoals.splice(index, 1);
      }
      return true;
    },
    async saveSettings() {
      if (this.isDisabled) {
        this.$toasted.info('You can\'t change your settings at the moment. Please wait, or reload page', {
          position: 'top-right',
          duration: 1000,
        });
        return false;
      }
      this[this.btnDataName] = this.isUpdating ? 'updating...' : 'saving...';
      this.isDisabled = true;

      await this.$store.dispatch(this.settingsActionUpdate, this.selectedGoals)
        .then(() => {
          this.$toasted.success(`Goals ${this.isUpdating ? 'updated' : 'set'}`, {
            type: 'success',
            position: 'top-right',
            fullWidth: false,
            duration: 800,
          });
        });
      await this.$nextTick();

      // liase again with the store states
      const userType = this.$store.getters.userTypeName;
      this.selectedGoals = new Array(...this.$store.state.user[userType].goals);

      this[this.btnDataName] = this.btnText;
      this.isDisabled = false;

      return true;
    },
    async created() {
      this.scope = await this.$store.dispatch('evaluateScope');
    },
  },
};
