<template>
  <!-- // * NOTE: DON'T USE AppView COMPONENT, UNLESS <portal> -->
  <transition name="fade" mode="out-in">
    <MyMenteeModuleTasks :id="id" :mentee="mentee" :menteeId="menteeId" v-if="isSequentialProgram" />
    <MyMenteeGoalTasks :id="id" :mentee="mentee" :menteeId="menteeId" v-else />

    <!-- <div v-else class="px-body lg-grid lg:tw-flex box-border">
      <div class="lg-grid__col-2/3">
        <h3 class="opacity-78 tw-text-sm tw-text-center tw-px-1 tw-py-12">
          {{ (mentee.user || {}).name || 'Mentee' }} has not chosen {{ ((mentee.user || {}).gender || {}).id || '' | genderize(3) }} goals yet
        </h3>
      </div>
    </div> -->

  </transition>
</template>

<script>
import MyMenteeModuleTasks from '@/views/_partials/MyMenteeModuleTasks.vue';
import MyMenteeGoalTasks from '@/views/_partials/MyMenteeGoalTasks.vue';

export default {
  components: {
    MyMenteeModuleTasks,
    MyMenteeGoalTasks,
  },
  props: ['id', 'mentee'],
  data() {
    return {
      menteeId: this.$route.params.id,
    };
  },
};
</script>

<style>

</style>
