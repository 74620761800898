/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import { apiPost, apiStore, generateFormData } from '../../modules/apiHelper';
import { objectIsValid, toBoolean, urlFriendly } from '../../modules/dataHelper';

export default {
  actions: {
    async getGroupTasks(context, groupId) {
      const companyId = context.rootState.user.companyid;

      const request = await apiPost('task_lists_group', generateFormData({ groupid: groupId }), 51)
        .catch((err) => {
          console.warn('getGroupTasks::task_lists_group err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          // const tasksList = _uniqBy(res.data.task_lists_group || [], 'task_name');
          const tasksList = res.data.task_lists_group || [];

          const goals = await context.dispatch('getGoals', [companyId]);

          tasksList.map((task) => {
            // eslint-disable-next-line no-param-reassign
            task.goal = goals.find((goal) => Number(goal.id) === Number(task.subgoalid));

            return task;
          });

          return tasksList;
        });
      //

      return request;
    },
    async getMyGroupTasks(context, groupId) {
      const companyId = context.rootState.user.companyid;
      const userId = context.rootState.user.id;

      const request = await apiPost('task_lists_group_mentee', generateFormData({ groupid: groupId, userid: userId }), 51)
        .catch((err) => {
          console.warn('getMyGroupTasks::task_lists_group_mentee err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          // const tasksList = _uniqBy(res.data.task_lists_group_mentee || [], 'task_name');
          const tasksList = res.data.task_lists_group_mentee || [];

          const goals = await context.dispatch('getGoals', [companyId]);

          tasksList.map((task) => {
            // eslint-disable-next-line no-param-reassign
            task.goal = goals.find((goal) => Number(goal.id) === Number(task.subgoalid));

            return task;
          });

          return tasksList;
        });
      //

      return request;
    },
    async getEndedGroupTasks(context, groupId) {
      const companyId = context.rootState.user.companyid;

      const request = await apiPost('task_lists_end_group', generateFormData({ groupid: groupId }), 51)
        .catch((err) => {
          console.warn('getEndedGroupTasks::task_lists_end_group err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          const tasksList = res.data.task_lists_end_group || [];

          const goals = await context.dispatch('getGoals', [companyId]);

          tasksList.map((task) => {
            // eslint-disable-next-line no-param-reassign
            task.goal = goals.find((goal) => Number(goal.id) === Number(task.subgoalid));

            return task;
          });

          return tasksList;
        });
      //

      return request;
    },
    async getMyEndedGroupTasks(context, groupId) {
      const companyId = context.rootState.user.companyid;
      const userId = context.rootState.user.id;

      const request = await apiPost('task_lists_end_group_mentee', generateFormData({ groupid: groupId, userid: userId }), 51)
        .catch((err) => {
          console.warn('getMyEndedGroupTasks::task_lists_end_group err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          const tasksList = res.data.task_lists_end_group_mentee || [];

          const goals = await context.dispatch('getGoals', [companyId]);

          tasksList.map((task) => {
            // eslint-disable-next-line no-param-reassign
            task.goal = goals.find((goal) => Number(goal.id) === Number(task.subgoalid));

            return task;
          });

          return tasksList;
        });
      //

      return request;
    },
    async getGroupTasksOnReview(context, groupId) {
      const companyId = context.rootState.user.companyid;

      const request = await apiPost('task_list_review', generateFormData({
        groupid: groupId,
      }), 51)
        .catch((err) => {
          console.warn('getGroupTasksOnReview::task_list_review err', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          const tasksList = res.data.task_list_review || [];

          const goals = await context.dispatch('getGoals', [companyId]);

          tasksList.map((task) => {
            // eslint-disable-next-line no-param-reassign
            task.goal = goals.find((goal) => Number(goal.id) === Number(task.subgoalid));

            return task;
          });

          return tasksList;
        });
      //

      return request;
    },
    async getMyGroupTasksOnReview(context, groupId) {
      const userId = context.rootState.user.id;
      const groupTasks = await context.dispatch('getGroupTasksOnReview', groupId);

      // console.log(groupTasks);

      if (Array.isArray(groupTasks)) {
        return groupTasks.filter((task) => task.userid == userId);
      }

      return [];
    },
    async getGroupTaskResources(context, { groupId, taskId }) {
      const formData = new FormData();
      formData.set('groupid', groupId);

      const response = await apiPost('task_report_resources', formData, 51)
        .catch((err) => {
          console.warn('getGroupTaskResources::task_report_resources failed: ', err);
          return [];
        })
        .then(async (res) => {
          if (!res) return [];
          if (toBoolean(res.data.error)) return [];
          const resources = [];
          const resourcesProm = [];

          (res.data.task_report_resources || []).forEach(({
            resourceid, resource_type, taskid,
          }) => {
            if (taskid != taskId) return;

            const r = context.dispatch('getResource', [resourceid, resource_type]);

            resourcesProm.push(r);

            r.then((resource) => {
              // console.log(resource);
              if (objectIsValid(resource)) {
                resources.push(resource);
              }
            });
          });

          await Promise.all(resourcesProm);
          return resources;
        });

      return response;
    },
    async getRevertedGroupTaskResources(context, { groupId, taskId }) {
      const formData = new FormData();
      formData.set('groupid', groupId);

      const response = await apiPost('reject_task_upload_list', formData, 56)
        .catch((err) => {
          console.warn('getRevertedGroupTaskResources::reject_task_upload_list failed: ', err);
          return [];
        })
        .then(async (res) => {
          if (!res) return [];
          if (toBoolean(res.data.error)) return [];
          const resources = [];
          const resourcesProm = [];

          (res.data.reject_task_upload_list || []).forEach(({
            resourceid,
            taskid,
            menteeid,
            ...resource
          }) => {
            if (taskid != taskId) return;

            // eslint-disable-next-line no-underscore-dangle
            const _resource = {
              resourceid,
              ...resource,
            };

            // const r = context.dispatch('getResource', [resourceid]);

            // resourcesProm.push(r);

            // r.then((resource) => {
            // console.log(resource);
            // if (objectIsValid(resource)) {
            resources.push(_resource);
            // }
            // });
          });

          await Promise.all(resourcesProm);
          return resources;
        });

      return response;
    },
    async getRevertedGroupTaskTexts(context, { groupId, taskId }) {
      const formData = new FormData();
      formData.set('groupid', groupId);

      const response = await apiPost('reject_task_list_text', formData, 56)
        .catch((err) => {
          console.warn('getRevertedGroupTaskResources::reject_task_list_text failed: ', err);
          return [];
        })
        .then(async (res) => {
          if (!res) return [];
          if (toBoolean(res.data.error)) return [];

          return (res.data.reject_task_list_text || []).filter((text) => text.taskid == taskId);
        });

      return response;
    },
    async getGroupTaskReportResources(context, { groupId, taskId }) {
      const formData = new FormData();
      formData.set('groupid', groupId);

      const response = await apiPost('task_report_resource', formData, 51)
        .catch((err) => {
          console.warn('getGroupTaskReportResources::task_report_resource failed: ', err);
          return [];
        })
        .then(async (res) => {
          if (!res) return [];
          if (toBoolean(res.data.error)) return [];
          const resources = [];
          const resourcesProm = [];

          (res.data.task_report_resource || []).forEach(({
            resourceid, resource_type, taskid,
          }) => {
            if (taskid != taskId) return;

            const r = context.dispatch('getResource', [resourceid, resource_type]);

            resourcesProm.push(r);

            r.then((resource) => {
              // console.log(resource);
              if (objectIsValid(resource)) {
                resources.push(resource);
              }
            });
          });

          await Promise.all(resourcesProm);
          return resources;
        });

      return response;
    },
    async getGroupTaskReportTexts(context, { groupId, taskId }) {
      const formData = new FormData();
      formData.set('groupid', groupId);

      const response = await apiPost('task_list_review_text', formData, 51)
        .catch((err) => {
          console.warn('getGroupTaskReportTexts::task_list_review_text failed: ', err);
          return [];
        })
        .then(async (res) => {
          if (!res) return [];
          if (toBoolean(res.data.error)) return [];

          return (res.data.task_list_review_text || []).filter((text) => text.taskid == taskId);
        });

      return response;
    },
    async uploadGroupTaskReportResource(context, [taskid, resources = []]) {
      const requests = [];
      const percentagesComplete = [];

      Array.from(resources).forEach((resource, index) => {
        const formData = generateFormData({
          taskid,
          userid: context.rootState.user.id,
          name: resource.name,
        });

        formData.append('file1', resource, urlFriendly(resource.name));

        requests.push(apiPost(
          'add_task_grp_report_resource',
          formData,
          3,
          {
            'Content-Type': 'multipart/form-data',
          },
          (progressEvent) => {
            percentagesComplete[index] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        ));
        //
      });

      const response = Promise.all(requests)
        .catch((err) => {
          console.warn('uploadGroupTaskReportResource failed!', err);
        })
        .then((res) => {
          if (!res) return false;
          const positive = res.every((r) => !!r);
          if (!positive) return false;
          const haveErrors = res.every((r) => toBoolean(r.data.error));
          if (haveErrors) return false;

          return true;
        });
      //

      return [response, percentagesComplete];
    },
    async markGroupTaskComplete(context, [taskId, groupId, reportText = undefined, reportResources = false]) {
      if (!taskId) return false;
      const task = await context.dispatch('getTask', [taskId]);
      const { userId } = context.getters;

      if (userId != task.userid) return false;

      const formData = new FormData();
      formData.set('taskid', taskId);
      formData.set('groupid', groupId);

      if (reportText) {
        formData.set('review', reportText);
        formData.set('text', reportResources ? 2 : 1);
      } else {
        formData.set('text', 0);
      }

      const response = await apiPost('submit_task_report', formData, 55)
        .catch((err) => {
          console.warn('markGroupTaskComplete ERROR', err);
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.error)) return false;

          // get the updated task Details
          await apiStore.removeItem(`task/${taskId}`);
          context.dispatch('getTask', [taskId]);

          return true;
        });
      //

      return response;
    },
  },
};
