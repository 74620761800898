<template>
  <div class="tw-relative tw-w-full tw-py-1">
    <div v-if="isResourceRef">
      <ResourceChatBubble
        :resource="resource"
        :message="message"
        :text-is-self="textIsSelf"
        :read="read"
        :is-link-resource-ref="isLinkResourceRef"
      />
      <div v-if="!isResourceRefOnly">
        <p
          :class="[
            'tw-text-sm opacity-87 leading-17 tw-mt-2 tw-mb-6 tw-mx-2 tw-break-words tw-whitespace-pre-wrap',
            { 'tw-text-right' : textIsSelf },
            { 'tw-text-left' : !textIsSelf },
          ]"
          v-html="htmlizeLinksInString(message.chat_message || '...')"
        >
        </p>
      </div>
    </div>

    <div
      :class="[
        'chat-bubble tw-overflow-visible tw-fill-current',
        { 'chat-bubble--right mr-body lg:tw-mr-2' : textIsSelf },
        { 'chat-bubble--left ml-body lg:tw-ml-2 tw-bg-app-primary-blue tw-text-app-light-blue' : !textIsSelf },
        { 'tw-rounded-tl-32' : isTaskRef && textIsSelf },
        { 'tw-rounded-tr-32' : isTaskRef && !textIsSelf },
      ]"
      v-else
    >
      <TaskChatRef
        :resource="resource"
        :text-is-self="textIsSelf"
        v-if="isTaskRef"
      />
      <div class="tw-overflow-auto tw-relative">
        <p class="tw-text-sm opacity-87 leading-17 tw-mt-2 tw-mx-2 tw-break-words tw-whitespace-pre-wrap" v-html="htmlizeLinksInString(message.chat_message || '...')"></p>
        <div class="tw-flex tw-items-center">
          <span
            :class="[
              'tw-mx-2 tw-my-2 tw-text-xs leading-14 tw-font-light tw-block tw-flex-grow',
              { 'tw-text-right opacity-31' : textIsSelf },
              { 'tw-text-left opacity-54' : !textIsSelf },
            ]"
          >{{ message.tym | toJsDate | moment('ddd Do hh:mm A') }}</span>
          <!-- READ/UNREAD TICKS -->
          <span
            v-if="textIsSelf"
            :class="[
            'tw-fill-current tw-my-1 tw-flex-shrink-0 tw-pr-2',
            { 'tw-text-green-400': read},
            { 'tw-text-gray-500': !read},
            ]"
          >
            <svg class="tw-h-3 tw-w-3 transform-scale-0" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <g>
                <path d="M34.6,58.7c-0.8,0-1.6-0.4-2.2-1l-16.7-18c-1.2-1.3-1.1-3.4,0-4.7c1.2-1.3,3.1-1.2,4.3,0l14.5,15.6l32.1-36.4
                  c1.1-1.3,3.1-1.4,4.3-0.2c1.2,1.2,1.3,3.3,0.2,4.7L36.9,57.7C36.3,58.3,35.5,58.7,34.6,58.7L34.6,58.7z"/>
                <path d="M35.2,40.9l19.1-21.7c1-1.1,1.2-2.9,0.4-4.2c-1.1-1.8-3.4-1.9-4.7-0.4L30.5,36.7c-1.1,1.2-1.1,3.1,0.2,4.3l0,0
                  C31.9,42.3,34,42.2,35.2,40.9z"/>
                <path d="M22.1,52.8L5.8,35.1c-1-1.1-2.4-1.4-3.8-1c-2.1,0.8-2.6,3.3-1.2,4.8L17.4,57c1.2,1.4,3.4,1.4,4.6,0l0,0
                  C23.2,55.9,23.2,54,22.1,52.8z"/>
              </g>
            </svg>
          </span>
        </div>
      </div>
      <!--  -->
      <RightTip v-if="textIsSelf" />
      <LeftTip v-else-if="!textIsSelf" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toBoolean, htmlizeLinksInString } from '@/modules/dataHelper';
import { apiUrl } from '@/modules/apiHelper';
import ResourceChatBubble from '@/components/ChatBubbleText/ResourceChatBubble.vue';
import TaskChatRef from '@/components/ChatBubbleText/TaskChatRef.vue';
import RightTip from '@/components/ChatBubbleText/RightTip.vue';
import LeftTip from '@/components/ChatBubbleText/LeftTip.vue';

// const RESOURCE_TYPES = {
//   pillarresource: 1,
//   pillarlink: 2,
//   resource: 3,
//   link: 4,
// };

export default {
  name: 'ChatBubbleText',
  components: {
    ResourceChatBubble,
    TaskChatRef,
    RightTip,
    LeftTip,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    status: {
      type: [String, Boolean],
      required: true,
    },
    senderId: {
      type: [String, Number],
      required: true,
    },
    // CAN EITHER BE TASKID OR RESOURCE...
    attachment: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      resource: {},
      apiUrl,
    };
  },
  computed: {
    ...mapGetters([
      'userId',
    ]),
    textIsSelf() {
      return this.senderId === this.userId || Number(this.senderId) === Number(this.userId);
    },
    read() {
      return toBoolean(this.status);
    },
    isResourceRef() {
      return this.message.resourceid.length > 0 && this.message.resourceid !== '0';
    },
    isTaskRef() {
      return (this.message.taskid || '').length > 0 && this.message.taskid !== '0';
    },
    isLinkResourceRef() {
      return this.isResourceRef && this.resource?.file_type === 'link';
    },
    isResourceRefOnly() {
      return this.isResourceRef && !!this.message.chat_message === false;
    },
  },
  methods: {
    markTextRead() {
      this.$emit('text-read');
    },
    htmlizeLinksInString(str) {
      return htmlizeLinksInString(str);
    },
  },
  async created() {
    if (!this.textIsSelf && !this.read) this.markTextRead();

    // get attachment
    if (this.isResourceRef) {
      this.resource = await this.$store.dispatch('getProgramResource', [this.message.resourceid, 'resource']);
      // console.log(this.resource);
      this.$emit('update');
    } else if (this.isTaskRef) {
      this.resource = await this.$store.dispatch(this.isSequentialProgram ? 'getSequentialTask' : 'getTask', [this.message.taskid]);
      this.$emit('update');
    }
  },
};
</script>

<style>
</style>
